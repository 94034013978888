import {Button, useRecordContext} from "react-admin";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import DownloadIcon from "@mui/icons-material/GetApp";

export const DownloadDocumentButton = (props: any) => {
    const record = useRecordContext();
    const [startDownload, setStartDownload] = useState<{
        bucket_file_name: string,
        original_file_name: string
    } | null>(null);
    const [downloadLink, setDownloadLink] = useState<string>();

    useEffect(() => {
        if (startDownload) {
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getDownloadUrl?bucketFilename=${startDownload.bucket_file_name}&originalFilename=${startDownload.original_file_name}`)
                .then(r => r.json())
                .then((body) => setDownloadLink(body.url));
            setStartDownload(null);
        }
    }, [startDownload])

    useEffect(() => {
        if (downloadLink) {
            console.log("link", downloadLink);
            const link = document.createElement('a');
            link.href = `${downloadLink}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [downloadLink])


    if (record?.file?.attachments?.length >= 0) return (
        <Box>
            {
                record?.file?.attachments?.map((attachment: any, index: number) => (
                    <Button
                        key={index}
                        label={attachment.original_file_name}
                        onClick={() => setStartDownload({
                            bucket_file_name: attachment.bucket_file_name,
                            original_file_name: attachment.original_file_name
                        })}
                    >
                        <DownloadIcon/>
                    </Button>
                ))
            }
        </Box>
    );


    if (!record?.file?.bucket_file_name) return null;

    return (
        <Button
            label={props.label || "Download"}
            onClick={() => setStartDownload({
                bucket_file_name: record.file.bucket_file_name,
                original_file_name: record.file.original_file_name
            })}
        >
            <DownloadIcon/>
        </Button>
    )
};