
export const access = (path: string, object: any) => {
    return path.split('.').reduce((o, i) => o?.[i], object)
}

export const emojis = {
    whiteCheckmark: '✅',
    redCircle: '🔴',
}
export const JAN_OVE_EMAIL = "janove@breezeip.com";
export const HANDLER_ACUMASS = "Acumass";
export type OrderType = "PCT" | "EP";
