import {ExportConfiguration} from "../import/importReportExporter";
import {dateFormat} from "../actions/Actions";
import * as XLSX from 'xlsx-js-style';
import moment from "moment";
import {access} from "../../utils/utils";
import {countries} from "../../utils/countries";

const getExportConfig = (tenantFee?: number): ExportConfiguration => {
    const tenantFeeConfig = tenantFee ? [
        {field: "tenant_fee", header: "Client fee"},
        {field: "total_with_tenant_fee", header: "Total costs inc. client fee"},
    ] : [];
    return [
        {field: "country", header: "Country"},
        {field: "country_code", header: "Country code"},
        {field: "official_fees", header: "Official fees"},
        {field: "service_fees", header: "Service fees"},
        {field: "translation_fees", header: "Translation fees"},
        {field: "total", header: "Total costs"},
        ...tenantFeeConfig,
        {field: "currency_code", header: "Currency"},
    ];
}


const toArrayOfArrays = (data: any[], config: ExportConfiguration) => {
    const headerLines = config.map((config) => {
        return config.header || config.field;
    });
    const contentLines = data.map((item) => {
        return config.map((config) => {
            return access(config.field, item);
        });
    })
    return [
        headerLines,
        ...contentLines,
    ];
}

export const PCTPricesExportToExcel = (data: any[], caseData: any, currencyConvert: { currency_code: string, factor: number }, tenantFee?: number) => {
    console.log("EXPORT: ", data);
    const transformedData = data.map((item) => {
        return {
            ...item,
            official_fees: Math.ceil((item?.official_fees || 0) * currencyConvert.factor),
            service_fees: Math.ceil((item?.service_fees || 0) * currencyConvert.factor),
            translation_fees: Math.ceil((item?.translation_fees || 0) * currencyConvert.factor),
            total: Math.ceil((item?.total || 0) * currencyConvert.factor),
            currency_code: currencyConvert.currency_code,
            country_code: item?.country_code?.toUpperCase(),
            country: countries[item?.country_code],
            ...(tenantFee ? {
                tenant_fee: tenantFee,
                total_with_tenant_fee: Math.ceil((item?.total || 0) * currencyConvert.factor) + tenantFee
            } : {})
        }
    });

    const exportConfiguration = getExportConfig(tenantFee);
    const arrayOfArrays = toArrayOfArrays(transformedData, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || transformedData.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    const sheetTitle = caseData.client_ref ? `PCT prices - ${caseData.client_ref}` : "PCT prices";
    XLSX.utils.book_append_sheet(wb, ws, sheetTitle);
    XLSX.writeFile(wb, `pct_prices_${moment().format(dateFormat)}.xlsx`);
}

export const directApplicationPricesExportToExcel = (data: any[], caseData: any, currencyConvert: { currency_code: string, factor: number }, tenantFee?: number) => {
    console.log("EXPORT: ", data);
    const promptResult = prompt(`Enter your fee in ${currencyConvert.currency_code}`, tenantFee?.toString());

    if (promptResult === null) return;

    const numberFee = isNaN(Number(promptResult)) ? undefined : Number(promptResult);
    const usedTenantFee = numberFee || tenantFee;

    const transformedData = data.map((item) => {
        return {
            ...item,
            official_fees: Math.ceil((item?.official_fees || 0) * currencyConvert.factor),
            service_fees: Math.ceil((item?.service_fees || 0) * currencyConvert.factor),
            translation_fees: Math.ceil((item?.translation_fees || 0) * currencyConvert.factor),
            total: Math.ceil((item?.total || 0) * currencyConvert.factor),
            currency_code: currencyConvert.currency_code,
            country_code: item?.country_code?.toUpperCase(),
            country: countries[item?.country_code],
            ...(usedTenantFee ? {
                tenant_fee: usedTenantFee,
                total_with_tenant_fee: Math.ceil((item?.total || 0) * currencyConvert.factor) + usedTenantFee
            } : {})
        }
    });
    const exportConfiguration = getExportConfig(usedTenantFee);
    const arrayOfArrays = toArrayOfArrays(transformedData, exportConfiguration);
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);

    ws["!cols"] = (arrayOfArrays?.[0] || []).map((header: any) => {
            const found = exportConfiguration.find((config) => config.header === header || config.field === header);
            return ({
                wch: found?.width || transformedData.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), header.length)
            });
        }
    );

    const wb = XLSX.utils.book_new();
    const sheetTitle = caseData.client_ref ? `Direct applications prices - ${caseData.client_ref}` : "Direct applications prices";
    XLSX.utils.book_append_sheet(wb, ws, sheetTitle);
    XLSX.writeFile(wb, `direct_applications_prices_${moment().format(dateFormat)}.xlsx`);
}

