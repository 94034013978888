import { defaultTheme, RaThemeOptions } from 'react-admin';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import red from '@mui/material/colors/red';
import { lighten } from '@mui/material';
import { deepmerge } from "@mui/utils";

const theme: RaThemeOptions = deepmerge(defaultTheme, {
    palette: {
        primary: {
            dark: "#372a8c",
            main: "#4f3cc9",
            light: "#7263d3",
            contrastText: "#fff"
        },
        secondary: {
            dark: "#0069c0",
            main: "#283593",
            light: "#6ec6ff",
            contrastText: "#fff"
        },
    },
    // typography: {
    //     // Use the system font instead of the default Roboto font.
    //     fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    // },
});

/*

primary
:
contrastText
:
"#fff"
dark
:
"rgb(55, 42, 140)"
light
:
"rgb(114, 99, 211)"
main
:
"#4f3cc9"
[[Prototype]]
:
Object
secondary
:
contrastText
:
"#fff"
dark
:
"#0069c0"
light
:
"#6ec6ff"
main
:
"#283593"
 */

export const warningLight = lighten("#fee3b5", 0.3);
export const errorLight = "#FDE6E2";
export const successLight = "#D6F3EB";

// export const appTheme = defaultTheme;
export const appTheme = theme;
