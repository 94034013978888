import {Box, Dialog, DialogContent, DialogTitle, Grid, Stack, Tab, Tabs} from "@mui/material";
import Mustache from "mustache";
import {useState} from "react";
import {Button, useNotify, useDataProvider, useListContext, required, Form, useRefresh, BooleanInput, choices} from "react-admin";
import {countries} from "../../utils/countries";
import {
    DefaultEditorOptions,
    RichTextInput,
} from 'ra-input-rich-text';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import React from "react";
import {useTenantContext} from "../../react-admin-overrides/AppLayout";
import {useUserId} from "../../auth/utils";
import moment from "moment";
import {INSTRUCTION_DO_NOT_PAY, INSTRUCTION_PAY} from "../annuities/Annuities";
import {HANDLER_ACUMASS, JAN_OVE_EMAIL} from "../../utils/utils";

export const SendInvoiceEmailButton = () => {
    const [open, setOpen] = useState(false);
    const [selectedInvoiceNumber, setInvoiceNumber] = useState<number | undefined>();
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const userId = useUserId();
    const refresh = useRefresh();

    const selected = (data || []).filter((d: any) => selectedIds.includes(d.id));
    const selectedTenants = [...new Set(selected.map((d: any) => d.case_action?.case?.tenant?.id))];
    if (selectedTenants.length > 1) return null;

    const tenant = selected[0]?.case_action?.case?.tenant;

    const handleClick = async () => {
        const emailTemplateId = "3a779da7-ed67-42c5-9eae-265d3ee5b103";
        try {
            // Fetch email template and preview
            const response = await dataProvider.getOne('email_templates', {
                id: emailTemplateId
            });

            const invoiceIds = [...new Set(selected.map(row => row.invoice_id))];
            const invoicesResponse = await dataProvider.getList('invoices', {
                filter: {
                    "id@_in": invoiceIds
                },
                sort: {
                    field: "invoice_number",
                    order: "ASC"
                },
                pagination: {
                    page: 1,
                    perPage: 100,
                }
            });
            const invoices = invoicesResponse.data;

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});
            const recipientUsersResponse = await dataProvider.getMany('users', {
                ids: selected.map(row => row.instructed_by_user)
            });

            const tableData = selected.map(row => ({
                ...row,
                invoice: invoices.find(invoice => invoice?.id === row?.invoice_id)
            }))

            console.log(sendingUserResponse, recipientUsersResponse)

            const htmlTable = `
                <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
                    <tbody>
                        <tr>
                            <th>Your Ref</th>
                            <th>Type</th>
                            <th>Country</th>
                            <th>App #</th>
                            <th>Our Ref</th>
                            <th>Sequence</th>
                            <th>Amount</th>
                            <th>Invoice #</th>
                        </tr>
                        ${tableData.map(row => `
                            <tr>
                                <td>${row.case_action?.case?.tenant_case_ref || ''}</td>
                                <td>${row.case_action?.case?.case_ref?.startsWith("D") ? "Design" : "Patent"}</td>
                                <td>${countries[row.case_action?.case?.country_code] || ''}</td>
                                <td>${row.case_action?.case?.application_number || ''}</td>
                                <td>${row.case_action?.case?.case_ref || ''}</td>
                                <td>${row.case_action?.action_rule?.action_name || ''}</td>
                                <td>${row.invoice?.total || ''} ${row.invoice?.currency || ''}</td>
                                <td>${row.invoice?.invoice_number || ''}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            `;


            const recipientName = recipientUsersResponse.data.map((user: any) => user.name).join(', ');

            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', {
                table: htmlTable,
                recipientName,
                clientName: tenant?.name,
                senderName: sendingUserResponse.data?.name || "",
            }, {}, {escape: (text) => text});

            const emailSubject = Mustache.render(response.data?.subject || '', {recipientName});

            setEmailPreview(emailPreview);
            const ccEmails = Array.from(new Set(
                selected
                    .map(row => row.case_action?.case?.tenant?.cc_email)
                    .filter(email => !!email)
            ));
            const recipientCcEmails = [tenant.invoice_cc_email, ...ccEmails, JAN_OVE_EMAIL];
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                subject: emailSubject,
                body: emailPreview,
                recipient_email: tenant.invoice_email,
                // recipient_email: "aleksander@breezeip.com",
                // recipient_cc_emails: [],
                recipient_cc_emails: recipientCcEmails,
                invoices,
            });
            setInvoiceNumber(invoices[0]?.invoice_number)
            setOpen(true);
        } catch (error) {
            notify('Error loading email preview', {type: 'error'});
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        const { invoices, ...email } = emailData;
        try {
            const invoiceIds = (invoices || []).map((invoice: any) => invoice?.id);
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: {
                    ...email,
                    attachment_invoice_ids: invoiceIds,
                }
            });

            await dataProvider.updateMany('invoices', {
                ids: invoiceIds,
                data: {
                    invoice_email_id: createdEmail.id,
                    sent_by: userId,
                    sent_at: createdEmail.created_at,
                }
            });

            await dataProvider.updateMany('instructions_received', {
                ids: selectedIds,
                data: {
                    invoiced_by: userId,
                    invoiced_at: createdEmail.created_at,
                }
            });

            notify('Email sent successfully', {type: 'success'});
            setOpen(false);
            onUnselectItems();
            refresh();
        } catch (error) {
            notify('Error sending email', {type: 'error'});
        }
    };

    return (
        <>
            <Button
                label="Send Invoices Email"
                onClick={handleClick}
                disabled={selectedIds.length === 0}
            />

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                <DialogTitle>Send Invoices Email</DialogTitle>
                <DialogContent>
                    <Form onSubmit={handleSend}>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Stack spacing={1} mb={2}>
                                    <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                                    <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                                    <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                                </Stack>
                                <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                                <Box mb={2}><strong>Email:</strong></Box>
                                <div dangerouslySetInnerHTML={{__html: emailPreview}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Tabs
                                    value={selectedInvoiceNumber}
                                    onChange={(_, value) => setInvoiceNumber(value)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {
                                        emailData?.invoices?.map((invoice: any) => (
                                            <Tab value={invoice?.invoice_number} label={`# ${invoice?.invoice_number}`}></Tab>
                                        ))
                                    }
                                </Tabs>

                                <Box height={"500px"}>
                                    {
                                        selectedInvoiceNumber &&
                                        <object width="100%"
                                                height={"100%"}
                                                data={`data:application/pdf;base64,${emailData?.invoices.find((i: any) => i.invoice_number === selectedInvoiceNumber)?.pdf}`}
                                                type="application/pdf">
                                        </object>
                                    }

                                </Box>
                            </Grid>
                        </Grid>
                        <Box marginTop={4}>
                            <BooleanInput label={<span>I have verified that all PDF invoices look correctly generated</span>} source="accepted" validate={choices([true], "PDFs have to be verified")} />
                        </Box>
                        <Button
                            label="Send Invoices Email (and mark as invoiced)"
                            type={"submit"}
                            sx={{mt: 2}}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};
