import {toChoices, yesNoChoices} from "../../common/common-utils";

export const countryQuestions: { [key: string]: any } = {
    no: [{
        question: "How many employees does the applicant have?",
        label: "Number of employees",
        source: "applicant_employees",
        type: "select",
        choices: toChoices([
            "1-20",
            "21 or more",
        ])
    }],
    us: [
        {
            question: "How many employees does the applicant have?",
            label: "Number of employees",
            source: "applicant_employees",
            type: "select",
            choices: toChoices([
                "1 employee",
                "500 or less",
                "501 or more",
            ])
        },
        {
            question: "Does the claim set contain multiple dependent claims?",
            source: "multiple_dependent_claims",
            type: "select",
            choices: yesNoChoices
        }
    ],
    ep: [
        {
            question: "Would you like to extend this European application to the following extension and validation states: BA, KH, MD, ME, MA and TN?",
            source: "extension_and_validation",
            type: "select",
            choices: yesNoChoices
        },
        {
            question: "This application contains more than 15 claims. Would you like to reduce the official excess claims fees?",
            source: "reduce_claims",
            type: "select",
            choices: yesNoChoices
        }
    ],
    br: [
        {
            question: "Does the application contain biological material?",
            source: "biological_material",
            type: "select",
            choices: yesNoChoices
        }
    ]
};
