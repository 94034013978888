import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Datagrid, DateField, Link, List, ListBase, TextField, Title, useDataProvider, WithListContext } from 'react-admin';
import {Page} from "./utils/Page";
import { Box, Stack } from "@mui/system";
import {Button, CardActionArea, CardActions, CardHeader, Chip, Divider, Skeleton, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {alternateBackground, CASE_FIELDS} from "./cases/list/CaseList";
import {CaseRefField} from "./cases/crud/CaseRefField";
import {FlagField} from "./utils/FlagField";
import {NextSubAction} from "./cases/crud/CaseActionsList";
import {dateFormat, SelectCountriesButton} from "./cases/actions/Actions";
import moment from "moment";
import {useTenantId} from "./data/useTenantId";
import { useEffect } from "react";
import {caseSubActionSort} from "./cases/actions/SubActionsList";
import {useIsAdmin} from "./auth/utils";
import {TenantContext} from "./react-admin-overrides/AppLayout";

const EPPCTEvent = (props: any) => {
    const { record } = props;
    const isEP = record.action_code === "0800";
    const title = isEP ? "EP Validation" : "PCT National Phase";
    const momentDueDate = moment(record.due_date);
    const dueDateString = momentDueDate.isValid() ? momentDueDate.format(dateFormat) : "";
    const timeUntilString = momentDueDate.isValid() ? momentDueDate.from(moment()) : "";
    const url = isEP ? `/patents/${record.case_id}/select-countries` : `/patents/${record.case_id}/select-countries`;
    return (
        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
            <Box flex={1}>
                <Typography variant="body1" component="h2" >
                    {title}
                </Typography>
                <Typography variant="caption" component="div" >
                    {record.case_ref}
                </Typography>
                <Typography variant="caption" component="div" >
                    App. no. {record.application_number}
                </Typography>
            </Box>
            <Box textAlign={"right"}>
                <Typography variant="caption" component="div" >
                    {dueDateString}
                </Typography>
                <Typography variant="caption" component="div" >
                    {timeUntilString}
                </Typography>
                <Link to={url} sx={{fontSize: ".8rem"}}>Select countries</Link>
            </Box>
            {/*<Box>*/}
            {/*    <div><Chip label="Prices & handling" size={"small"} onClick={() => console.log("handle")}/></div>*/}
            {/*    <div><Chip label="Mark done" size={"small"} onClick={() => console.log("handle")}/></div>*/}
            {/*</Box>*/}
        </Stack>
    );
}

const annuitiesRequest = (body: object) => fetch(
    `${process.env.REACT_APP_FUNCTIONS_URL}/annuitiesPrices` as string,
    {
        method: "POST",
        body: JSON.stringify(body)
    });

export const Dashboard = (props: any) => {
    const tenantId = useTenantId();
    const { tenant }  = React.useContext(TenantContext) || {};
    const isAdmin = useIsAdmin();
    const auth0Data = useAuth0();
    const userName = auth0Data?.user?.name;
    const [annutiesIsLoading, setAnnuitiesIsLoading] = React.useState(false);
    const [annuitiesPrices, setAnnuitiesPrices] = React.useState<any[] | null>(null);
    const dataProvider = useDataProvider();
    const notAgent = !tenant?.is_agent;

    useEffect(() => {
        if (notAgent || true) {
            setAnnuitiesIsLoading(true);
            dataProvider.getList("annuity_cost_forecasts", {
                pagination: {page: 1, perPage: 4},
            // @ts-ignore
                sort: { field: "from,created_at", order: "ASC,DESC" },
                filter: {
                    tenant_id: tenantId,
                    distinct_on: "from",
                    "from@_gte": "2025-01-01",
                    "name@_ilike": "Q"
                }
            }).then(res => {
                setAnnuitiesIsLoading(false);
                setAnnuitiesPrices(res.data)
            }).catch(error => {
                console.error(error);
                setAnnuitiesIsLoading(false);
            });

            // annuitiesRequest({
            //     tenant_id: tenantId,
            //     from_date: "2024-07-01",
            //     to_date: "2024-09-30"
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log(data);
            //         setAnnuitiesIsLoading(false);
            //         setAnnuitiesPrices(data)
            //     })
            //     .catch(error => {
            //         console.error(error);
            //         setAnnuitiesIsLoading(false);
            //     });
        }
    }, []);

    return (
        <Stack spacing={5} alignItems={"center"} marginTop={15}>
            <Typography variant="h2" component="h1" >
                Welcome{userName && !userName.includes("@") ? `, ${userName}` : ""}
            </Typography>
            <Stack direction={"row"} spacing={5}>
                <Box width={600}>
                    <Card>
                        <CardContent >
                            <Typography variant="h6" component="h2" textAlign={"center"} marginBottom={5}>
                                EP Validations and PCT National Phase
                            </Typography>
                            <ListBase
                                resource={"actions"}
                                sort={{ field: "due_date", order: "ASC" }}
                                perPage={10}
                                filter={{ "completed_at@_is_null": true, "due_date@_gte": moment().subtract(1, "month"), "tenant_id": tenantId, "action_code@_in": ["1300", "1320", "0800"] }}
                                exporter={false}
                                storeKey={"actions-dashboard"}
                            >
                                <WithListContext render={({ data, isLoading }) => (

                                    <Stack spacing={2} sx={{ padding: 2 }} divider={<Divider></Divider>}>
                                        {
                                            isLoading ?
                                                <Stack spacing={2}>
                                                    <Skeleton variant="rounded" animation="wave" width={"90%"} height={60} />
                                                    <Skeleton variant="rounded" animation="wave" width={"90%"} height={60} />
                                                    <Skeleton variant="rounded" animation="wave" width={"90%"} height={60} />
                                                    <Skeleton variant="rounded" animation="wave" width={"90%"} height={60} />
                                                    <Skeleton variant="rounded" animation="wave" width={"90%"} height={60} />
                                                </Stack>
                                                :
                                                data?.length === 0 ?
                                                    <Box textAlign={"center"}>No upcoming actions</Box> :
                                                    data?.map(action => (
                                                        <EPPCTEvent key={action.id} record={action}></EPPCTEvent>
                                                    ))
                                        }
                                    </Stack>
                                )} />
                            </ListBase>
                        </CardContent>
                        <CardActions>
                            <Stack alignItems={"center"} flex={1}>
                                <Button size="small" color="primary">
                                    More information
                                </Button>
                            </Stack>
                        </CardActions>
                    </Card>
                </Box>

                {
                    // notAgent &&
                    <Box width={400}>
                        <Card>
                            {/*<CardActionArea component={Link} to={"/annuities?filter={\"due_date@_gte\":\"2024-07-01\",\"due_date@_lte\":\"2024-09-30\"}"}>*/}
                            <CardContent>
                                <Typography variant="h6" component="h2" textAlign={"center"} marginBottom={5}>
                                    Patent Annuities
                                </Typography>
                                {/*<Typography variant="h5" component="h3" marginTop={2}>*/}
                                {/*    {annutiesIsLoading ? <Skeleton variant={"rounded"} animation={"wave"}/> : `NOK ${annuity?.total_cost_nok?.toLocaleString("nb-NO")}`}*/}
                                {/*</Typography>*/}
                                {/*<Typography variant="caption" component="div" >*/}
                                {/*    {annutiesIsLoading ? <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/> : `A total of ${annuity?.number_of_annuities} patent annuities are due to be paid in ${annuity.name}.`}*/}
                                {/*</Typography>*/}
                                {
                                    annutiesIsLoading ?
                                        <div>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                            <Skeleton variant={"rounded"} animation={"wave"} sx={{marginTop: "1rem"}}/>
                                        </div> :
                                        <Stack divider={<Divider></Divider>} spacing={2}>
                                            {
                                                annuitiesPrices?.map((annuity: any) => (
                                                    <CardActionArea component={Link} to={`/annuities?filter=${JSON.stringify({
                                                        "effective_due_date@_gte": annuity.from,
                                                        "effective_due_date@_lte": annuity.to,
                                                        ...(isAdmin ? {"tenant_id": tenantId} : {})
                                                    })}`}>
                                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                                                            <Box flex={1}>
                                                                <Typography variant="body1" component="h2" >
                                                                    {annuity.name}
                                                                </Typography>
                                                                <Typography variant="caption" component="div" >
                                                                    {annuity.from} - {annuity.to}
                                                                </Typography>
                                                            </Box>
                                                            <Box textAlign={"right"}>
                                                                <Typography variant="body1" component="h3">
                                                                    {
                                                                        tenant?.currency_code === "USD" ?
                                                                            (annuity?.total_cost_usd ? <span>USD {annuity?.total_cost_usd?.toLocaleString("en-US")}</span> : null)
                                                                            :
                                                                            (annuity?.total_cost_nok ? <span>NOK {annuity?.total_cost_nok?.toLocaleString("nb-NO")}</span> : null)
                                                                    }
                                                                </Typography>
                                                                <Typography variant="caption" component="div" >
                                                                    {annuity.number_of_annuities ? `${annuity.number_of_annuities} annuit${annuity.number_of_annuities === 1 ? "y" : "ies"}` : "No annuities"}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                    </CardActionArea>
                                                ))
                                            }
                                        </Stack>
                                }
                            </CardContent>
                            {/*</CardActionArea>*/}
                            <CardActions>
                                <Stack justifyContent={"center"} direction={"row"} flex={1} spacing={3}>
                                    <Button size="small" color="primary" component={Link} to={`/annuities?filter=${JSON.stringify({
                                        "effective_due_date@_gte": "2024-10-01",
                                        "effective_due_date@_lte": "2024-12-31",
                                        ...(isAdmin ? {"tenant_id": tenantId} : {})
                                    })}`}>
                                        Handle Q4 annuities
                                    </Button>
                                    <Button size="small" color="primary" component={Link} to={`/annuities?filter=${JSON.stringify({
                                        ...(isAdmin ? {"tenant_id": tenantId} : {})
                                    })}`}>
                                        All annuities
                                    </Button>
                                </Stack>
                            </CardActions>
                        </Card>
                    </Box>
                }
            </Stack>
            <Typography variant="caption" component="div" textAlign={"center"} fontSize={"0.65rem"}>
                <Box>We will add more widgets to this dashboard in the future.</Box>
                <Box>If you have any suggestions, please let us know.</Box>
                <Box>post@breezeip.com</Box>
            </Typography>
        </Stack>
    );
};
