import {Button, Link, useDataProvider, useRecordContext} from "react-admin";
import {
    allCountries,
    countries,
    countries as countryList,
    epStates,
    INTERNATIONAL_TRADEMARK_APPLICATION, patentStates,
    pctStates
} from "../../utils/countries";
import PlusIcon from '@mui/icons-material/Add';
import {
    Button as MUIButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
    Autocomplete,
    TextField as MUITextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box
} from '@mui/material';
import React, {FunctionComponent, useState} from "react";
import {CASE_TYPE_TRADEMARK, Direct, PCTBased, PriorityFounding, ValidatedEPC} from "../list/CaseList";
import {CaseRecord} from "../types";
import { Flag } from "../../utils/Flag";

enum CopyType {
    EP = "EP", PCT = "PCT", TRADEMARK = "TRADEMARK", DIVISIONAL = "DIVISIONAL", FROM_PRIORITY = "FROM_PRIORITY"
}

const divisionalCopy = (caseRecord: CaseRecord, copyAmount: number) => {
    const {
        applicant,
        created_at,
        updated_at,
        id,
        next_annuity,
        next_maintenance_case_action,
        case_ref,
        ...caseRest
    } = caseRecord;
    const caseRef = case_ref || "";
    const countryCode = caseRecord?.country_code;

    const caseRefParts = caseRef.split(countryCode);
    const divisionalCounter = Number(caseRefParts[1]) || 0;

    const divisionalCases = Array.from({length: copyAmount}, (_, i) => i + 1).map((i) => {
        const newCaseRef = !caseRef ? "" : caseRef.slice(0, -2) + (divisionalCounter + i).toString().padStart(2, "0");
        return {
            ...caseRest,
            case_ref: newCaseRef,
        }
    });
    return divisionalCases;
}

const copyCaseToCountry = (caseRecord: any, countryCode: string, copyType?: CopyType) => {
    const {
        applicant,
        created_at,
        updated_at,
        id,
        designated_states,
        filing_date,
        next_annuity,
        next_maintenance_case_action,
        case_ref,
        ...caseRest
    } = caseRecord;
    const caseRef = case_ref || "";

    return copyType === CopyType.TRADEMARK ?
        {
            ...caseRest,
            country_code: countryCode,
            case_ref: caseRef.split("IM")[0] + "IM" + countryCode === "eu" ? INTERNATIONAL_TRADEMARK_APPLICATION : countryCode.toUpperCase()
        } : copyType === CopyType.EP ? {
            ...caseRest,
            country_code: countryCode,
            application_type_2: ValidatedEPC,
            filing_date,
            case_ref: caseRef.split("EP")[0] + countryCode.toUpperCase() + "EP" + caseRef.split("EP")[1]
        } : copyType === CopyType.PCT ? {
            ...caseRest,
            application_type: PCTBased,
            based_on_pct: caseRecord.application_number,
            application_number: undefined,
            country_code: countryCode,
            international_filing_date: filing_date,
            case_ref: caseRef.split("PC")[0] + countryCode.toUpperCase() + "PC" + caseRef.split("PC")[1]
        } : copyType === CopyType.FROM_PRIORITY ? {
            ...caseRest,
            country_code: countryCode,
            case_ref: caseRef.split("PC")[0] + countryCode.toUpperCase() + "00",
            application_type: countryCode?.toUpperCase() === "PC" ? Direct : Direct,
            first_priority_filing_date: filing_date,
            priority_application_number: caseRest.application_number,
            priority_country_code: caseRest.country_code,
            priority_claims: [{
                priority_country_code: caseRest.country_code?.toUpperCase(),
                priority_application_number: caseRest.application_number,
                priority_filing_date: filing_date,
            }]
        } : {};
}

const countriesFromCopyType = (copyType?: CopyType) =>
    copyType === CopyType.TRADEMARK ? countryList : copyType === CopyType.EP ? epStates : pctStates

const onlyDesignatedStates = (countries: any, designatedStates?: string) => Object.keys(countries)
    .filter(countryCode => (designatedStates || "").split(" ").includes(countryCode.toUpperCase()))
    .sort((a, b) => a.localeCompare(b));

const sortedStates = (countries: any) => Object.keys(countries)
    .sort((a, b) => a.localeCompare(b));

const copyConfig = {
    [CopyType.PCT]: {
        availableCountries: (caseRecord: any) => onlyDesignatedStates(pctStates, caseRecord?.designated_states),
    },
    [CopyType.EP]: {
        availableCountries: (caseRecord: any) => onlyDesignatedStates(epStates, caseRecord?.designated_states),
    },
    [CopyType.DIVISIONAL]: {
        availableCountries: (caseRecord: any) => undefined,
    },
    [CopyType.FROM_PRIORITY]: {
        availableCountries: (caseRecord: any) => sortedStates(patentStates)
    },
    [CopyType.TRADEMARK]: {
        availableCountries: (caseRecord: any) => sortedStates(patentStates),
    },
}

export const CopyCaseButton: FunctionComponent<{ label: string }> = ({label}) => {
    const caseRecord = useRecordContext<CaseRecord>();
    const [copyType, setCopyType] = useState<CopyType | undefined>(
        caseRecord?.case_type === CASE_TYPE_TRADEMARK ? CopyType.TRADEMARK :
            caseRecord?.country_code === "ep" ? CopyType.EP :
                caseRecord?.country_code === "pc" ? CopyType.PCT :
                    caseRecord?.application_type === PriorityFounding ? CopyType.FROM_PRIORITY :
                        CopyType.DIVISIONAL
    );
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const close = () => {
        setOpen(false);
        setFormInput({
            countryCodes: [],
            divisionalCount: 1,
        });
        setSuccessMessage("");
    };
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [formInput, setFormInput] = useState({
        countryCodes: [] as string[],
        divisionalCount: 1,
    });

    if (!caseRecord) {
        // TODO: Move dialog logic to separate component and only execute the logic when we know the caseRecord is fetched
        // It now fails when going directly to the link to the case show page (via Annuities or browser link)
        return null;
    }

    const availableCountries: any = copyConfig[copyType || CopyType.FROM_PRIORITY]?.availableCountries(caseRecord) || [];
    console.log(availableCountries);

    const startCopying = () => {
        console.log(formInput.countryCodes);

        const objects = copyType === CopyType.DIVISIONAL ?
            divisionalCopy(caseRecord, formInput.divisionalCount)
            :
            formInput.countryCodes.map((countryCode) => copyCaseToCountry(caseRecord, countryCode, copyType));

        console.log(objects);

        setIsLoading(true)
        dataProvider.getList("case_roles", {
            pagination: {page: 1, perPage: 10},
            sort: {field: "id", order: "ASC"},
            filter: {case_id: caseRecord.id}
        }).then(rolesResponse => {
            const roles = rolesResponse.data;
            const rolesToCopy = roles.map(r => ({name_id: r.name_id, role: r.role}));

            Promise.all(objects.map((object) => dataProvider.create("cases", {data: {...object, case_roles: {data: rolesToCopy}}})))
                .then(result => {
                    console.log("Roles result", result);
                    setIsLoading(false);
                    setSuccessMessage(`${result.length} cases successfully copied`);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }

    return (
        <>
            <Button
                label={label}
                onClick={() => setOpen(!open)}
            >
                <PlusIcon/>
            </Button>
            <Dialog 
                open={open} 
                onClose={close} 
                maxWidth={"md"}
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle>{`Copy case ${caseRecord?.case_ref} to other countries`}</DialogTitle>
                <DialogContent sx={{width: 600}}>
                    {
                        successMessage ?
                            <DialogContentText>
                                {successMessage}
                            </DialogContentText>
                            :
                            <>
                                {
                                    copyType !== CopyType.TRADEMARK &&
                                    <FormControl>
                                        <FormLabel>Copy Type</FormLabel>
                                        <RadioGroup
                                            // row
                                            value={copyType}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCopyType(e.target.value as unknown as CopyType)}
                                        >
                                            <FormControlLabel value={CopyType.FROM_PRIORITY} control={<Radio />} label="From Priority Founding Application" />
                                            <FormControlLabel value={CopyType.DIVISIONAL} control={<Radio />} label="Divisional Copy" />
                                            { caseRecord?.country_code === "ep" && <FormControlLabel value={CopyType.EP} control={<Radio />} label="EP Validation States"/>}
                                            { caseRecord?.country_code === "pc" && <FormControlLabel value={CopyType.PCT} control={<Radio />} label="PCT National Phase States" />}
                                        </RadioGroup>
                                    </FormControl>
                                }
                                <DialogContentText>
                                    {
                                        copyType === CopyType.DIVISIONAL &&
                                            <Box>
                                                <Box>
                                                    Divisional copy will copy all fields and increase the counter of the case_ref.
                                                    <br></br>
                                                    Example: Divisional copy of P12345NO00 will copy the case and give it a new case_ref of P12345NO01. If you select to copy to 2 or more divisional applications, they will get case_ref numbers accordingly (P12345NO01, P12345NO02, etc).
                                                </Box>
                                                <Box mt={2}>
                                                    <MUITextField
                                                        type="number"
                                                        label="Number of divisional applications to copy"
                                                        defaultValue={1}
                                                        inputProps={{ min: 1 }}
                                                        onChange={(e) => setFormInput({...formInput, divisionalCount: parseInt(e.target.value)})}
                                                        fullWidth
                                                    />
                                                </Box>
                                            </Box>
                                    }

                                    {
                                        copyType === CopyType.PCT &&
                                        <Box>
                                            This will copy the case to the countries you select below. Only designated countries
                                            will be available for selection. Perform this copy when you want to create cases for the post PCT national applications you have filed. If you file through Breeze, we will create these cases for you automatically when you submit your order.
                                            <br></br>
                                            <br></br>
                                            Application type will be set to "PCT Based". "Based on pct" will be set to this case's application number ({caseRecord.application_number})
                                            <br></br>
                                            <br></br>
                                            Case ref on the format P30100PC00 will be transformed to P30100XXPC00 where "XX" is
                                            replaced with the specific country code
                                        </Box>
                                    }

                                    {
                                        copyType === CopyType.EP &&
                                        <Box>
                                            This will copy the case to the countries you select below. Only designated countries
                                            will be available for selection. Perform this action for states you have validated the EP patent in. If you validate the EP patent through Breeze, we will create these cases for you automatically when you submit your order.
                                            <br></br>
                                            <br></br>
                                            Application type 2 will be set to "Validated EPC".
                                            <br></br>
                                            <br></br>
                                            Case ref on the format P30100EP00 will be transformed to P30100XXEP00 where "XX" is
                                            replaced with the specific country code.
                                        </Box>
                                    }

                                    {
                                        copyType === CopyType.FROM_PRIORITY &&
                                        <Box>
                                            Copy the case to the countries you select below. The copied application will have priority claims from the source case.
                                            <br></br>
                                            <br></br>
                                            Priority data that will be copied over to the new cases:
                                            <br></br>
                                            Priority application number: {caseRecord.application_number} <br />
                                            Priority application country: {caseRecord.country_code?.toUpperCase()} <br />
                                            Priority application date: {caseRecord.filing_date} <br />
                                            <br></br>
                                            <br></br>
                                            Case ref on the format P30100US00 will be transformed to P30100XX00 where "XX" is
                                            replaced with the specific country codes you select.
                                        </Box>
                                    }

                                </DialogContentText>
                                <Backdrop
                                    sx={{color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1}}
                                    open={isLoading}
                                    // onClick={handleClose}
                                >
                                    <CircularProgress color="inherit"/>
                                </Backdrop>
                                <br/>

                                {
                                    copyType !== CopyType.DIVISIONAL &&
                                    <Box>
                                        <div style={{display: "flex", flex: 1, justifyContent: "right"}}>
                                            <Button label={"Select all"}
                                                    onClick={() => setFormInput({...formInput, countryCodes: availableCountries})}></Button>
                                        </div>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={availableCountries}
                                            renderOption={(props, option) => 
                                            <Box component="li" {...props}>
                                                <Flag countryCode={option} />
                                                <Box ml={2}>{allCountries[option] || ""}</Box>
                                            </Box>
                                        }
                                            filterSelectedOptions
                                            value={formInput.countryCodes}
                                            onChange={(e, value) => setFormInput({...formInput, countryCodes: value})}
                                            renderInput={(params) => (
                                                <MUITextField
                                                    {...params}
                                                    label="Select countries to copy to"
                                                    // placeholder="Countries"
                                                />
                                            )}
                                        />
                                    </Box>
                                }
                            </>
                    }
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={close}>Cancel</MUIButton>
                    {!successMessage &&
                        <MUIButton onClick={startCopying} disabled={copyType !== CopyType.DIVISIONAL && formInput.countryCodes?.length === 0}>Start
                            copying</MUIButton>}
                </DialogActions>
            </Dialog>
        </>
    );
};