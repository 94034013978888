import {required, SelectInput} from "react-admin";
import * as React from "react";
import {Box, Stack} from "@mui/material";

export const Question = (props: any) => {
    const {countryCode, questionText, questionSelectLabel, questionSource, choices} = props;
    return (
        <Labelled label={questionText}>
            <SelectInput
                source={`${countryCode}.${questionSource}`}
                label={questionSelectLabel || false}
                validate={required()}
                choices={choices}
                sx={{minWidth: 200}}
                fullWidth={false}
            ></SelectInput>
        </Labelled>
    );
}

export const Labelled = (props: any) => {
    const {label, children, ...rest} = props;
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Box alignSelf={"center"} marginRight={1}>
                {label}
            </Box>
            {children}
        </Stack>
    );

}