import React, {FunctionComponent, useEffect, useState} from "react";
import {useRecordContext} from "react-admin";
import {ShowInDialogButton} from "@react-admin/ra-form-layout";
import {Box, DialogContent, Grid, Stack, Tab, Tabs} from "@mui/material";
import {PDFViewer} from "../../../../utils/PDFViewer";

export const PreviewButton: FunctionComponent = () => {
    const record = useRecordContext();
    const [selectedAttachment, setSelectedAttachment] = React.useState<string | null>(null);

    if (record?.file?.email) {
        const emailRecord = record.file.email;
        const attachments = record.file?.attachments || [];
        return (
            <ShowInDialogButton
                resource="case_documents"
                title="Preview email"
                key={record?.id}
                label="Preview email"
                maxWidth={false}
                fullWidth
            >
                <DialogContent>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <Stack spacing={1} mb={2}>
                                <Box><strong>To:</strong> {emailRecord?.to}</Box>
                                <Box><strong>CC:</strong> {emailRecord?.from}</Box>
                            </Stack>
                            <Box mb={5}><strong>Subject:</strong> {emailRecord?.subject}</Box>
                            <Box mb={2}><strong>Email body:</strong></Box>
                            <div dangerouslySetInnerHTML={{__html: emailRecord?.body?.replace(/\n/g, "<br />")}}/>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                attachments.length > 0 ?
                                <h4>Attachments (click to preview)</h4> : <span>No attachments</span>
                            }
                            <Tabs
                                value={selectedAttachment}
                                onChange={(_, value) => setSelectedAttachment(value)}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {
                                    attachments.map((attachmentFile: any) => (
                                        <Tab value={attachmentFile?.id} label={`${attachmentFile?.title}`}></Tab>
                                    ))
                                }
                            </Tabs>

                            <Box height={"1000px"}>
                                {
                                    selectedAttachment &&
                                    <WithFileData
                                        file={attachments.find((file: any) => file.id === selectedAttachment)}
                                        render={(fileUrl) => (
                                            <object width="100%"
                                                    height={"100%"}
                                                    data={fileUrl}
                                                    type="application/pdf">
                                            </object>
                                        )}
                                    ></WithFileData>
                                }

                            </Box>
                        </Grid>
                    </Grid>

                </DialogContent>
            </ShowInDialogButton>
        )
    }

    if (!record?.file?.original_file_name || !record?.file?.bucket_file_name) {
        return null;
    }

    const unsupportedExtensions = ["doc", "docx", "xls", "xlsx", "msg"];
    const fileExtension = record?.original_file_name?.split(".").pop()?.toLowerCase();

    if (unsupportedExtensions.includes(fileExtension || "")) {
        return null;
    }

    return (
        <ShowInDialogButton
            resource="case_documents"
            title="Preview file"
            key={record.id}
            label="Preview"
            maxWidth={false}
            fullWidth
        >
            <PDFViewer bucketFileName={record?.file?.bucket_file_name}/>
        </ShowInDialogButton>
    );
};

const WithFileData = (props: {
    file: any,
    render: (fileUrl: string) => React.ReactElement
}) => {
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getDownloadUrl?bucketFilename=${props.file.bucket_file_name}&originalFilename=${props.file.original_file_name}`);
            const fileUrl = await response.json().then((body) => body.url);

            const fileResponse = await fetch(fileUrl, { method: "GET" });
            const blob = await fileResponse.blob();
            const url = URL.createObjectURL(blob);

            setBlobUrl(url);
        };

        fetchData();
    }, [props.file]);

    if (!blobUrl) return null;

    return props.render(blobUrl);
}
