import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, SelectInput, ImageField, UrlField, FunctionField, FormDataConsumer, AutocompleteArrayInput, Filter, BooleanInput, SimpleFormIterator, ReferenceManyField, SingleFieldList, ReferenceInput, AutocompleteInput, required, NumberInput, Count, DateField, ReferenceField, BulkDeleteButton, ExportButton, CreateButton, TopToolbar, useResourceContext, useRecordContext, NumberField} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {countries, countriesOptions} from "../utils/countries";
import {useIsAdmin} from "../auth/utils";
import {ReferenceManyInput } from "@react-admin/ra-relationships";
import { Box, Stack } from "@mui/system";
import {useTenantContext} from "../react-admin-overrides/AppLayout";
import {Page} from "../utils/Page";
import {Tab, Tabs } from "@mui/material";
import {MarkInvoiceSent} from "../cases/instructions/MarkInvoiceSent";
import {PDFViewer} from "../utils/PDFViewer";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import {CountryInput} from "../utils/CountryInput";
import {caseStatusList} from "../cases/crud/utils";
import {ProductInput} from "../cases/crud/CaseInputFields";
import {downloadExcelDesigns} from "../cases/export/designs-export";
import {CountryField} from "../utils/CountryField";
import {FlagField} from "../utils/FlagField";
import {triggerDateTitles} from "../actions/triggerDate";
import { useFormContext } from "react-hook-form";
import moment from "moment";

const Filters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <ReferenceInput source="case#client_id" reference="clients" alwaysOn label={"Client"}>
                <AutocompleteInput
                    label={"Client"}
                    filterToQuery={(searchText: string) => ({ "name": `${searchText}` })}
                />
            </ReferenceInput>
            <SelectInput source={"type"} optionText={"name"} choices={typeOptions} alwaysOn />
            <TextInput label={"Search"} source={"type,title,case#case_ref@_ilike,case#tenant_case_ref@_ilike,case#applicant@_ilike,case#application_number@_ilike"} alwaysOn />
        </Filter>
    );
};

const tabState: { [key: string]: any} = {
    nonInvoiced: {
        value: "nonInvoiced",
        title: "To be invoiced",
        filter: {
            "invoiced_at@_is_null": true,
        }
    },
    invoiced: {
        value: "invoiced",
        title: "Invoiced",
        filter: {
            "invoiced_at@_is_null": false,
        }
    }
}
const combineFilters = (filterA: any, filterB: any) => ({
    ...(filterA || {}),
    ...(filterB || {})
});
export const ChargesList = (props: any) => {
    const [value, setValue] = React.useState<string>(tabState.nonInvoiced.value);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    const resource = "charges";
    return (
        <Page>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value={tabState.nonInvoiced.value}
                    label={<Box>{tabState.nonInvoiced.title} <Count filter={combineFilters(props?.filter, tabState.nonInvoiced.filter)} resource={resource}></Count></Box>}
                />
                <Tab value={tabState.invoiced.value} label={<Box>{tabState.invoiced.title} <Count filter={combineFilters(props?.filter, tabState.invoiced.filter)} resource={resource}></Count></Box>} />
            </Tabs>
            <List resource={"charges"}
                  {...props}
                  sort={{ field: "created_at", order: "DESC" }}
                  filters={<Filters/>}
                  filter={combineFilters(props?.filter, tabState[value]?.filter)}
                  empty={false}
                  actions={<Actions caseId={props?.caseId}></Actions>}
            >
                <Datagrid
                    rowClick={false}
                    bulkActionButtons={
                        <>
                            {
                                value === tabState.nonInvoiced.value && <MarkInvoiceSent></MarkInvoiceSent>
                            }
                            <BulkDeleteButton mutationMode="pessimistic"></BulkDeleteButton>
                        </>
                    }
                >

                    <FunctionField label={"Case"} render={(record: any) => {
                        return (
                            <Box>
                                <Box><TextField label={"Case ref."} source={"case.case_ref"} /></Box>
                                <Box><TextField label={"Applicant"} source={"case.applicant"} /></Box>
                                <Box><FlagField record={record?.case} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField></Box>
                                <Box>A#: {record?.case.application_number}</Box>
                                {/*<Box>R#: {record?.case.registration_number}</Box>*/}
                            </Box>
                        );


                    }} />
                    {/*<TextField label={"Case ref."} source={"case.case_ref"} />*/}
                    {/*<TextField label={"Applicant"} source={"case.applicant"} />*/}
                    {/*<TextField label={"Client ref."} source={"case.tenant_case_ref"} />*/}
                    <TextField source={"type"} />
                    <TextField source={"title"} />
                    <FunctionField label={"Hours"} render={(record: any) => {
                        return (
                            <Box>
                                <Box>{record?.hours_worked ? `${record?.hours_worked} hours` : ""}</Box>
                                <Box>{record?.hours_worked ? `${moment.utc(moment.duration(record?.hours_worked * 3600, "seconds").asMilliseconds()).format("HH:mm [(hh:mm)]")}` : ""}</Box>
                                <Box>{record?.hourly_fee ? `${record?.hourly_fee} ${record?.currency?.toUpperCase()} / hour` : ""}</Box>
                            </Box>
                        );

                    }} />

                    <FunctionField label={"Sum"} render={(record: any) => {
                        return (
                            <Box>
                                {record?.sum} {record?.currency}
                            </Box>
                        );

                    }} />
                    {/*<TextField source={"sum"} />*/}
                    {/*<TextField source={"currency"} />*/}
                    {
                        value === tabState.invoiced.value &&
                        <DateField label="Invoiced at" source={"invoiced_at"} showTime />
                    }
                    {
                        value === tabState.invoiced.value &&
                        <ReferenceField reference="users" source={"invoiced_by"} label={"Invoiced by"} link={false}>
                            <TextField source={"name"}/>
                        </ReferenceField>
                    }
                    <DateField source={"created_at"} />
                    <EditButton />
                </Datagrid>
            </List>
        </Page>
    );
};

const HOURLY = "Hourly work";
const typeOptions = ["Disbursement", "Official fee", HOURLY, "Other"].map((type) => ({ id: type, name: type}))
const currencyOptions = ["NOK", "EUR", "USD"].map((currency) => ({id: currency, name: currency}));
export const ChargesEdit = (props: any) => {
    const {tenant} = useTenantContext() || {};
    return (
        <Edit {...props} mutationMode="pessimistic" redirect={"list"} transform={transform}>
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} validate={required()}/>
                <TextInput source={"title"} validate={required()} fullWidth />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData?.type === HOURLY ?
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <NumberInput source={"hours_worked"} validate={required()} helperText={formData?.hours_worked && `${moment.utc(moment.duration(formData?.hours_worked * 3600, "seconds").asMilliseconds()).format("HH:mm [(hh:mm)]")}`}/>
                                <NumberInput source={"hourly_fee"} validate={required()} defaultValue={tenant?.hourly_fee || undefined}/>
                                <span>Sum: {(formData?.hours_worked || 0) * (formData?.hourly_fee || 0)}</span>
                            </Stack>
                            :
                            <NumberInput source={"sum"} validate={required()}/>
                    }}
                </FormDataConsumer>
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} validate={required()} />

            </SimpleForm>
        </Edit>
    );
};

const transform = (data: any) => {
    if (data?.type === HOURLY) {
        return {
            ...data,
            sum: data?.hours_worked * data?.hourly_fee
        }
    }
    return data;
}

export const ChargesCreate = (props: any) => {
    const {tenant} = useTenantContext() || {};
    return (
        <Create title="Create a name" {...props} mutationMode="pessimistic" redirect={"list"} transform={transform}>
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} validate={required()}/>
                <TextInput source={"title"} validate={required()} fullWidth />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData?.type === HOURLY ?
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <NumberInput source={"hours_worked"} validate={required()} helperText={formData?.hours_worked && `${moment.utc(moment.duration(formData?.hours_worked * 3600, "seconds").asMilliseconds()).format("HH:mm [(hh:mm)]")}`}/>
                                <NumberInput source={"hourly_fee"} validate={required()} defaultValue={tenant?.hourly_fee || undefined}/>
                                <span>Sum: {(formData?.hours_worked || 0) * (formData?.hourly_fee || 0)}</span>
                            </Stack>
                            :
                            <NumberInput source={"sum"} validate={required()}/>
                    }}
                </FormDataConsumer>
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} validate={required()} />
            </SimpleForm>
        </Create>
    );
};

export const ChargesCreateInDialog = (props: any) => {
    const {tenant} = useTenantContext() || {};
    const record = props?.caseId ? { case_id: props.caseId } : {};
    return (
        <CreateInDialogButton
            resource="charges"
            title={props?.title || "Create charge"}
            label={props?.label || "Create charge"}
            maxWidth={"sm"}
            fullWidth
            record={record}
            transform={transform}
        >
            <SimpleForm>
                <ReferenceInput source="case_id" reference="patents">
                    <AutocompleteInput
                        validate={required()}
                        fullWidth
                        optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                        filterToQuery={(searchText: string) => ({
                            "case_ref,tenant_case_ref,application_number": `${searchText}`,
                        })}
                    />
                </ReferenceInput>
                <SelectInput source={"type"} optionText={"name"} choices={typeOptions} validate={required()}/>
                <TextInput source={"title"} validate={required()} fullWidth />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData?.type === HOURLY ?
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <NumberInput source={"hours_worked"} validate={required()} helperText={formData?.hours_worked && `${moment.utc(moment.duration(formData?.hours_worked * 3600, "seconds").asMilliseconds()).format("HH:mm [(hh:mm)]")}`}/>
                                <NumberInput source={"hourly_fee"} validate={required()} defaultValue={tenant?.hourly_fee || undefined}/>
                                <span>Sum: {(formData?.hours_worked || 0) * (formData?.hourly_fee || 0)}</span>
                            </Stack>
                            :
                            <NumberInput source={"sum"} validate={required()}/>
                    }}
                </FormDataConsumer>
                <SelectInput source={"currency"} choices={currencyOptions} defaultValue={tenant?.currency_code || undefined} validate={required()} />
            </SimpleForm>
        </CreateInDialogButton>
    );
}

const Actions = (props: any) => {
    return (
        <TopToolbar>
            {/*<CreateButton />*/}
            <ChargesCreateInDialog caseId={props?.caseId} label={"Create"}></ChargesCreateInDialog>
            <ExportButton />
        </TopToolbar>
    );
};

