import * as React from "react";
import {
    AutocompleteInput,
    BooleanField,
    BulkDeleteButton,
    Datagrid,
    DeleteButton,
    List,
    ReferenceInput,
    required,
    SelectInput,
    ShowBase,
    SimpleForm,
    TextField,
    useDataProvider,
    useListContext,
    useRecordContext,
    WithRecord
} from 'react-admin';
import {Button} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {Link, useParams} from 'react-router-dom';
import {Page} from "../utils/Page";
import {BulkUpdateFormButton} from "@react-admin/ra-form-layout";
import {triggerDateOptions, triggerDateTitles} from "./triggerDate";
import {FlagField} from "../utils/FlagField";


export const ActionRulesShow = () => (
    <ShowBase>
        <WithRecord render={record => (
            <Page title={`${record.action_code} – ${record.action_name}: Country Rules`}>
                <ActionCountryRuleList></ActionCountryRuleList>
            </Page>
        )} />
    </ShowBase>
);

const BulkUpdateCountryRulesButton = () => {
    const { data, isLoading, selectedIds, onUnselectItems } = useListContext();
    const dataProvider = useDataProvider();
    if (isLoading) return null;
    const onSubmit = (formData: any) => {
        const selectedObjects = (data || []).filter((object: any) => selectedIds.includes(object.id));
        console.log(formData, selectedIds, selectedObjects);

        Promise.all(selectedObjects.map((object) => {
            const { action_rule, id, created_at, updated_at, ...restObject } = object;
            return dataProvider.create("action_country_rules", { data: { ...restObject, ...formData }})
                .then((result: any) => {
                    console.log("Result", result);
                    onUnselectItems();
                    setTimeout(() => window.location.reload(), 250);
                })
        }

));
    }

    return (
        <BulkUpdateFormButton label={"Copy to other Action Rule"} onSubmit={onSubmit} >
            <SimpleForm sx={{maxWidth: 400}} defaultValues={{ action_rule_id: null }} record={{}} onSubmit={onSubmit}>
                <ReferenceInput source="action_rule_id" reference="action_rules">
                    <AutocompleteInput
                        source="action_rule_id"
                        filterToQuery={(searchText: string) => ({"action_name,action_code": `${searchText}`})} label="Action rule"
                        optionText={(record: any) => `${record.action_code} – ${record.action_name}`}
                        optionValue={"id"}
                        fullWidth
                        validate={required()}
                    />
                </ReferenceInput>
                <SelectInput
                    source={"trigger_date"}
                    choices={triggerDateOptions.map((s) => ({ id: s, name: s}))}
                    label={"Override trigger date (empty means no override)"}
                />
            </SimpleForm>
        </BulkUpdateFormButton>
    );
};

const BulkMoveCountryRulesButton = () => {
    const { onUnselectItems } = useListContext();
    return (
        <BulkUpdateFormButton label={"Move"} mutationOptions={{ onSuccess: () => onUnselectItems() }}>
            <SimpleForm sx={{maxWidth: 400}} defaultValues={{ action_rule_id: null }} record={{}}>
                <ReferenceInput source="action_rule_id" reference="action_rules">
                    <AutocompleteInput
                        source="action_rule_id"
                        filterToQuery={(searchText: string) => ({"action_name,action_code": `${searchText}`})} label="Action rule"
                        optionText={(record: any) => `${record.action_code} – ${record.action_name}`}
                        optionValue={"id"}
                        fullWidth
                    />
                </ReferenceInput>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
};

const BulkEditTriggerDateButton = () => {
    const { onUnselectItems } = useListContext();
    return (
        <BulkUpdateFormButton label={"Edit trigger date"} mutationOptions={{ onSuccess: () => {
                    onUnselectItems();
                    setTimeout(() => window.location.reload(), 100);
                }}}>
            <SimpleForm sx={{maxWidth: 400}} record={{}}>
                <SelectInput source={"trigger_date"} choices={triggerDateOptions.map((s) => ({ id: s, name: s}))} validate={required()}/>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
};

const bulkActionButtons: React.ReactElement = <>
    <BulkUpdateCountryRulesButton />
    <BulkMoveCountryRulesButton />
    <BulkEditTriggerDateButton />
    <BulkDeleteButton mutationMode="pessimistic"/>
</>;


export const ActionCountryRuleList = (props: any) => {
    const { actionRuleId: paramsActionRuleId } = useParams();
    const record = useRecordContext();
    const actionRuleId = record?.id || paramsActionRuleId;
    return (
        <List resource="action_country_rules" filter={{ action_rule_id: actionRuleId }} filters={[]} perPage={100} sort={{ field: "country_code", order: "ASC" }} storeKey={`action-rule-${actionRuleId}`}>
            <Datagrid rowClick={false} bulkActionButtons={bulkActionButtons} >
                <TextField source={"country_code"} label={false} />
                <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                <TextField source={"entity_size"} label={"Entity size"}/>
                <TriggerDateField source={"trigger_date"} />
                <TextField source={"days_until_due_date"} />
                <TextField source={"months_until_due_date"} />
                <TextField source={"years_until_due_date"} />
                <BooleanField source={"end_of_month_due_date"} />
                <TextField source={"with_fine_months_from_due_date"} />
                <TextField source={"official_fee"} />
                <TextField source={"official_fee_per_claim"} />
                <TextField source={"official_fee_per_extra_class"} />
                <TextField source={"num_classes_included"} />
                <TextField source={"official_fee_currency"} />
                <TextField source={"agent_fee"} />
                <TextField source={"agent_fee_currency"} />
                <TextField source={"our_fee"} />
                <TextField source={"our_fee_currency"} />
                <TextField source={"translation_fee_per_word"} />
                <TextField source={"translation_fee_currency"} />
                <TextField source={"override_action_name"} />
                <CountryRuleEditButton />
                <DeleteButton mutationMode="pessimistic"></DeleteButton>
            </Datagrid>
        </List>
    );
};

const TriggerDateField = (props: any) => {
    const record = useRecordContext();
    return <TextField source={"trigger_date"} record={record} title={triggerDateTitles[record?.trigger_date]} {...props} />;
}

const CountryRuleEditButton = () => {
    const actionCountryRule = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/action_rules/${actionCountryRule?.action_rule_id}/country_rules/${actionCountryRule?.id}`}
            startIcon={<EditIcon />}
        >
            Edit
        </Button>
    );
};

