import {Edit, FormDataConsumer, SimpleForm, TextInput} from "react-admin";
import {
    CASE_FIELDS,
    CASE_TYPE_DESIGN,
    CASE_TYPE_INFRINGEMENT,
    CASE_TYPE_OPPOSITION,
    CASE_TYPE_PATENT,
    CASE_TYPE_TRADEMARK
} from "../list/CaseList";
import {casesRedirect} from "./utils";
import {CaseTitle} from "./CaseTitle";
import {
    DesignCaseInputFields,
    OppositionInputFields,
    PatentCaseInputFields,
    TrademarkCaseInputFields
} from "./CaseInputFields";

export const CaseEdit = (props: any) => {
    return (
        <Edit title={<CaseTitle/>} mutationMode="optimistic" redirect={casesRedirect} {...props} >
            <SimpleForm maxWidth={"sm"}>
                <TextInput disabled source="id"/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_PATENT) return <PatentCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_TRADEMARK) return <TrademarkCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_DESIGN) return <DesignCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_INFRINGEMENT) return <OppositionInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_OPPOSITION) return <OppositionInputFields />
                        return null;
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};