import {NextButton, PreviousButton, useWizardFormContext, WizardForm, WizardToolbarProps} from "@react-admin/ra-form-layout";
import * as React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    Datagrid,
    FileField,
    FileInput,
    FormDataConsumer,
    FunctionField,
    Labeled,
    Link,
    NumberField,
    ReferenceField,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    ShowBase,
    SimpleFormIterator,
    TextField,
    TextInput,
    Title,
    useGetList,
    useRecordContext,
    useSaveContext,
    WithRecord,
    WrapperField
} from "react-admin";
import {useIsAdmin} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {allCountries, countries, countriesToOptions, epStates, pctStates} from "../../utils/countries";
import {Box, Button, Grid, Stack, Toolbar as MUIToolbar, Typography} from "@mui/material";
import { useLocation, useParams } from "react-router";
import {FieldValues, useFormContext, useFormState } from "react-hook-form";
import {
    CountryFlagField,
    countryOption,
    LocalAgentField,
    LocalAgentInput,
    SelectCountriesWizardToolbar
} from "./common";
import { CountryField } from "../../utils/CountryField";
import {defaultAgentIdFunction, SelectCountriesEP} from "./SelectCountriesEP";
import {Flag} from "../../utils/Flag";
import {roundToTwoDecimals, yesNoChoices} from "../../common/common-utils";
import {noRefetch, useTenantContext} from "../../react-admin-overrides/AppLayout";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {SelectCountriesPCT} from "./SelectCountriesPCT";
import {SelectDirectApplications} from "./SelectDirectApplications";

export const SelectCountries = (props: any) => {
    const { id, type, case_action_id, ...rest } = useParams();
    if (!id) return null;
    return (
        <ShowBase resource={"cases"} id={id}>
            <WithRecord render={caseRecord => {
                if (type === "direct") {
                    return <SelectDirectApplications />;
                }

                const isEP = caseRecord.country_code === "ep";
                const isPCT = caseRecord.country_code === "pc";
                if (!isEP && !isPCT) return null;

                return isEP ? <SelectCountriesEP /> : <SelectCountriesPCT />;
            }}/>
        </ShowBase>

    );
};
