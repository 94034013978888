import * as React from "react";
import {useIsAdmin} from "../../auth/utils";
import {CopyCaseButton} from "../copy/CopyCaseButton";
import {CreateActionsButton} from "../actions/CreateActionsButton";
import {SyncWithEPOButton} from "../crud/CaseSyncWithEPO";
import {RecreateActionsButton} from "../crud/show/CaseShow";
import {ChargesCreateInDialog} from "../../charges/charges";
import {CaseActionsButton, CaseDocumentsButton, EPCTButton, SelectCountriesButton} from "./CaseList";
import {EditButton, ShowButton, useRecordContext } from "react-admin";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const DotDotDotMenu = (props: { allowedToModify?: boolean }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {allowedToModify} = props;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="actions-menu"
                aria-controls={open ? 'case-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="case-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
                MenuListProps={{
                    'aria-labelledby': 'actions-menu',
                }}
            >
                {allowedToModify && (
                    <MenuItem onClick={handleClose}>
                        <EditButton/>
                    </MenuItem>
                )}
                <MenuItem onClick={handleClose}>
                    <ShowButton label={"Details"}/>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <CaseActionsButton/>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <CaseDocumentsButton/>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <SelectCountriesButton/>
                </MenuItem>
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <CopyCaseButton label={"Copy"}/>
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <CreateActionsButton/>
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <SyncWithEPOButton/>
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <RecreateActionsButton/>
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <EPCTButton/>
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem onClick={handleClose}>
                        <ChargesCreateInDialog caseId={record?.id}/>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}