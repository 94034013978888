import {Box} from "@mui/material";
import {Flag} from "../../utils/Flag";
import {countries} from "../../utils/countries";
import {Question} from "./Question";
import * as React from "react";

export const CountryQuestions = (props: any) => {
    const {countryCode, questions} = props;

    return (
        <Box>
            <h3><Flag countryCode={countryCode}></Flag>{` ${countries[countryCode]}`}</h3>
            {
                questions.map((question: any, index: number) => (
                    <Question countryCode={countryCode} questionText={question.question}
                              questionSelectLabel={question.label} choices={question.choices}
                              questionSource={question.source}></Question>
                ))
            }
        </Box>
    );
}