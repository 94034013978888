import {WizardForm} from "@react-admin/ra-form-layout";
import * as React from "react";
import {
    ArrayInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    choices,
    Create,
    Datagrid,
    DateInput, FileField, FileInput,
    FormDataConsumer,
    Link,
    required,
    SelectInput,
    SimpleFormIterator,
    TextField,
    TextInput,
    Title,
    useGetList,
    useRecordContext,
    WithRecord,
    WrapperField
} from "react-admin";
import {useIsAdmin, useUserId} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {
    allCountries,
    countries,
    countriesOptions,
    countriesToOptions, nonPctParisStates,
    nonPCTStates,
    pctStates
} from "../../utils/countries";
import {Box, Chip, Stack, Tooltip, Typography} from "@mui/material";
import {FieldValues} from "react-hook-form";
import {
    CountryFlagField,
    countryOption,
    CurrencyField,
    LocalAgentField,
    LocalAgentInput,
    SelectCountriesWizardToolbar
} from "./common";
import {defaultAgentIdFunction} from "./SelectCountriesEP";
import {noRefetch, useTenantContext} from "../../react-admin-overrides/AppLayout";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {submitOrdersMutation} from "./order_mutation";
import {Spinner} from "../../utils/Spinner";
import {ErrorDialog} from "../../utils/ErrorDialog";
import {SuccessDialog} from "../../utils/SuccessDialog";
import {directApplicationPricesExportToExcel} from "./pct-prices-exporter";
import DownloadIcon from '@mui/icons-material/GetApp';
import HelpIcon from '@mui/icons-material/InfoOutlined';
import {CaseLink} from "../crud/CaseRefField";
import {ListContextRawData} from "../../utils/ListContextRawData";
import {useParams} from "react-router";
import {priceCalc, sumField} from "./pct-price-calc";
import {countryQuestions} from "./pct-countries-questions";
import { uploadFileToBucket } from "../../documents/documents";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const questions = [
    "Have you requested international preliminary examination and filed amendments under PCT article 34?",
    "Have you filed amendments under PCT article 19?",
    "Does the application contain sequence listings?",
    "Does the invention relate to or use biological material deposited under rule 28 EPC?",
    "Do you want to file voluntarily amended claims?",
]

const questionInventorRights = {
    question: "How does the applicant have the rights to the invention?",
    source: "question_invention_rights",
}

const commonStates = ["no", "us", "ep", "ca"];

export const SelectDirectApplications = (props: any) => {
    const { id } = useParams();
    const caseRecord = useRecordContext();
    const { loading: isSubmittingOrder, data: orderData, error: orderError, makeApiCall } = useHasuraRequest();
    const userId = useUserId();
    const isAdmin = useIsAdmin();
    const { tenant } = useTenantContext()  || {};
    const { data, isLoading, error } = useGetList('pct_country_prices', {
        pagination: { perPage: 10000, page: 1 },
    }, noRefetch);

    const { data: eurData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "EUR" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    const { data: usdData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "USD" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    console.log(caseRecord, data, eurData);

    if (!id) return null;
    if (!caseRecord) return null;
    if (!data) return null;
    if (!eurData) return null;

    // const oneEURToNOK = 1 / eurData[0].exchange_rate;
    const oneEURToNOK = (1 / eurData[0].exchange_rate) * 1.05;
    // const oneEURToUSD = oneEURToNOK * (usdData?.[0]?.exchange_rate || 0);
    const oneEURToUSD = (oneEURToNOK * (usdData?.[0]?.exchange_rate || 0)) * 1.05;
    const currencyConvert = { currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD };

    console.log("DATA", data);

    const onSubmit = async (formData: any) => {
        console.log("SUBMIT", formData);
        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
        const totalPrice = sumField(countriesDatagrid, "total");

        const bucketFiles = await Promise.all((formData?.files || [])
            .filter((file: any) => file.rawFile.name)
            .map(async (file: any) => {
                const contentType = file.rawFile.type; // e.g., "text/plain"
                const fileExtension = file.rawFile.name.split('.').pop(); // e.g., "txt"

                const response = await uploadFileToBucket(contentType, fileExtension, file.rawFile);

                return {
                    type: "File",
                    bucket_file_name: response.Key,
                    original_file_name: file.rawFile.name,
                    title: file.rawFile.name,
                    uploaded_by: userId,
                };
            }));

        const order_files = bucketFiles.map((bucketFile: any) => ({
            file: {
                data: {
                    id: uuidv4(),
                    ...bucketFile
                }
            },
        }));

        const direct_application_order_lines = (formData.countries || []).map((country_code: string) => {
            const { agent, ...countryAnswers } = formData[country_code] || {};
            const questionsConfig = countryQuestions[country_code];
            const answers = questionsConfig ?
                questionsConfig.map((question: any, index: number) => ({
                    q: question.question,
                    a: countryAnswers[question.source],
                }))
                : [];


            const priceConfig = countriesDatagrid.find((record: any) => record.country_code === country_code);
            const prices = priceConfig ? {
                price: priceConfig.total,
                price_currency: "EUR",
                translation_fee: priceConfig.translation_fees,
                service_fee: priceConfig.service_fees,
                official_fee: priceConfig.official_fees,
            } : {};
            return {
                country_code,
                agent_id: formData[country_code]?.agent,
                answers,
                ...prices
            };
        });

        const order = {
            tenant_id: tenant?.id,
            created_by: userId,
            order_type: "DIRECT_APPLICATION",
            portal_price: totalPrice,
            order_files: {
                data: order_files
            },
            direct_application_orders: {
                data: {
                    case_id: caseRecord.id,
                    answers: [
                        ...questions.map((question, index) => ({
                            q: question,
                            a: formData[`question_${index}`],
                        })),
                        {
                            q: questionInventorRights.question,
                            a: formData[questionInventorRights.source],
                        }
                    ],
                    priority: formData.priority,
                    user_reference: formData.your_reference,
                    user_comments: formData.comments,
                    direct_application_order_lines: {
                        data: direct_application_order_lines
                    }
                }
            }
        }
        console.log("ORDER", order);

        makeApiCall({
            query: submitOrdersMutation,
            variables: {
                orders: [order]
            }
        });
    }

    const hasPrices = (country: {id: string, name: string}) => data.find((p: any) => p.country_code === country.id);
    const withoutPrices = (country: {id: string, name: string}) => !data.find((p: any) => p.country_code === country.id);

    const nonPCTOptions = countriesToOptions(nonPctParisStates);
    const commonCountries = countriesToOptions(pctStates).filter(country => commonStates.includes(country.id));
    const restPctCountries = countriesToOptions(pctStates).filter(country => !commonStates.includes(country.id));
    const countryPriceCalc = priceCalc(caseRecord, data);
    const defaultAgentId = defaultAgentIdFunction(data);

    const priorityFromCase = {
        application_number: caseRecord.application_number,
        filing_date: caseRecord.filing_date,
        application_country: caseRecord.country_code?.toLowerCase(),
    };

    return (
        <Page title={`File Direct Applications claiming priority from ${caseRecord.country_code?.toUpperCase()}${caseRecord.application_number} of ${moment(caseRecord.filing_date).format("ll")}`} center body={
            <p>
                <Stack my={2} textAlign={"center"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={1} divider={<span>–</span>}>
                        { caseRecord.application_number ? <ApplicationNumberField /> : null}
                        { caseRecord.tenant_case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.tenant_case_ref} target={"_blank"}/> : null }
                        { caseRecord.case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.case_ref} target={"_blank"}/> : null }
                    </Stack>
                    { caseRecord.invention_title && <Box>{caseRecord.invention_title}</Box> }
                    { caseRecord.applicant && <Box>{caseRecord.applicant}</Box> }
                    {/*<Tooltip title=></CaseImagePreview>} arrow>*/}
                    {/*    <span>{caseRecord.application_number}</span>*/}
                    {/*</Tooltip>*/}
                </Stack>
            </p>
        }>
            <Title title="Breeze IP – Direct Applications" />
            {
                isSubmittingOrder && <Spinner></Spinner>
            }
            {
                orderError &&
                <ErrorDialog ></ErrorDialog>
            }

            {
                orderData &&
                <SuccessDialog></SuccessDialog>
            }
            <Box maxWidth={"lg"}>
                <Create resource={"orders"}>
                    <WizardForm
                        toolbar={<SelectCountriesWizardToolbar />}
                        onSubmit={onSubmit}
                    >
                        <WizardForm.Step label="Select countries">
                            <Stack>
                                <Stack spacing={1} marginBottom={2}>
                                    <Box>You are approaching the due date for filing of direct patent applications. The applications have to be translated and filed with the respective Patent Offices before this date, so we urge you to send instructions about two months before this date, and latest at least one month before this due date.</Box>
                                    <Box>The easiest way to file such applications is to select countries and regions below, answer some case relevant questions and send the filing order. Breeze IP will take care of the national/regional filings, appoint the local agents, file translations and power of attorney and assignment documents.</Box>
                                    <Box>We will send you one package of documents to be signed, one invoice and one filing report.</Box>
                                    <Box>We have agreement and fixed prices with agents in the first group of countries, and we can get a quote for countries in the second group of countries (show all designated states). The costs are based on an English application text (i.e. that the PCT application is published in English) and on an electronic counting of words, pages and claims.</Box>
                                </Stack>

                                <h4>Non PCT members</h4>
                                <CheckboxGroupInput
                                    source="countries"
                                    label={false}
                                    choices={nonPCTOptions}
                                    optionText={countryOption}
                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                    row={true}
                                />

                                {/*<h4> </h4>*/}
                                <BooleanInput source={"show_common_pct_states"} label={"Show common PCT members"}></BooleanInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        if (!formData.show_common_pct_states) return null;
                                        return (
                                            <>
                                                <CheckboxGroupInput
                                                    source="countries"
                                                    label={false}
                                                    choices={commonCountries}
                                                    optionText={countryOption}
                                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                                    row={true}
                                                />
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>

                                <BooleanInput source={"show_rest_pct_states"} label={"Show all PCT members"}></BooleanInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        if (!formData.show_rest_pct_states) return null;
                                        return (
                                            <>
                                                <CheckboxGroupInput
                                                    source="countries"
                                                    label={false}
                                                    choices={countriesToOptions(pctStates)}
                                                    optionText={countryOption}
                                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                                    row={true}
                                                />
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>

                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId);
                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;
                                        const inkSumLine = [...countriesDatagrid, { name: "Sum", total: sumField(countriesDatagrid, "total")}]
                                        return (
                                            <ListContextRawData data={inkSumLine}>
                                                <Datagrid bulkActionButtons={false} sort={{ field: "name", order: "ASC" }} expand={isAdmin ? <PriceCalculationExplanation formData={formData}></PriceCalculationExplanation> : undefined}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <WrapperField label={"Official fees"} >
                                                        <Box sx={{textWrap: "nowrap", display: "block"}}>
                                                            <CurrencyField source={"official_fees"} currency={"EUR"}></CurrencyField>
                                                            <WithRecord render={(r: any) =>
                                                                r?.country_code === "ep" ?
                                                                    <Tooltip title={
                                                                        <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                                                            We have not included the EP search fee as we assume that a search in the PCT phase has been conducted by EPO or USPTO.
                                                                        </Stack>
                                                                    }>
                                                                        <HelpIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></HelpIcon>
                                                                    </Tooltip> : null }
                                                            />
                                                        </Box>
                                                    </WrapperField>
                                                    <CurrencyField source={"service_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"translation_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD }}></CurrencyField>
                                                    {/*<CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}></CurrencyField>*/}
                                                    {/*<SelectInput source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                                </Datagrid>
                                            </ListContextRawData>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            </Stack>
                        </WizardForm.Step>
                        <WizardForm.Step label="Priority">
                            Set priority claims for the application.
                            <ArrayInput source="priority" label={false} defaultValue={priorityFromCase} >
                                <SimpleFormIterator inline disableReordering>
                                    <TextInput source="application_number" label="Application number" validate={required()} sx={{maxWidth: 200}}></TextInput>
                                    <DateInput source="filing_date" label="Filing date" validate={required()} sx={{maxWidth: 200}}></DateInput>
                                    <SelectInput source="application_country" label="Country" choices={countriesOptions} validate={required()} sx={{maxWidth: 200}}></SelectInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </WizardForm.Step>
                        <WizardForm.Step label="Documents">
                            Please provide the following documents which are required for the application:
                            <ul>
                                <li>Comb - Application text</li>
                                <li>Description</li>
                                <li>Patent claims</li>
                                <li>Abstract</li>
                                <li>Drawings</li>
                                <li>English translations of description, claims, abstract and drawings with English text</li>
                            </ul>

                            <FileInput source="files" label="Documents" multiple
                                       maxSize={10000000} // 10 MB
                                       accept={{
                                           "application/*": [".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".msg", ".zip"],
                                           "text/*": [".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".msg"],
                                           "image/*": [".jpg", ".jpeg", ".png"],
                                       }}
                                       options={{
                                           onDropRejected: (arg: any) => { console.log("REJECTED", arg) },
                                           onDrop: (arg: any) => { console.log("DROP", arg) }
                                       }}
                                       sx={{'& .RaFileInput-dropZone': { backgroundColor: "#eee" }}}
                                       validate={required()}
                            >
                                <FileField source="src" title="title" target={"_blank"}/>
                            </FileInput>
                        </WizardForm.Step>
                        {/*<WizardForm.Step label="Questions">*/}
                        {/*    Please answer the questions below. The answers may change the estimated costs.*/}
                        {/*    <h3>General questions</h3>*/}
                        {/*    {*/}
                        {/*        questions.map((question, index) => (*/}
                        {/*            <Labelled label={question}>*/}
                        {/*                <SelectInput*/}
                        {/*                    source={`question_${index}`}*/}
                        {/*                    label={false}*/}
                        {/*                    validate={required()}*/}
                        {/*                    choices={yesNoChoices}*/}
                        {/*                    sx={{ minWidth: 200}}*/}
                        {/*                    fullWidth={false}*/}
                        {/*                ></SelectInput>*/}
                        {/*            </Labelled>*/}
                        {/*        ))*/}
                        {/*    }*/}

                        {/*    <Labelled label={questionInventorRights.question}>*/}
                        {/*        <SelectInput*/}
                        {/*            source={questionInventorRights.source}*/}
                        {/*            label={"Invention rights"}*/}
                        {/*            validate={required()}*/}
                        {/*            choices={*/}
                        {/*                [*/}
                        {/*                    "Applicant is the inventor",*/}
                        {/*                    "Inventor assigned the rights to the applicant",*/}
                        {/*                    "The inventor was employed by applicant",*/}
                        {/*                    "Other"*/}
                        {/*                ]*/}
                        {/*                    .map((label:string) => ({ id: label, name: label }))}*/}
                        {/*            sx={{ minWidth: 200}}*/}
                        {/*            fullWidth={false}*/}
                        {/*        ></SelectInput>*/}
                        {/*    </Labelled>*/}

                        {/*    <FormDataConsumer>*/}
                        {/*        {({ formData, ...rest }) => {*/}
                        {/*            const selectedCountries = formData.countries || [];*/}
                        {/*            return (*/}
                        {/*                <>*/}
                        {/*                    {*/}
                        {/*                        selectedCountries.map((countryCode: string) => {*/}
                        {/*                            const questions = countryQuestions[countryCode];*/}
                        {/*                            if (!questions) return null;*/}
                        {/*                            return (*/}
                        {/*                                    <CountryQuestions countryCode={countryCode} questions={questions}></CountryQuestions>*/}
                        {/*                                );*/}
                        {/*                        })*/}
                        {/*                    }*/}
                        {/*                </>*/}
                        {/*            );*/}
                        {/*        }}*/}
                        {/*    </FormDataConsumer>*/}
                        {/*</WizardForm.Step>*/}
                        <WizardForm.Step label="Select Agents">
                            {
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
                                            ?.filter((record: any) => record.total && record.total > 0);
                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;

                                        return (
                                            <ListContextRawData data={countriesDatagrid}>
                                                <p>Please select the local agents you want use. The costs will increase if you choose agents that we do not have agreements with.</p>
                                                <Datagrid bulkActionButtons={false} sort={{ field: "name", order: "ASC" }}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={{ currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD }}/>
                                                    {/*<CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}/>*/}
                                                    <LocalAgentInput
                                                        countryCodeSource={"country_code"}
                                                        hidden={(record: any) => Boolean(!record.total)}
                                                        required={true}
                                                        defaultValueSource={"defaultAgentId"}></LocalAgentInput>
                                                </Datagrid>
                                            </ListContextRawData>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            }
                        </WizardForm.Step>
                        <WizardForm.Step label="Review & send order">
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
                                    if (!countriesDatagrid || countriesDatagrid.length === 0) return null;
                                    const inkSumLine = [...countriesDatagrid, { name: "Total", total: sumField(countriesDatagrid, "total")}]

                                    console.log(formData);
                                    const questionsDatagrid = Object.keys(formData || {})
                                        .filter(key => key?.startsWith("question_"))
                                        .map((key: string) => {
                                            if (key === questionInventorRights.source) {
                                                return {
                                                    question: questionInventorRights.question,
                                                    answer: formData[key]
                                                }
                                            }
                                            return {
                                                question: questions[Number(key.split("_")[1])],
                                                answer: formData[key]
                                            };
                                        });
                                    console.log(questionsDatagrid)
                                    return (
                                        <ListContextRawData data={inkSumLine}>
                                            <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                                                <Box>Please check the order, accept Breeze IP’s terms and conditions, and send the order.</Box>
                                                <Button label={`Excel Export ${currencyConvert.currency_code} Prices`} onClick={() => directApplicationPricesExportToExcel(inkSumLine, caseRecord, currencyConvert, tenant?.pct_fee)} endIcon={<DownloadIcon></DownloadIcon>} />
                                            </Stack>
                                            <Datagrid
                                                sort={{ field: "name", order: "ASC" }}
                                                bulkActionButtons={false}
                                                expand={isAdmin ? <PriceCalculationExplanation formData={formData}></PriceCalculationExplanation> : undefined}
                                                // rowSx={(record: any, index: number) => index === inkSumLine.length - 1 ? { fontWeight: "bold !important" } : { fontWeight: 700 }}
                                            >
                                                <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                <LocalAgentField countryCodeSource={"country_code"} label={"Agent"}></LocalAgentField>
                                                <CurrencyField source={"official_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"service_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"translation_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={currencyConvert}></CurrencyField>
                                                {/*<CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}></CurrencyField>*/}
                                                {/*<SelectInput source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                            </Datagrid>
                                            <Box my={4}>
                                                <strong>Priority claims:</strong>
                                                {
                                                    formData?.priority?.map((priority: any, index: number) => (
                                                        <Chip
                                                            key={index}
                                                            label={`${allCountries[priority.application_country]}/${priority.application_country?.toUpperCase()} ${priority.application_number} ${priority.filing_date}`}
                                                            sx={{ ml: 2, my: .5 }}
                                                        />
                                                    ))
                                                }
                                            </Box>
                                            {/*<Box mt={2}>*/}
                                            {/*    <strong>Documents:</strong>*/}
                                            {/*    {*/}
                                            {/*        formData?.files?.map((file: any, index: number) => (*/}
                                            {/*            <Chip*/}
                                            {/*                key={index}*/}
                                            {/*                label={`${file.title}`}*/}
                                            {/*                sx={{ ml: 2, my: .5 }}*/}
                                            {/*            />*/}
                                            {/*        ))*/}
                                            {/*    }*/}
                                            {/*</Box>*/}
                                            <Box mt={2}>
                                                <strong>Documents:</strong>
                                                {
                                                    formData?.files?.map((file: any, index: number) => (
                                                        <Box>
                                                            <Link
                                                                to={URL.createObjectURL(file.rawFile)}
                                                                title={file.rawFile.name}
                                                                target={"_blank"}
                                                                download={file.rawFile.name}
                                                            >
                                                                {file.rawFile.name}
                                                            </Link>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                            <Stack direction={"row"} spacing={15} justifyContent={"space-between"} marginTop={4}>
                                                {
                                                    questionsDatagrid && questionsDatagrid.length > 0 &&
                                                    <Box maxWidth={700} flex={1}>
                                                        <Datagrid data={questionsDatagrid || []} bulkActionButtons={false}>
                                                            <TextField source="question" label={<strong>Questions</strong>}/>
                                                            <TextField source="answer" label={false} />
                                                        </Datagrid>
                                                    </Box>
                                                }
                                                <Box>
                                                    <Datagrid data={[
                                                        { count_name: "Number of words in application", count: caseRecord.word_count || "N/A" },
                                                        { count_name: "Number of words in claims", count: caseRecord.word_count_claims_only || "N/A" },
                                                        { count_name: "Number of claims", count: caseRecord.claims_count || "N/A" },
                                                        { count_name: "Number of independent claims", count: caseRecord.independent_claims_count || "N/A" },
                                                        { count_name: "Number of pages in application", count: caseRecord.page_count || "N/A" },
                                                    ]} bulkActionButtons={false}>
                                                        <TextField source="count_name" label={<strong>Relevant counts</strong>}/>
                                                        <TextField source="count" label={false} textAlign={"right"}/>
                                                    </Datagrid>
                                                </Box>
                                            </Stack>

                                            <Box marginTop={4}><h4>Additional information</h4></Box>
                                            <Stack direction={"row"} spacing={15} marginTop={4}>
                                                <Box maxWidth={400}>
                                                    <Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>
                                                        <div>If you have a reference number or additional comments, please enter them below.</div>
                                                        {/*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*/}
                                                    </Typography>
                                                    <TextInput source="your_reference" fullWidth defaultValue={caseRecord.tenant_case_ref || caseRecord.case_ref}></TextInput>
                                                    <TextInput source="comments" multiline fullWidth minRows={3}></TextInput>
                                                </Box>
                                                <Box flex={1}>
                                                    {/*<Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>*/}
                                                    {/*    <div>If you have made any changes to the application, you can upload the revised version(s) here</div>*/}
                                                    {/*    /!*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*!/*/}
                                                    {/*</Typography>*/}

                                                    {/*<FileInput source="files" label="Upload files" isRequired multiple sx={{ '& .RaFileInput-dropZone': { height: "100%", backgroundColor: "#eee", display: "flex", alignItems: "center", justifyContent: "center" }, height: "100%" }}>*/}
                                                    {/*    <FileField source="src" title="title"/>*/}
                                                    {/*</FileInput>*/}
                                                </Box>
                                            </Stack>

                                            <Box marginTop={4}>
                                                <BooleanInput label={<span>I accept Breeze IP's <Link to={"/terms-pct"} target={"_blank"}>terms and conditions</Link></span>} source="terms_accepted" validate={choices([true], "The terms has to be accepted")} />
                                            </Box>
                                        </ListContextRawData>
                                    )
                                }}
                            </FormDataConsumer>
                        </WizardForm.Step>
                    </WizardForm>
                </Create>
            </Box>
        </Page>
    );
}


const createCountriesDatagrid = (formData: FieldValues, countryPriceCalc: any, defaultAgentId: any) => formData.countries?.map((code: string) => ({
    country_code: code,
    name: allCountries[code],
    ...countryPriceCalc(code, formData[code]?.applicant_employees),
    defaultAgentId: defaultAgentId(code)
}));

const PriceCalculationExplanation = (props: any) => {
    const record = useRecordContext();
    console.log("EXPANDED", record);
    const calculations = record?.official_fee_calculations;
    if (!calculations) return null;
    return (
        <Box>
            <Box>Official fee calculations in EUR (1 {calculations.official_fee_currency}
                ≈ {calculations.official_fee_currency_factor} EUR)
            </Box>
            <Box>Basic fee: {calculations.basic_filing_fee_eur}
                ({calculations.basic_filing_fee} {calculations.official_fee_currency}{calculations.applicant_employees ?
                    <span> | Entity status: {calculations.applicant_employees}</span> : ""})
            </Box>

            <Box>Extra claims fee: {calculations.extra_claims_fee_eur}</Box>
            <Box>Extra independent claims fee: {calculations.extra_independent_claims_fee_eur}</Box>
            <Box>Extra pages fee: {calculations.extra_pages_fee_eur}</Box>
            <Box>Examination fee: {calculations.examination_fee}</Box>
            <Box>Official fee (total): {record.official_fees}</Box>
        </Box>
    );
}
