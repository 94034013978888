import {
    Datagrid,
    DatagridProps,
    DateField,
    FunctionField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useDataProvider,
    useListContext,
    useRecordContext,
    useRefresh,
    WithRecord
} from "react-admin";
import {useIsAdmin} from "../../../../auth/utils";
import React from "react";
import {ListContextRawData} from "../../../../utils/ListContextRawData";
import {Folder} from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import ArticleIcon from "@mui/icons-material/Article";
import {Box, Typography} from "@mui/material";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {DOCUMENT_FIELDS} from "../../../../documents/documents";
import {AddFolderButton} from "./AddFolderButton";
import {PreviewButton} from "./PreviewButton";
import {DownloadDocumentButton} from "./DownloadDocumentButton";

interface File {
    id: number;
    file_name: string;
    uploaded_by: number;
}

interface User {
    id: number;
    name: string;
}

export const DocumentsDataGrid = (props: DatagridProps) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const {data} = useListContext();
    const isAdmin = useIsAdmin();

    const [rootDocumentData, setRootDocumentData] = React.useState<any[]>([]);
    const [allFilesData, setAllFilesData] = React.useState<any[]>([]);
    const [foldersData, setFoldersData] = React.useState<any[]>([]);
    const [combinedData, setCombinedData] = React.useState<any[]>([]);
    const caseRecord = useRecordContext();
    const [users, setUsers] = React.useState<User[]>([]);

    const isFolderEmpty = (folderId: any, filesData: any[]) => {
        return !filesData.some(file => file.folder_id === folderId);
    };

    React.useEffect(() => {
        const getFolders = dataProvider.getList<File>('case_document_folders', {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},
            filter: {case_id: record?.id}
        })

        const getDocumentData = dataProvider.getList<File>('case_documents', {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},
            filter: {
                case_id: record?.id, folder_id: {
                    format: "hasura-raw-query",
                    value: {_is_null: true},
                }
            }
        })

        const getFiles = dataProvider.getList<File>('case_documents', {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},
            filter: {case_id: record?.id}
        })

        Promise.all([getFolders, getDocumentData, getFiles]).then(([folders, documents, files]) => {
            setFoldersData(folders.data);
            setRootDocumentData(documents.data);
            setAllFilesData(files.data);
        });
    }, [dataProvider, record?.id]);

    React.useEffect(() => {
        const normalizedData = [
            ...foldersData.map(folder => ({
                ...folder,
                isFolder: true,
                displayName: folder.name
            })),
            ...rootDocumentData.map(doc => ({
                ...doc,
                isFolder: false,
                displayName: doc?.file ? doc.file?.title : doc.title,
            })),
        ];
        setCombinedData(normalizedData);
    }, [rootDocumentData, foldersData]);

    return (
        <ListContextRawData data={combinedData}>
            <Datagrid
                sort={{field: "displayName", order: "ASC"}}
                expand={<FileGrid />}
                isRowExpandable={record => record && record.isFolder && !isFolderEmpty(record.id, allFilesData)}
                bulkActionButtons={false}
                rowClick={false}
            >
                <FunctionField
                    render={(record: any) =>
                        record.isFolder ? <Folder/> :
                            record?.file?.email ? <EmailIcon/> :
                                <ArticleIcon/>
                    }/>
                <TextField source="displayName" label={"Name"} sx={{width: '200px', display: 'block'}}/>
                <DateField source={"created_at"} showTime label={"Date created"}/>
                {!record?.isFolder && <TextField source={"file.original_file_name"} label={"File name"}/>}
                {record && !record.isFolder && <ReferenceField source="file.uploaded_by" reference="users" link={false}>
                    <TextField source={record.name}/>
                </ReferenceField>}
                <PreviewButton/>
                <DownloadDocumentButton/>
                <FunctionField
                    render={(record: { isFolder: any; id: any; }) => {
                        return record && !record.isFolder
                            ? <EditDocumentButton caseId={caseRecord?.id}/>
                            : <EditFolderButton/>
                    }}
                />
            </Datagrid>

        </ListContextRawData>

    );
}

const FileGrid = (props: any) => {
    const dataProvider = useDataProvider();
    const folderRecord = useRecordContext();
    const [filesData, setFilesData] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        dataProvider.getList<File>('case_documents', {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' },
            filter: {
                folder_id: folderRecord?.id
            }
        })
            .then(({ data }) => {
                setFilesData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dataProvider, folderRecord?.id]);


    return (
        <ListContextRawData data={filesData}>
            <Box sx={{
                pl: 4,
                pr: 0,
                width: "100%"
            }}>
                <Datagrid
                    sx={{
                        '& .RaDatagrid-headerCell': {
                            background: 'transparent',
                        },
                        '& .RaDatagrid-tbody': {
                            borderTop: 'none',
                        },
                        '& .RaDatagrid-row td:last-child': {
                            paddingRight: 0
                        },
                        // Add these styles to fix cell alignment when header is removed
                        '& .MuiTableCell-root': {
                            padding: '8px 16px',
                        },
                        '& .RaDatagrid-tbody .MuiTableCell-root': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '.MuiTableHead-root': {
                            display: 'none'
                        }
                    }}
                    sort={{ field: "title", order: "ASC" }}
                    empty={loading ? <Typography>Loading...</Typography> : <Typography>Empty folder</Typography>}
                    isRowSelectable={() => false}
                    bulkActionButtons={false}
                    rowClick={false}
                >
                    <ArticleIcon/>
                    <TextField source={"file.title"} sx={{ width: '224px', display: 'block' }}/>
                    <DateField source={"file.created_at"} showTime label={"Date created"}/>
                    <TextField source={"file.original_file_name"} label={"File name"}/>
                    <ReferenceField source="file.uploaded_by" label={"Uploaded by"} reference="users" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <PreviewButton />
                    <DownloadDocumentButton />
                    <WithRecord render={(record: any) => (
                        <EditDocumentButton caseId={record?.case_id}/>
                    )} />
                </Datagrid>
            </Box>
        </ListContextRawData>
    );
};

const EditFolderButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    if (!record?.isFolder) {
        return null;
    }

    return (
        <EditInDialogButton
            fullWidth
            mutationMode="pessimistic"
            title={`Edit folder '${record.name}'`}
            resource="case_document_folders"
            mutationOptions={{
                onSuccess: () => refresh()
            }}
        >
            <SimpleForm maxWidth="sm">
                <TextInput source="name" />
            </SimpleForm>
        </EditInDialogButton>
    );
};

const EditDocumentButton = (caseId: any) => {
    const refresh = useRefresh();
    const isAdmin = useIsAdmin();
    const caseDocument = useRecordContext();
    return (
        <EditInDialogButton
            fullWidth
            mutationMode={"pessimistic"}
            title={`Edit document '${caseDocument?.file?.title}'`}
            mutationOptions={{
                onSuccess: () => refresh()
            }}
            redirect={false}
        >
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm maxWidth={"sm"}>
                    {/*{ isAdmin && <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>}*/}

                    <TextInput source={"file.title"} resettable fullWidth/>
                    <Box display="flex" alignItems="center" gap={2}>
                        <ReferenceInput source="folder_id" reference={DOCUMENT_FIELDS.FOLDER} filter={{ case_id: caseId.caseId }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <AddFolderButton caseId={caseDocument?.case_id}/>
                    </Box>
                    {/*{isAdmin && <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>}*/}
                    {/*<CitationSpecificFields/>*/}
                </SimpleForm>
            </Box>
        </EditInDialogButton>
    );
};
