import React from "react";
import { Box } from "@mui/system";
import {AutocompleteInput,
    FunctionField, ReferenceField, ReferenceInput, SaveButton, TextField, useRecordContext, WrapperField } from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";
import {allCountries, euFlagCountries} from "../../utils/countries";
import {NextButton, PreviousButton, useWizardFormContext, WizardToolbarProps } from "@react-admin/ra-form-layout";
import {Grid, Toolbar as MUIToolbar} from "@mui/material";

export const CountryFlagField = (props: any) => {
    const { countryCodeSource, ...rest } = props;
    const record = useRecordContext();
    const countryCode = euFlagCountries.includes(record?.[countryCodeSource]) ?
        "eu" :
        record?.[countryCodeSource];
    return (
        <>
            <img
                loading="lazy"
                width="20"
                style={{verticalAlign: "middle"}}
                srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
                alt=""
            />
            <TextField {...rest} marginLeft={1}></TextField>
        </>
    );
};

export const LocalAgentInput = (props: any) => {
    const { countryCodeSource, defaultValueSource, hidden, source, ...rest } = props;
    const record = useRecordContext();
    const hide = hidden && typeof hidden === "function" ? hidden(record) : false;
    if (hide) return null;

    const countryCode = record?.[countryCodeSource];
    const defaultValue = record?.[defaultValueSource] || null;
    const countryName = allCountries[countryCode] || "";
    /*<SelectInput {...rest} label={`Local Agent (${countryName})`} source={`${countryCode}.agent`} choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]} fullWidth />*/
    return (
        <ReferenceInput reference="names" source={source || `${countryCode}.agent`} filter={{  type: "Agent", "agent_handles_patent_country_codes@_contains": [countryCode] }} label={"Local Agent"} >
            <AutocompleteInput {...rest} filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} optionText={"name"} label={`Local Agent (${countryName})`} defaultValue={defaultValue}/>
        </ReferenceInput>
    );
};

export const LocalAgentField = (props: any) => {
    const { countryCodeSource, ...rest } = props;
    const record = useRecordContext();
    const countryCode = record?.[countryCodeSource];
    const countryName = allCountries[countryCode] || "";
    /*<SelectInput {...rest} label={`Local Agent (${countryName})`} source={`${countryCode}.agent`} choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]} fullWidth />*/
    const formData = useFormContext();
    return (
        <WrapperField label={"Agent"}>
            <ReferenceField reference="names" source={`${countryCode}.agent`} label={"Local Agent"} record={formData.getValues()} link={false}>
                <TextField source={"name"} label={"Local Agent"} />
            </ReferenceField>
        </WrapperField>
    );
};

export const countryOption = (country: any) => {
    const countryCode = (euFlagCountries.includes(country.id) ? "eu" : country.id)?.toLowerCase();
    const sources = countryCode?.toLowerCase() === "ep" ?
        { src: `https://res.cloudinary.com/dhdixshqm/image/upload/v1719315116/tgjui8v59yipkjapyj2h.gif`} :
        countryCode?.toLowerCase() === "ea" ?
        { src: `https://res.cloudinary.com/dhdixshqm/image/upload/v1741009738/Screenshot_2025-03-03_at_14.48.04_zu3dlp.png`} :
            countryCode?.toLowerCase() === "oa" ?
        { src: `https://res.cloudinary.com/dhdixshqm/image/upload/v1741010014/oapi_logo_xnhjkg.jpg`} :
        {
            src: `https://flagcdn.com/w20/${countryCode}.png`,
            srcSet: `https://flagcdn.com/w40/${countryCode}.png 2x`,
        }

    return (
        <Box>
            <img
                loading="lazy"
                width="20"
                style={{verticalAlign: "middle"}}
                srcSet={sources.srcSet}
                src={sources.src}
                alt={``}
            />
            <Box component={"span"} marginLeft={1}>{country.name}</Box>
        </Box>
    );
};

export const SelectCountriesWizardToolbar = (props: WizardToolbarProps) => {
    const { children, ...rest } = props;
    const { hasNextStep, currentStep } = useWizardFormContext(props);

    return (
        <MUIToolbar {...rest}>
            {children ? (
                children
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <PreviousButton />
                    </Grid>
                    <Grid item>
                        {hasNextStep ? <NextButton /> : <SaveButton icon={<></>} label={`Send order`} />}
                    </Grid>
                </Grid>
            )}
        </MUIToolbar>
    );
};

export const CurrencyField = (props: any) => {
    const record = useRecordContext();
    const {source, currency, fallback, convert } = props;
    if (!source || !currency) return null;
    const amount = record?.[source];
    const displayAmount = convert ? Math.ceil(amount * convert.factor) : amount;
    const displayCurrency = convert ? convert.currency_code : currency;
    return (
        <FunctionField render={(record: any) => (
            <>
                {record[source] ? <span>{record[source]} {currency}</span> : <span>{fallback || ""}</span>}
                {convert && record[source] ? <div>≈ {displayAmount} {displayCurrency}</div> : null}
            </>
        )} {...props} />
    );
}
