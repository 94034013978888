import {applicationType2Options, applicationTypeOptions, CASE_FIELDS, caseTypeList, caseTypeOptions} from "../list/CaseList";
import {countriesOptions, INTERNATIONAL_TRADEMARK_APPLICATION} from "../../utils/countries";
import {CASE_STATUS_CLOSED, caseStatusList, isPCTOrEP, parseDesignatedStates, validateDesignatedStates} from "./utils";
import {ApplicantNameInput} from "./ApplicantNameInput";
import {AutocompleteInput, BooleanInput, DateInput, FormDataConsumer,
    ImageField,
    ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TextInput, useGetOne, useRecordContext } from "react-admin";
import {useTenantId} from "../../data/useTenantId";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { Box } from "@mui/system";
import {useIsAdmin} from "../../auth/utils";
import moment from "moment";


export const PatentCaseInputFields = (props: any) => {
    const tenantId = useTenantId();
    const isAdmin = useIsAdmin();
    return (
        <>
            <TextInput source={CASE_FIELDS.CASE_REF} fullWidth/>
            <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
            <SelectInput source={CASE_FIELDS.COUNTRY_CODE} choices={countriesOptions} label={"Country"} fullWidth/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return isPCTOrEP(formData[CASE_FIELDS.COUNTRY_CODE]) ?
                        <TextInput source={CASE_FIELDS.DESIGNATED_STATES} multiline validate={validateDesignatedStates(formData[CASE_FIELDS.COUNTRY_CODE])} {...rest} parse={parseDesignatedStates} fullWidth/> :
                        null;
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData[CASE_FIELDS.COUNTRY_CODE] === "pc" ?
                        <BooleanInput source={CASE_FIELDS.DEMAND_FOR_CHAPTER_2} fullWidth/> :
                        null;
                }}
            </FormDataConsumer>
            <TextInput source={CASE_FIELDS.APPLICATION_NUMBER} fullWidth />
            <TextInput source={CASE_FIELDS.CATCHWORD} fullWidth />
            <TextInput source={CASE_FIELDS.INVENTION_TITLE} fullWidth />
            <SelectInput source={CASE_FIELDS.APPLICATION_TYPE} choices={applicationTypeOptions} fullWidth />
            <DateInput source={CASE_FIELDS.FILING_DATE}  />
            <DateInput source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}  />
            <DateInput source={CASE_FIELDS.NATIONAL_FILING_DATE} />
            <DateInput source={CASE_FIELDS.REGISTRATION_DATE}  />
            <TextInput source={CASE_FIELDS.REGISTRATION_NUMBER} fullWidth />
            <DateInput source={CASE_FIELDS.PUBLICATION_DATE}  />
            <TextInput source={CASE_FIELDS.PUBLICATION_NUMBER} fullWidth />
            <DateInput source={"expiration_date"}  />
            <SelectInput source={CASE_FIELDS.STATUS} choices={caseStatusList.map((s) => ({ id: s, name: s}))} fullWidth />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData[CASE_FIELDS.STATUS] === CASE_STATUS_CLOSED ?
                        <DateInput source={"closed_at"} defaultValue={moment()} />
                        :
                        null;
                }}
            </FormDataConsumer>
            <DateInput source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE} />
            <TextInput source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER} fullWidth />
            <SelectInput source={CASE_FIELDS.PRIORITY_COUNTRY_CODE} choices={countriesOptions} label={"Priority country"} fullWidth />
            <ApplicantNameInput />
            <CaseTeamInput />
            <ReferenceInput source="primary_agent_id" reference="names" filter={{ type: "Agent" }}>
                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
            </ReferenceInput>
            <ReferenceInput source="local_agent_id" reference="names" filter={{ type: "Agent" }}>
                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
            </ReferenceInput>
            <ProductInput></ProductInput>
            <DepartmentInput></DepartmentInput>
            { isAdmin &&
                <NumberInput source={"word_count"} step={1} fullWidth label={"Word count (app. inc. claims)"}/>
            }
            { isAdmin &&
                <NumberInput source={"word_count_claims_only"} step={1} fullWidth label={"Claims Word count"} />
            }
            { isAdmin &&
                <NumberInput source={"claims_count"} step={1} fullWidth label={"Claims count"} />
            }
            { isAdmin &&
                <NumberInput source={"independent_claims_count"} step={1} fullWidth label={"Independent claims count"} />
            }
            { isAdmin &&
                <NumberInput source={"page_count"} step={1} fullWidth label={"Page count"} />
            }
        </>
    )
}


export const ProductInput = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext();
    const contextSpecificTenantId = record?.tenant_id || tenantId;
    const { data, isLoading } = useGetOne('tenants', { id: contextSpecificTenantId });
    const products = data?.products?.split(",")?.map((productName: string) => ({ name: productName?.trim() })) || [];

    return (
        <SelectInput
            source={CASE_FIELDS.PRODUCT}
            choices={products}
            optionText="name"
            optionValue="name"
            isLoading={isLoading}
            fullWidth
            {...props}
        />
    );
}

export const DepartmentInput = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext();
    const contextSpecificTenantId = record?.tenant_id || tenantId;
    const { data, isLoading } = useGetOne('tenants', { id: contextSpecificTenantId });
    const departments = data?.departments?.split(",").map((departmentName: string) => ({ name: departmentName?.trim() })) || [];

    return (
        <SelectInput
            source={CASE_FIELDS.DEPARTMENT}
            choices={departments}
            optionText="name"
            optionValue="name"
            isLoading={isLoading}
            fullWidth
            {...props}
        />
    );
}

export const CaseTeamInput = (props: any) => {
    const tenantId = useTenantId();
    return (
        <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: tenantId }} isRequired>
            <SelectInput optionText={"name"} fullWidth />
        </ReferenceInput>
    );
}


export const trademarkApplicationTypeOptions = ["National registration", "Priority founding application", "International registration", "Combined/figure", "Word"].map((s) => ({id: s, name: s}));

export const TrademarkCaseInputFields = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext()
    console.log(record);
    return (
        <>
            <TextInput source={CASE_FIELDS.CASE_REF} fullWidth />
            <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
            <ApplicantNameInput />
            <TextInput source={CASE_FIELDS.APPLICATION_NUMBER} fullWidth />
            <TextInput source={CASE_FIELDS.CATCHWORD} fullWidth />
            <TextInput source={"trademark_text_in_the_mark"} fullWidth />
            <DateInput source={CASE_FIELDS.FILING_DATE} />
            <SelectInput source={CASE_FIELDS.COUNTRY_CODE} choices={countriesOptions} label={"Country"} fullWidth />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData[CASE_FIELDS.COUNTRY_CODE] === INTERNATIONAL_TRADEMARK_APPLICATION ?
                        <TextInput source={CASE_FIELDS.DESIGNATED_STATES} multiline validate={validateDesignatedStates(formData[CASE_FIELDS.COUNTRY_CODE])} {...rest} parse={parseDesignatedStates} fullWidth /> :
                        null;
                }}
            </FormDataConsumer>
            <SelectInput source={CASE_FIELDS.APPLICATION_TYPE} choices={trademarkApplicationTypeOptions} fullWidth />
            <DateInput source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
            <DateInput source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
            <DateInput source={CASE_FIELDS.REGISTRATION_DATE}/>
            <TextInput source={CASE_FIELDS.REGISTRATION_NUMBER} fullWidth />
            <SelectInput source={CASE_FIELDS.STATUS} choices={caseStatusList.map((s) => ({ id: s, name: s}))} fullWidth />
            <TextInput source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER} fullWidth />
            <DateInput source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE} fullWidth />
            <SelectInput source={CASE_FIELDS.PRIORITY_COUNTRY_CODE} choices={countriesOptions} label={"Priority country"} fullWidth />
            <CaseTeamInput />
            <ProductInput></ProductInput>
            <DepartmentInput></DepartmentInput>
            <ImageInput source={"trademark_logo"} accept={{ "image/*": [".png", ".jpg", ".jpeg"]}}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <ReferenceManyInput reference="case_trademark_classes" target="case_id" sort={{ field: "class", order: "ASC" }}>
                <SimpleFormIterator inline>
                    <TextInput source="class" sx={{ minWidth: "20px" }}/>
                    <TextInput source="class_description" multiline sx={{ minWidth: "430px" }}/>
                </SimpleFormIterator>
            </ReferenceManyInput>
        </>
    )
}


export const designApplicationTypeOptions = ["National registration", "Priority founding application", "International registration", "Combined/figure", "Word"].map((s) => ({id: s, name: s}));
export const designApplicationType2List = [...designApplicationTypeOptions, "Divisional", "Provisional", "Continuation"];
export const designApplicationType2Options = designApplicationType2List.map((s) => ({id: s, name: s}));

export const DesignCaseInputFields = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext()
    console.log(record);
    return (
        <>
            <TextInput source={CASE_FIELDS.CASE_REF} fullWidth />
            <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
            <ApplicantNameInput />
            <TextInput source={CASE_FIELDS.APPLICATION_NUMBER} fullWidth />
            <DateInput source={CASE_FIELDS.FILING_DATE}/>
            <SelectInput source={CASE_FIELDS.COUNTRY_CODE} choices={countriesOptions} label={"Country"} fullWidth />
            <SelectInput source={CASE_FIELDS.APPLICATION_TYPE} choices={designApplicationTypeOptions}fullWidth />
            <SelectInput source={CASE_FIELDS.APPLICATION_TYPE_2} choices={designApplicationType2Options}fullWidth />
            <DateInput source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
            <DateInput source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
            <DateInput source={CASE_FIELDS.REGISTRATION_DATE}/>
            <TextInput source={CASE_FIELDS.REGISTRATION_NUMBER} fullWidth />
            <DateInput source={"expiration_date"}  />
            <SelectInput source={CASE_FIELDS.STATUS} choices={caseStatusList.map((s) => ({ id: s, name: s}))} fullWidth />
            <TextInput source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER} fullWidth />
            <DateInput source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
            <SelectInput source={CASE_FIELDS.PRIORITY_COUNTRY_CODE} choices={countriesOptions} label={"Priority country"} fullWidth />
            <CaseTeamInput />
            <ProductInput></ProductInput>
            <DepartmentInput></DepartmentInput>
        </>
    )
}

export const oppositionInfringementApplicationTypeOptions = ["Active", "Passive"].map((s) => ({id: s, name: s}));

export const OppositionInputFields = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext()
    console.log(record);
    return (
        <>
            <TextInput source={CASE_FIELDS.CASE_REF} fullWidth />
            <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
            <SelectInput source={CASE_FIELDS.COUNTRY_CODE} choices={countriesOptions} label={"Country"} fullWidth />
            <TextInput source={CASE_FIELDS.CATCHWORD} fullWidth />
            <SelectInput source={CASE_FIELDS.APPLICATION_TYPE} choices={oppositionInfringementApplicationTypeOptions} fullWidth />
            <CaseTeamInput />
            <ReferenceInput source="counter_party_id" reference="names" filter={{ "type@_neq": "Agent" }}>
                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
            </ReferenceInput>
            <ReferenceInput source="counter_party_agent_id" reference="names" filter={{ type: "Agent" }}>
                <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
            </ReferenceInput>
            <ProductInput></ProductInput>
            <DepartmentInput></DepartmentInput>
        </>
    )
}

export const SimpleCaseTypeInputFields = (props: any) => {
    const tenantId = useTenantId();
    const record = useRecordContext()
    return (
        <>
            <TextInput source={CASE_FIELDS.CASE_REF} fullWidth />
            <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
            <SelectInput source={CASE_FIELDS.COUNTRY_CODE} choices={countriesOptions} label={"Country"} fullWidth />
            <TextInput source={CASE_FIELDS.CATCHWORD} fullWidth />
            <CaseTeamInput />
            <ProductInput></ProductInput>
            <DepartmentInput></DepartmentInput>
        </>
    )
}
