import React from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    DateField,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SimpleForm,
    TextField,
    AutocompleteInput,
    required,
    useRefresh,
} from 'react-admin';
import {EditInDialogButton} from '@react-admin/ra-form-layout';
import Typography from '@mui/material/Typography';
import { useTenantId } from "../data/useTenantId";
import { Box, Tab, Tabs } from '@mui/material';

export const IncomingMailList = () => {
    const tenantId = useTenantId();
    const [tabValue, setTabValue] = React.useState('org');
    const refresh = useRefresh();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const filters = {
        org: { "tenant_id@_eq": tenantId },
        all: {},
        history: { "case_id@_is_null": false }
    };

    const currentFilter = filters[tabValue as keyof typeof filters];

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Organisation" value="org" />
                    <Tab label="All" value="all" />
                    <Tab label="History" value="history" />
                </Tabs>
            </Box>

            <List
                resource="incoming_mail"
                exporter={false}
                filter={currentFilter}
                sort={{ field: "created_at", order: "ASC" }}
            >
                <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                    <TextField source="subject" label="Subject" />
                    <DateField source="created_at" label="Received" showTime />
                    <FunctionField
                        label="Case ref"
                        render={(record: any) =>
                            record.file?.case_documents?.[0].case_id ? (
                                <>
                                    <ReferenceField
                                        source="case_id"
                                        reference="cases"
                                        record={record.file?.case_documents?.[0]}
                                    >
                                        <TextField source="case_ref" />
                                    </ReferenceField>

                                    <EditInDialogButton
                                        label="Override case"
                                        resource={"case_documents"}
                                        id={record.file?.case_documents?.[0].id}
                                        mutationMode={"pessimistic"}
                                        mutationOptions={{
                                            onSuccess: () => refresh()
                                        }}
                                    >
                                        <SimpleForm>
                                            <ReferenceInput
                                                source="case_id"
                                                reference="cases"
                                                filter={{
                                                    "tenant_id@_eq": tenantId
                                                }}
                                            >
                                                <AutocompleteInput
                                                    validate={required()}
                                                    fullWidth
                                                    optionText={(caseRecord) => 
                                                        `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.applicant || ""} – ${caseRecord.catchword || ""}`
                                                    }
                                                    filterToQuery={(searchText: string) => ({
                                                        "case_ref,application_number": `${searchText}`,
                                                    })}
                                                />
                                            </ReferenceInput>
                                        </SimpleForm>
                                    </EditInDialogButton>
                                </>
                            ) : (
                                <>
                                    <Typography>No case reference matched from email subject</Typography>
                                    <EditInDialogButton
                                        label="Set case"
                                        resource={"case_documents"}
                                        id={record.file?.case_documents?.[0].id}
                                        mutationMode={"pessimistic"}
                                        mutationOptions={{
                                            onSuccess: () => refresh()
                                        }}
                                    >
                                        <SimpleForm>
                                            <ReferenceInput
                                                source="case_id"
                                                reference="cases"
                                                filter={{
                                                    "tenant_id@_eq": tenantId
                                                }}
                                            >
                                                <AutocompleteInput
                                                    validate={required()}
                                                    fullWidth
                                                    optionText={(caseRecord) => 
                                                        `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.applicant || ""} – ${caseRecord.catchword || ""}`
                                                    }

                                                    filterToQuery={(searchText: string) => ({
                                                        "case_ref,application_number": `${searchText}`,
                                                    })}
                                                />
                                            </ReferenceInput>
                                        </SimpleForm>
                                    </EditInDialogButton>
                                </>
                            )
                        }
                    />
                    <ReferenceField source="tenant_id" reference="tenants" label={"Organisation"}>
                        <TextField source="name" label={"Organisation"} />
                    </ReferenceField>
                    <FunctionField
                        source="body"
                        label="Body"
                        render={(record: any) =>
                            record.body?.substring(0, 400) + (record.body?.length > 400 ? "..." : "")
                        }
                    />
                    <FunctionField
                        source="attachments"
                        label="Attachments"
                        render={(record: any) => {
                            try {
                                const attachments = record.file?.attachments || [];
                                return attachments.map((attachment: any, index: number) => (
                                    <div key={index}>{attachment.original_file_name}</div>
                                ));
                            } catch (e) {
                                return record.file?.attachments;
                            }
                        }}
                    />
                    <TextField source="from" />
                    <TextField source="to" />
                </Datagrid>
            </List>
        </Box>
    );
}; 