import {Box, Dialog, DialogContent, DialogTitle, Grid, Stack, Tab, Tabs} from "@mui/material";
import Mustache from "mustache";
import React, {useState} from "react";
import {BooleanInput, Button, choices, Form, useDataProvider, useListContext, useNotify, useRefresh} from "react-admin";
import {useUserId} from "../auth/utils";
import {countries} from "../utils/countries";
import {OrderType} from "../utils/utils";

const pctTable = (tableData: Array<any>) => `
    <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
        <tbody>
            <tr>
                <th>Our Ref</th>
                <th>PCT Application</th>
                <th>Country</th>
                <th>Amount</th>
                <th>Invoice #</th>
            </tr>
            ${tableData.map(row => `
                <tr>
                    <td>${row.case?.case_ref || ''}</td>
                    <td>${row.pct_order?.case?.application_number || ''}</td>
                    <td>${countries[row.case?.country_code] || ''}</td>
                    <td style="white-space: nowrap">${row.invoice?.total ? Number(row.invoice.total).toFixed(2) : ''} ${row.invoice?.currency || ''}</td>
                    <td>${row.invoice?.invoice_number || ''}</td>
                </tr>
            `).join('')}
        </tbody>
    </table>
`;

const epTable = (tableData: Array<any>) => `
    <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
        <tbody>
            <tr>
                <th>Our Ref</th>
                <th>EP Registration</th>
                <th>Country</th>
                <th>Amount</th>
                <th>Invoice #</th>
            </tr>
            ${tableData.map(row => `
                <tr>
                    <td>${row.case?.case_ref || ''}</td>
                    <td>EP${row.ep_order?.case?.application_number || ''}</td>
                    <td>${countries[row.case?.country_code] || ''}</td>
                    <td style="white-space: nowrap">${row.invoice?.total ? Number(row.invoice.total).toFixed(2) : ''} ${row.invoice?.currency || ''}</td>
                    <td>${row.invoice?.invoice_number || ''}</td>
                </tr>
            `).join('')}
        </tbody>
    </table>
`;

const order = (orderLine: any, type: OrderType)=>
    type === "PCT" ? orderLine?.pct_order?.order : orderLine?.ep_order?.order


export const SendFilingInvoiceEmailButton = (props: { type: OrderType }) => {
    const [open, setOpen] = useState(false);
    const [selectedInvoiceNumber, setInvoiceNumber] = useState<number | undefined>();
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const userId = useUserId();
    const refresh = useRefresh();
    const { type } = props;

    const selected = (data || []).filter((d: any) => selectedIds.includes(d.id));
    const selectedTenants = [...new Set(selected.map((d: any) => order(d, type)?.tenant?.id))];


    if (selectedTenants.length > 1) return null;

    const tenant = order(selected[0], type)?.tenant;

    console.log(selected[0], tenant);
    const handleClick = async () => {
        const emailTemplateId = props.type === "PCT" ? "15f22236-ed7c-4a90-b6c9-76ab15a21e9e" : "1d23973c-0933-4205-8552-17d9c63251e9";
        try {
            // Fetch email template and preview
            const response = await dataProvider.getOne('email_templates', {
                id: emailTemplateId
            });

            const invoiceIds = [...new Set(selected.map(row => row.invoice_id))];
            const invoicesResponse = await dataProvider.getList('invoices', {
                filter: {
                    "id@_in": invoiceIds
                },
                sort: {
                    field: "invoice_number",
                    order: "ASC"
                },
                pagination: {
                    page: 1,
                    perPage: 100,
                }
            });
            const invoices = invoicesResponse.data;

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});
            const recipientUsersResponse = await dataProvider.getMany('users', {
                ids: [...new Set(selected.map(row => order(row, type)?.user?.id))]
            });

            const tableData = selected.map(row => ({
                ...row,
                invoice: invoices.find(invoice => invoice?.id === row?.invoice_id)
            })).sort((a, b) => {
                const aNum = a.invoice?.invoice_number || 0;
                const bNum = b.invoice?.invoice_number || 0;
                return aNum - bNum;
            });

            console.log(sendingUserResponse, recipientUsersResponse)

            const htmlTable = props.type === "PCT" ? pctTable(tableData) : epTable(tableData);

            const recipientName = recipientUsersResponse.data.map((user: any) => user.name).join(', ');

            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', {
                table: htmlTable,
                recipientName,
                clientName: tenant?.name,
                senderName: sendingUserResponse.data?.name || "",
            }, {}, {escape: (text) => text});

            const emailSubject = Mustache.render(response.data?.subject || '', {recipientName});

            setEmailPreview(emailPreview);
            const mainRecipient = tenant.invoice_email;
            const ccEmails = Array.from(new Set(
                selected
                    .map(row => order(row, type)?.user?.email)
                    .filter(email => !!email && email !== mainRecipient)
            ));
            const recipientCcEmails = [...(tenant.invoice_cc_email ? [tenant.invoice_cc_email] : []), ...ccEmails];
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                subject: emailSubject,
                body: emailPreview,
                recipient_email: mainRecipient,
                recipient_cc_emails: recipientCcEmails,
                recipient_bcc_emails: [mainRecipient, ...recipientCcEmails].includes(BREEZE_EMAIL) ? [] : [BREEZE_EMAIL],
                invoices,
            });
            setInvoiceNumber(invoices[0]?.invoice_number)
            setOpen(true);
        } catch (error) {
            console.error(error);
            notify('Error loading email preview', {type: 'error'});
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        const { invoices, ...email } = emailData;
        try {
            const invoiceIds = (invoices || []).map((invoice: any) => invoice?.id);
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: {
                    ...email,
                    attachment_invoice_ids: invoiceIds,
                }
            });

            await dataProvider.updateMany('invoices', {
                ids: invoiceIds,
                data: {
                    invoice_email_id: createdEmail.id,
                    sent_by: userId,
                    sent_at: createdEmail.created_at,
                }
            });

            const orderLineResource = type === "PCT" ? "pct_order_lines" : "ep_order_lines";
            await dataProvider.updateMany(orderLineResource, {
                ids: selectedIds,
                data: {
                    invoiced_by: userId,
                    invoiced_at: createdEmail.created_at,
                }
            });

            notify('Email sent successfully', {type: 'success'});
            setOpen(false);
            onUnselectItems();
            refresh();
        } catch (error) {
            notify('Error sending email', {type: 'error'});
        }
    };

    return (
        <>
            <Button
                label="Send Invoices Email"
                onClick={handleClick}
                disabled={selectedIds.length === 0}
            />

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                <DialogTitle>Send Invoices Email</DialogTitle>
                <DialogContent>
                    <Form onSubmit={handleSend}>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Stack spacing={1} mb={2}>
                                    <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                                    <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                                    <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                                </Stack>
                                <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                                <Box mb={2}><strong>Email:</strong></Box>
                                <div dangerouslySetInnerHTML={{__html: emailPreview}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Tabs
                                    value={selectedInvoiceNumber}
                                    onChange={(_, value) => setInvoiceNumber(value)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {
                                        emailData?.invoices?.map((invoice: any) => (
                                            <Tab value={invoice?.invoice_number} label={`# ${invoice?.invoice_number}`}></Tab>
                                        ))
                                    }
                                </Tabs>

                                <Box height={"500px"}>
                                    {
                                        selectedInvoiceNumber &&
                                        <object width="100%"
                                                height={"100%"}
                                                data={`data:application/pdf;base64,${emailData?.invoices.find((i: any) => i.invoice_number === selectedInvoiceNumber)?.pdf}`}
                                                type="application/pdf">
                                        </object>
                                    }

                                </Box>
                            </Grid>
                        </Grid>
                        <Box marginTop={4}>
                            <BooleanInput label={<span>I have verified that all PDF invoices look correctly generated</span>} source="accepted" validate={choices([true], "PDFs have to be verified")} />
                        </Box>
                        <Button
                            label="Send Invoices Email (and mark as invoiced)"
                            type={"submit"}
                            sx={{mt: 2}}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};
