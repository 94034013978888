import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import moment from "moment";
import {
    DateInput,
    NumberInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useListContext,
    useRecordContext,
    useRefresh
} from "react-admin";
import {Alert, AlertTitle, Box, Typography} from "@mui/material";
// @ts-ignore
import {v4 as uuidv4} from "uuid";
import {useEffect, useState} from "react";
import {allCountries, countries} from "../utils/countries";
import {useUserId} from "../auth/utils";
import {useHasuraRequest} from "../utils/useHasuraRequest";
import {currencyOptions} from "../tenants/TenantCreate";
import {invoiceItemsFromPCTEPOrderLine} from "../invoicing";
import {Spinner} from "../utils/Spinner";
import {ButtonDialog} from "../utils/ButtonDialog";
import {OrderType} from "../utils/utils";

const createPCTInvoiceMutation = `
             mutation createPCTInvoice($invoice: invoices_insert_input!, $pct_order_line_id: uuid!, $invoice_id: uuid!) {
                 insert_invoices_one(
                     object: $invoice,
                 ) {
                     id
                 }
                 update_pct_order_lines_by_pk(
                     pk_columns: {id: $pct_order_line_id},
                     _set: {invoice_id: $invoice_id}
                 ) {
                     id
                 }
             }
        `;

const createEPInvoiceMutation = `
             mutation createEPInvoice($invoice: invoices_insert_input!, $ep_order_line_id: uuid!, $invoice_id: uuid!) {
                 insert_invoices_one(
                     object: $invoice,
                 ) {
                     id
                 }
                 update_ep_order_lines_by_pk(
                     pk_columns: {id: $ep_order_line_id},
                     _set: {invoice_id: $invoice_id}
                 ) {
                     id
                 }
             }
        `;

const appendInfoValueIfPresent = (value?: string) => value ? ` | ${value}` : "";

const generateInvoiceData = (orderLine: any, userId: any) => {
    const pctEpOrder = orderLine?.pct_order || orderLine?.ep_order;
    const order = pctEpOrder?.order;
    const tenant = order?.tenant;
    const invoice_items = invoiceItemsFromPCTEPOrderLine(orderLine, tenant?.invoice_vat);
    //const vat_total = 0;
    const vat_total = invoice_items.reduce((acc: number, item: any) => acc + item.total - item.rate, 0);
    const price_inc_vat_total = invoice_items.reduce((acc: number, item: any) => acc + item.total, 0);
    const country = allCountries[orderLine?.case?.country_code];
    const invoice = {
        id: uuidv4(),
        created_by: userId,
        date: moment(),
        due_date: moment().add(30, 'days'),
        currency: orderLine?.price_currency || undefined,
        tenant_id: orderLine?.case?.tenant_id,
        case_id: orderLine?.case?.id,
        total: price_inc_vat_total,
        vat_total: vat_total,
        our_reference: orderLine?.case?.case_ref,
        your_reference: pctEpOrder?.user_reference,
        comments: order?.order_type === "PCT" ?
            `Filing of ${pctEpOrder?.case?.application_number} in ${country || ""} (${orderLine?.case?.country_code?.toUpperCase()})` :
            `Validation of EP Patent ${pctEpOrder?.case?.application_number} in ${country || ""}`,
        invoice_items: {
            data: invoice_items
        }
    };

    return invoice;
}

export const CreateFilingInvoice = (props: { type: OrderType }) => {
    const userId = useUserId();
    const now = moment();
    const record = useRecordContext();
    const { loading, data, error, makeApiCall } = useHasuraRequest();
    const { refetch } = useListContext();
    const refresh = useRefresh();
    const { type } = props;

    useEffect(() => {
        if (data) {
            // refetch();
            // refresh();
        }
    }, [data]);

    console.log(record);
    const defaultValues = generateInvoiceData(record, userId);

    const onSubmit = async (values: any) => {
        makeApiCall({
           query: type === "PCT" ? createPCTInvoiceMutation : createEPInvoiceMutation,
            variables: {
                invoice: defaultValues,
                invoice_id: defaultValues.id,
                ...(type === "PCT" ? { pct_order_line_id: record?.id } : { ep_order_line_id: record?.id }),
           }
        });
    }

    const onClose = () => refresh();

    return (
        <CreateInDialogButton
            label={"Create Invoice"}
            close={onClose}
            onClose={onClose}
            mutationOptions={{ onSuccess: () => refetch() }}
            resource={"invoices"} icon={<></>} fullWidth maxWidth={"lg"}>
            {loading && <Spinner></Spinner>}
            {error &&
                <Alert severity="error" sx={{marginBottom: 2}}>
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong when creating the invoice.</Typography>
                </Alert>
            }
            <Box sx={{minWidth: "600px"}}>
                {
                    data ?
                        <Alert severity="success" sx={{marginBottom: 2}}>
                            <AlertTitle>Success</AlertTitle>
                            <Typography>Invoice created</Typography>
                        </Alert>
                        :
                            <SimpleForm
                                onSubmit={onSubmit}
                                toolbar={
                                    <Toolbar>
                                        <SaveButton label={"Save"} alwaysEnable />
                                    </Toolbar>
                                }
                                defaultValues={defaultValues}>
                                <DateInput source={"date"} label={"Invoice date"} validate={required()}></DateInput>
                                <DateInput source={"due_date"} label={"Due date"} validate={required()}></DateInput>
                                <TextInput source={"our_reference"} label={"Our ref."}></TextInput>
                                <TextInput source={"your_reference"} label={"Your ref."}></TextInput>
                                <TextInput source={"comments"} label={"Comments"} multiline minRows={2}></TextInput>
                                <SelectInput source={"currency"} choices={currencyOptions} validate={required()} disabled/>
                                <NumberInput source={"total"} validate={required()} disabled/>
                                {/*<ReferenceManyInput defaultValue={invoice_items} source={"invoice_items"} reference="invoice_items" target="invoice_id" sort={{ field: "created_at", order: "DESC" }}>*/}
                                {/*    <SimpleFormIterator inline>*/}
                                {/*        <TextInput source="title" validate={required()} />*/}
                                {/*        <TextInput source="description" validate={required()} />*/}
                                {/*        <NumberInput source="quantity" validate={required()} />*/}
                                {/*        <NumberInput source="rate" validate={required()} />*/}
                                {/*        <NumberInput source="total" validate={required()} />*/}
                                {/*    </SimpleFormIterator>*/}
                                {/*</ReferenceManyInput>*/}
                            </SimpleForm>
                }
            </Box>

        </CreateInDialogButton>
    );
}

export const BulkCreateInvoicesButton = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const userId = useUserId();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const selected = (data || [])
        .filter((d: any) => selectedIds.includes(d.id));

    const toBeCreated = selected
        .filter((d: any) => d.price_currency === "USD");

    const toBeCreatedSkipped = selected
        .filter((d: any) => d.price_currency !== "USD");

    const onSubmit = async (values: any) => {
        const result = await Promise.all(toBeCreated.map((instructionRecord: any) => {
            const tenant = instructionRecord?.case_action?.case?.tenant;
            const invoice = generateInvoiceData(instructionRecord, userId);

            return dataProvider.hasuraRequest({
                query: createPCTInvoiceMutation,
                variables: {
                    invoice,
                    instructions_received_id: instructionRecord?.id,
                    invoice_id: invoice.id,
                }
            });
        }));
        console.log(result);
        refresh();
        setIsSuccess(true);
        onUnselectItems();
    }

    const count = toBeCreated.length;
    const skippedCount = toBeCreatedSkipped.length;

    return (
        <ButtonDialog buttonTitle={`Bulk create ${count} invoices`} dialogTitle={`Bulk create ${count} invoices`} >
            <SimpleForm
                onSubmit={onSubmit}
                toolbar={
                    <Toolbar>
                        <SaveButton label={`Create ${count} invoices`} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}>
                {
                    skippedCount ?
                    <Alert  variant="outlined" severity="warning" sx={{marginBottom: 2}}>
                        <AlertTitle>Skipped some of the selected</AlertTitle>
                        <Box>Since we only support USD invoicing at the moment, {skippedCount} of the selected orders will be skipped in the invoice creation process.</Box>
                        <Box mt={2}>These will be skipped:</Box>
                        <Box>
                            {
                                toBeCreatedSkipped.map((instruction: any) => (
                                    <Box>
                                        {instruction?.case_action?.case?.tenant?.name} | {instruction?.case_action?.case?.case_ref} | {instruction?.price} {instruction?.price_currency}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Alert>
                        : null
                }
                {
                    isSuccess ?
                        <Alert  variant="outlined" severity="success" sx={{marginBottom: 2}}>
                            <AlertTitle>Invoices created successfully</AlertTitle>
                            <Box>Go to the "Send Invoice" tab to email the invoices to the client</Box>
                        </Alert> : null
                }
            </SimpleForm>
        </ButtonDialog>
        // <BulkUpdateFormButton resource={"invoices"} label={"Create invoices"} icon={<></>}>
        //     <SimpleForm
        //         onSubmit={onSubmit}
        //         toolbar={
        //             <Toolbar>
        //                 <SaveButton label={"Create invoices"} alwaysEnable />
        //             </Toolbar>
        //         }
        //         sx={{maxWidth: 400}}>
        //
        //     </SimpleForm>
        // </BulkUpdateFormButton>
    );
}

