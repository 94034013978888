import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    FileInput,
    FileField,
    useDataProvider,
    SelectInput,
    FormDataConsumer,
    RadioButtonGroupInput,
    DateInput,
    FunctionField,
    useRedirect,
    Button
} from 'react-admin';
import {useMutation} from "@tanstack/react-query";
import DescriptionIcon from '@mui/icons-material/Description';
import {useCallback} from "react";
import {useLocation} from 'react-router';
import {useIsAdmin} from "../auth/utils";
import {useUserId} from "../auth/utils";
import {capitalizeFirstLetter} from "../cases/actions/Actions";
import {Box} from "@mui/system";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
export const DocumentIcon = DescriptionIcon;

export type Document = {
    id: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export enum DOCUMENT_FIELDS {
    ID = "id",
    TITLE = "title",
    TYPE = "type",
    DESCRIPTION = "description",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    FOLDER = "case_document_folders"
}

export const documentTypes = [
    "OTHER",
    "OFFICE_ACTION",
    "FORMALITY_OBJECTIONS",
    "OFFICE_ACTION_REPORT",
    "OFFICE_ACTION_DRAFT_RESPONSE",
    "OFFICE_ACTION_INSTRUCTIONS",
    "OFFICE_ACTION_RESPONSE_FILED",
    "APPLICATION_TEXT",
    "INVOICE",
    "CITATION"
].map((type) => ({id: type, name: capitalizeFirstLetter(type.replaceAll("_", " ").toLowerCase())}))

export const DocumentList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <List {...props} sort={{field: DOCUMENT_FIELDS.TITLE, order: "ASC"}}>
            <Datagrid>
                {isAdmin && <TextField source={DOCUMENT_FIELDS.ID}/>}
                <TextField source={DOCUMENT_FIELDS.TYPE}/>
                <TextField source={DOCUMENT_FIELDS.TITLE}/>
                {isAdmin && <TextField source={DOCUMENT_FIELDS.DESCRIPTION}/>}
                <FunctionField label={"Citation"} render={(record: any) => record[DOCUMENT_FIELDS.TYPE] === "CITATION" ?
                    record.citation_type === "PATENT" ?
                        `${record.citation_application_number || record.citation_registration_number}` :
                        `${record.citation_authors}`
                    : null
                }></FunctionField>
                <EditButton/>
            </Datagrid>
        </List>
    );
};

const DocumentTitle = ({record}: {
    record: Document
}) => {
    return <span>Post {record ? `"${record.title}"` : ''}</span>;
};

export const DocumentEdit = (props: any) => {
    const isAdmin = useIsAdmin();
    const location = useLocation();
    //console.log(location);
    return (
        <Edit title={<DocumentTitle record={props.record}/>} {...props} >
            <SimpleForm maxWidth={"sm"}>
                {isAdmin && <TextInput disabled source="id"/>}
                <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>
                <TextInput source={DOCUMENT_FIELDS.TITLE} resettable fullWidth/>
                <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>
                <CitationSpecificFields/>
            </SimpleForm>
        </Edit>
    );
};

interface LocationState extends Location {
    record: {
        case_id?: string
    }
}


interface UploadedFile {
    file: File;
    title: string;
    description: string;
    bucket_file_name?: string;
    original_file_name?: string;
}

interface DocumentCreateProps {
    [key: string]: any;
}


export async function uploadFileToBucket(contentType: string, fileExtension: string | undefined, file: File) {
    const urlResponse = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUploadUrl`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({contentType, extension: fileExtension}) // Use 'extension' key consistently
    }).then(res => res.json());

    const buffer = await file.arrayBuffer();
    const blobData = new Blob([buffer], {type: file.type});

    // Perform the file upload using the provided Key
    await fetch(urlResponse.uploadURL, {
        method: 'PUT',
        body: blobData
    });

    return urlResponse;
}

export const DocumentCreate: React.FC<DocumentCreateProps> = (props) => {
    const isAdmin = useIsAdmin();
    const location = useLocation();
    const userId = useUserId();
    const case_id = location.state?.record?.case_id || undefined;
    const dataProvider = useDataProvider();
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
    const redirect = useRedirect();

    const {mutate} = useMutation({
        mutationFn: (data: any) => dataProvider.create("case_documents", {
            data: data
        })
    });

    React.useEffect(() => {
        console.log("Current uploadedFiles state:", uploadedFiles);
    }, [uploadedFiles]);

    const redirect_to = case_id ? `/cases/${case_id}/show/documents` : false;
    //console.log(props, location, redirect);

    const save = useCallback(async (values: any) => {
            const uploadPromises = uploadedFiles.map(async (f) => {
                if (f.file.name) {
                    const contentType = f.file.type; // e.g., "text/plain"
                    const fileExtension = f.file.name.split('.').pop(); // e.g., "txt"

                    const response = await uploadFileToBucket(contentType, fileExtension, f.file);

                    // Prepare metadata for the uploaded file
                    const fileParams = {
                        type: "File",
                        bucket_file_name: response.Key, // Use Key without appending additional extension
                        original_file_name: f.file.name,
                        title: f.title,
                        description: f.description,
                        uploaded_by: userId,
                    };

                    const case_document = {
                        ...values,
                        case_id,
                        file: {
                            data: {
                                id: uuidv4(),
                                ...fileParams
                            }
                        },
                        bucket_file_name: response.Key, // Use Key without appending additional extension
                        original_file_name: f.file.name,
                        title: f.title,
                        description: f.description,
                        uploaded_by: userId,
                    }

                    return mutate(case_document);
                }
            });


            try {
                await Promise.all(uploadPromises);
                redirect(redirect_to);
            } catch (error) {
                console.error("Error uploading files:", error);
            }


            // redirect(redirect_to);

        },
        [mutate, uploadedFiles, case_id]
    );

    const isFile = (file: unknown): file is File => {
        return file instanceof File;
    };

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        const filesState = event;
        //console.log(event);
        if (!filesState) return; // Check if files exist

        const newFiles = Array.from(filesState).filter(isFile).map((file) => ({
            file: file as File,
            title: file.name,
            description: "",
            uploaded_by: userId
        }));

        console.log("New files: ");
        console.log(newFiles);

        setUploadedFiles((prevFiles) => {
            const uniqueFiles = [...prevFiles];
            newFiles.forEach((newFile) => {
                if (!prevFiles.some((prevFile) => prevFile.file.name === newFile.file.name)) {
                    uniqueFiles.push(newFile);
                }
            });
            return uniqueFiles;
        });


        if (event.target) {
            event.target.value = '';
        }
        console.log("UPloaded Files:");
        console.log(uploadedFiles);
    };

    const handleFileRemove = (fileName: string) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.file.name !== fileName));
    };


    const handleFileMetaChange = (index: number, field: keyof UploadedFile, value: string) => {
        setUploadedFiles((prevFiles) =>
            prevFiles.map((file, i) =>
                i === index ? {...file, [field]: value} : file
            )
        );
    };


    // @ts-ignore
    return (
        <Create title="Create a document" {...props}>
            <SimpleForm onSubmit={save}>
                {isAdmin && (
                    <SelectInput
                        source="type"
                        label="Document Type"
                        choices={[
                            {id: 'OTHER', name: 'Other'},
                            {id: 'INVOICE', name: 'Invoice'},
                            // Add more document type choices here
                        ]}
                    />
                )}
                <FileInput source="files" label="Documents" multiple onChange={handleFilesChange}
                           accept={{
                               "application/*": [".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".msg"],
                               "text/*": [".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".msg"],
                               "image/*": [".jpg", ".jpeg", ".png"],
                           }}
                           sx={{'& .RaFileInput-dropZone': {backgroundColor: "#eee"}}}
                >
                    {/**<FileField source="src" title="title" />*/}
                </FileInput>

                {uploadedFiles.map((file, index) => (
                    file.file.name ? (
                        <div key={file.file.name} style={{marginBottom: '24px'}}>
                            {/* Display the file name above the input and button */}
                            <label
                                style={{display: 'block', marginBottom: '2px', paddingBottom: 0, fontSize: '14px'}}>{file.file.name}</label>

                            {/* Wrap TextInput and button in a flex container */}
                            <Box display="flex" alignItems="center" gap={2}>

                                <TextInput
                                    source={file.title} // Unique path for each title
                                    label="Title"
                                    fullWidth
                                    defaultValue={file.title}
                                    onChange={(e) => handleFileMetaChange(index, 'title', e.target.value)}
                                />

                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => handleFileRemove(file.file.name)} // Pass file name
                                    startIcon={<DeleteIcon/>}
                                >
                                    <p>Remove</p>
                                </Button>
                            </Box>
                        </div>
                    ) : null
                ))}
            </SimpleForm>
        </Create>
    );
};

export const CitationSpecificFields = () => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => formData[DOCUMENT_FIELDS.TYPE] === "CITATION" && (
                <>
                    <RadioButtonGroupInput source="citation_type" defaultValue={"PATENT"} choices={[
                        {id: 'PATENT', name: 'Patent'},
                        {id: 'SCIENTIFIC', name: 'Scientific article'},
                    ]}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) => (
                            formData.citation_type === "PATENT" ?
                                <>
                                    <TextInput source={"citation_application_number"} fullWidth></TextInput>
                                    <TextInput source={"citation_registration_number"} fullWidth></TextInput>
                                </> :
                                <>
                                    <TextInput source={"citation_authors"} fullWidth></TextInput>
                                    <TextInput source={"citation_publication"} fullWidth></TextInput>
                                </>
                        )}
                    </FormDataConsumer>
                    <DateInput source={"citation_publication_date"} fullWidth></DateInput>
                    <TextInput source={"citation_relevant_sections"} fullWidth></TextInput>
                    <TextInput source={"citation_reference"} fullWidth></TextInput>
                    <TextInput source={"citation_relevance_classification"} fullWidth></TextInput>
                </>
            )}
        </FormDataConsumer>
    );
}
