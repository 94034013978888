import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import * as React from "react";
import { useRecordContext } from "react-admin";
import {RecreateActionsForm} from "../crud/show/CaseShow";

export const CasesBulkRecreateActionsButton = () => {
    const record = useRecordContext();

    return (
        <BulkUpdateFormButton
            label={"Recreate actions"}
            icon={<></>}
        >
            <RecreateActionsForm/>
        </BulkUpdateFormButton>
    );
};