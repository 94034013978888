import {Box, Dialog, DialogContent, DialogTitle, Grid, Stack} from "@mui/material";
import Mustache from "mustache";
import React, {useState} from "react";
import {
    AutocompleteArrayInput,
    Button,
    Form,
    Link,
    ReferenceArrayInput,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {countries} from "../utils/countries";
import {DefaultEditorOptions,} from 'ra-input-rich-text';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import {useTenantContext} from "../react-admin-overrides/AppLayout";
import {useIsAdmin, useUserId} from "../auth/utils";
import moment from "moment";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {JAN_OVE_EMAIL} from "../utils/utils";

const otherCountyCodes = ["GB", "FR", "DE"];
const epEmailTemplate = (countryCode: string) => {
    const cc = countryCode.toUpperCase();
    return cc === "UP" ? "376f02c0-9517-4bf2-b0d1-04f22b25b5bb" :
        otherCountyCodes.includes(cc) ? "32e247db-c7d0-46e4-9d5c-26e985bbe065" :
            "18de98ea-3656-45a1-80aa-0c2ca5f49a4d"
}

export const SendAgentInstructionsEmailButton = () => {
    const [open, setOpen] = useState(false);
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { tenant } = useTenantContext()  || {};
    const userId = useUserId();
    const refresh = useRefresh();
    const record = useRecordContext();
    const isAdmin = useIsAdmin();

    if (!record) return null;

    const isPCTOrder = !!record?.pct_order;
    const emailTemplateId = isPCTOrder ? '694f90f6-3693-413c-870b-a113d9d4d578' : epEmailTemplate(record?.country_code);

    const handleClick = async () => {
        try {
            const response = await dataProvider.getOne('email_templates', {
                id: emailTemplateId
            });
            console.log(record);

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});


            const order = record.order_type === "PCT" ? record.pct_order : record.ep_order;
            // const cases = order.case?.national_phase_cases;

            console.log(sendingUserResponse);
            
            const recipientName = record?.agent?.name;
            const applicationNumber = isPCTOrder ? record?.case?.based_on_pct : record?.case?.application_number;
            const templateVariables = {
                recipientName,
                application_number: applicationNumber,
                senderName: sendingUserResponse.data?.name || "Breeze IP",
                case_ref: record?.case?.case_ref,
                country: countries[record?.case?.country_code] || '',
            };
            console.log(templateVariables);
            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', templateVariables, {}, { escape: (text) => text });
            const emailSubject = Mustache.render(response.data?.subject || '', templateVariables);

            setEmailPreview(emailPreview);
            const mainRecipient = record?.agent?.email;
            //const ccEmails = [order?.order?.tenant?.cc_email].filter((email: any) => !!email);
            const recipientCcEmails = [JAN_OVE_EMAIL].filter((email: any) => mainRecipient !== email);
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                subject: emailSubject,
                body: emailPreview,
                recipient_email: mainRecipient,
                recipient_cc_emails: recipientCcEmails,
                recipient_bcc_emails: [mainRecipient, ...recipientCcEmails].includes(BREEZE_EMAIL) ? [] : [BREEZE_EMAIL],
            });
            setOpen(true);
        } catch (error) {
            console.error(error);
            notify('Error loading email preview', { type: 'error' });
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        const inputData = formData || {};
        const now = moment();
        try {
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: {
                    ...emailData,
                    ...inputData,
                }
            });

            // Update the order line with the confirmation email ID
            const resource = isPCTOrder ? "pct_order_lines" : "ep_order_lines"
            await dataProvider.updateMany(resource, {
                ids: [record.id],
                data: {
                    instructions_to_agent_sent_at: now,
                    instructions_to_agent_sent_by: userId,
                    instructions_to_agent_email_id: createdEmail.id
                }
            });

            notify('Email sent successfully', { type: 'success' });
            setOpen(false);
            refresh();
        } catch (error) {
            notify('Error sending email', { type: 'error' });
        }
    };

    return (
        <>
            <Button
                label="Send filing instructions to Local Agent"
                onClick={handleClick}
            />
            
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                {isAdmin && emailTemplateId ?
                    <Stack spacing={2} sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }} alignItems={"center"} direction={"row"}>
                        <Link to={`/cases/${record?.case?.id}/show`} target={"_blank"}>Case <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></OpenInNewIcon></Link>
                        <Link to={`/cases/${record?.case?.id}/show/documents`} target={"_blank"}>Case Documents <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></OpenInNewIcon></Link>
                        <Link to={`/email_templates/${emailTemplateId}`} target={"_blank"}>Edit template <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></OpenInNewIcon></Link>
                    </Stack> : null
                }
                <DialogTitle>Send filing instructions to Local Agent</DialogTitle>
                <DialogContent>

                    <Form onSubmit={handleSend} record={{}}>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <Stack spacing={1} mb={2}>
                                    <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                                    <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                                    <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                                </Stack>
                                <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                                <Box mb={2}><strong>Email:</strong></Box>
                                <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
                                {/*<RichTextInput*/}
                                {/*    source="body"*/}
                                {/*    defaultValue={emailPreview}*/}
                                {/*    fullWidth*/}
                                {/*    label={false}*/}
                                {/*    validate={[required()]}*/}
                                {/*    editorOptions={{*/}
                                {/*       ...MyEditorOptions,*/}
                                {/*       onCreate: ({ editor }: { editor: any }) => {*/}
                                {/*           editorRef.current = editor;*/}
                                {/*       },*/}
                                {/*   }}*/}
                                {/*/>*/}
                            </Grid>
                            <Grid item xs={6}>
                                <ReferenceArrayInput label="Attchments" reference="case_documents" source="attachment_case_document_ids" filter={{ "case_id@_in": [record?.case?.id, record?.case?.based_on_case_id]}}>
                                    <AutocompleteArrayInput
                                        label="Attachments from case or parent/based on case"
                                        filterToQuery={(searchText: string) => ({title: `${searchText}`})}
                                        sx={{ minWidth: 200 }}
                                        optionText={"title"}
                                    />
                                </ReferenceArrayInput>
                            </Grid>
                        </Grid>


                        <Button
                            label="Send filing instructions to Local Agent"
                            type={"submit"}
                            sx={{ mt: 2 }}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        // @ts-ignore
        ...DefaultEditorOptions.extensions,
        Table,
        TableCell,
        TableHeader,
        TableRow
    ],
};
