import {Create, FormDataConsumer, SelectInput, SimpleForm, TextInput, useResourceContext} from "react-admin";
import {useTenantId} from "../../data/useTenantId";
import {
    CASE_FIELDS,
    CASE_TYPE_DESIGN,
    CASE_TYPE_INFRINGEMENT,
    CASE_TYPE_OPPOSITION,
    CASE_TYPE_PATENT,
    CASE_TYPE_TRADEMARK,
    caseTypeList,
    caseTypeOptions
} from "../list/CaseList";
import {casesRedirect} from "./utils";
import {
    DesignCaseInputFields,
    OppositionInputFields,
    PatentCaseInputFields,
    SimpleCaseTypeInputFields,
    TrademarkCaseInputFields
} from "./CaseInputFields";

export const CaseCreate = (props: any) => {
    const tenantId = useTenantId();
    const resource = useResourceContext();
    const caseTypeFromResource = caseTypeList.find((caseType) => caseType?.resource === resource);
    return (
        <Create title="Create case" {...props} redirect={casesRedirect}>
            <SimpleForm sx={{ maxWidth: 300 }}>
                <TextInput hidden source={CASE_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />
                <SelectInput source={CASE_FIELDS.CASE_TYPE} choices={caseTypeOptions} defaultValue={caseTypeFromResource?.id} fullWidth />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_PATENT) return <PatentCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_TRADEMARK) return <TrademarkCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_DESIGN) return <DesignCaseInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_OPPOSITION) return <OppositionInputFields />
                        if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_INFRINGEMENT) return <OppositionInputFields />
                        return <SimpleCaseTypeInputFields />;
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};
