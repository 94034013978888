import {useRecordContext} from "react-admin";
import {Box} from "@mui/material";

export const calcOfficialFee = (caseRecord: any, priceEntry: any): any => {
    if (!priceEntry) return {};

    const basic_filing_fee_eur = (priceEntry.basic_filing_fee * priceEntry.official_fee_currency_factor);

    const extra_claims = Math.max((caseRecord.claims_count || 0) - (priceEntry.basic_fee_num_included_claims || 0), 0);
    const extra_claims_fee_eur = (extra_claims * priceEntry.basic_fee_per_extra_claim * priceEntry.official_fee_currency_factor);

    const extra_independent_claims = Math.max((caseRecord.independent_claims_count || 0) - (priceEntry.basic_fee_num_included_ind_claims || 0), 0);
    const extra_independent_claims_fee_eur = (extra_independent_claims * priceEntry.basic_fee_per_extra_ind_claim * priceEntry.official_fee_currency_factor);

    const extra_pages = Math.max((caseRecord.page_count || 0) - priceEntry.basic_fee_num_included_pages, 0);
    const extra_pages_fee_eur = (extra_pages * priceEntry.basic_fee_per_extra_page * priceEntry.official_fee_currency_factor);

    const examination_fee = ((priceEntry.examination_fee || 0) * priceEntry.official_fee_currency_factor);

    const official_fee_eur = Math.ceil(basic_filing_fee_eur + extra_claims_fee_eur + extra_independent_claims_fee_eur + extra_pages_fee_eur + examination_fee);

    return {
        official_fee_eur: official_fee_eur && official_fee_eur < 10 ? 10 : official_fee_eur,
        basic_filing_fee: priceEntry.basic_filing_fee,
        official_fee_currency_factor: priceEntry.official_fee_currency_factor,
        official_fee_currency: priceEntry.basic_filing_fee_currency,
        basic_filing_fee_eur,
        extra_claims_fee_eur,
        extra_independent_claims_fee_eur,
        extra_pages_fee_eur,
        applicant_employees: priceEntry.applicant_employees,
        examination_fee
    };
};

export const calcTranslationFee = (caseRecord: any, priceEntry: any): number => {
    const translation_requirements = priceEntry.translation_requirements;
    const translation_fee_per_word = priceEntry.translation_fee_per_word;

    if (translation_requirements === "Claims") return Math.ceil(caseRecord.word_count_claims_only * translation_fee_per_word);
    if (translation_requirements === "Whole application") return Math.ceil(caseRecord.word_count * translation_fee_per_word);

    return 0;
};

export const priceCalc = (caseRecord: any, priceTable: Array<any>) => (countryCode: string, employees?: string) => {
    const employeesPredicate = (p: any) => !employees || employees === p.applicant_employees;
    const countryPrices = priceTable.filter((p: any) => p.country_code === countryCode);
    const priceEntry = countryPrices.length === 1 ?
        countryPrices[0] :
        countryPrices.find(employeesPredicate) || countryPrices[0];

    if (!priceEntry) return {total: 0};

    const serviceFees = (priceEntry?.our_fee || 0) + (priceEntry?.local_agent_fee || 0);
    const translationFee = calcTranslationFee(caseRecord, priceEntry);
    const {official_fee_eur, ...official_fee_calculations} = calcOfficialFee(caseRecord, priceEntry);

    const total = official_fee_eur + serviceFees + translationFee
    return {
        official_fees: official_fee_eur,
        service_fees: serviceFees,
        translation_fees: translationFee,
        official_fee_calculations,
        total,
    }
};

export const sumField = (array: Array<any>, fieldName: string): number => array.reduce((previousValue, currentValue, currentIndex, array) => {
    const field = currentValue[fieldName];
    const numberValue = field ? Number(field) : 0;
    const safeNumber = isNaN(numberValue) ? 0 : numberValue;
    console.log(previousValue, currentValue, field, numberValue, safeNumber);
    return previousValue + safeNumber;
}, 0);
