import React from "react";
import {TenantContext} from "../../../../react-admin-overrides/AppLayout";
import {useIsAdmin} from "../../../../auth/utils";
import {
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useCreate,
    useRecordContext,
    useRefresh,
    WithRecord
} from "react-admin";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {CreateNewFolder} from "@mui/icons-material";

export const AddFolderButton = (props: any) => {
    const tenant = React.useContext(TenantContext);
    const isAdmin = useIsAdmin();
    const documents = tenant?.tenant?.feature_documents || isAdmin;
    const refresh = useRefresh();
    const [create] = useCreate();
    const record = useRecordContext();
    const caseId = props.caseId || record?.id;

    if (!documents) return null;
    const folderSave = (data: any) => {
        create('case_document_folders', { data });
    };

    return (
        <ReferenceManyField target="case_id" reference="case_document_folders">
            <WithRecord render={record => (
                <CreateInDialogButton
                    record={{ case_id: caseId, tenant_id: tenant?.tenant.id }}
                    label="Create Folder"
                    icon={<CreateNewFolder/>}
                >
                    <SimpleForm>
                        <TextInput source="name" />
                    </SimpleForm>
                </CreateInDialogButton>
            )} />
        </ReferenceManyField>
    );
}
