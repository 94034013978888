import * as React from "react";
import { Suspense } from "react";
import {
    DefaultEditorOptions,
} from 'ra-input-rich-text';
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, RichTextField, Toolbar, SaveButton, DeleteButton} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import {useTenantId} from "../data/useTenantId";
import {LazySpinner, Spinner} from "../utils/Spinner";
import HardBreak from '@tiptap/extension-hard-break'
import {CloneWithTransformation, transformRemoveDBAutoFields} from "../utils/./CloneWithTransformation";
import {actionTenantTypeOptions, actionTypeOptions} from "../cases/actions/utils";
import {caseTypeOptions} from "../cases/list/CaseList";

const RichTextInput = React.lazy(() =>
    import('ra-input-rich-text').then(module => ({
        default: module.RichTextInput,
    }))
);

export const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        // @ts-ignore
        ...DefaultEditorOptions.extensions,
        HardBreak,
    ],
};


export type EmailTEmplateName = {
    id: string;
    created_at: string;
    updated_at: string;
    code: string;
    name: string;
    subject: string;
    body: string;
}

enum EMAIL_TEMAPLTES_FIELDS {
    ID = "id",
    TENANT_ID = "tenant_id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    CODE = "code",
    NAME = "name",
    SUBJECT = "subject",
    BODY = "body",
}

const filters = [
    <TextInput alwaysOn source={"code,name,subject"}  label={"Search code, name, subject"} sx={{ width: 300 }} />,
];

export const EmailTemplatesList = (props: any) => (
    <List {...props} filters={filters} sort={{ field: EMAIL_TEMAPLTES_FIELDS.CODE, order: "ASC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source={EMAIL_TEMAPLTES_FIELDS.CODE} />
            <TextField source={EMAIL_TEMAPLTES_FIELDS.NAME} />
            <TextField source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} />
            <RichTextField source={EMAIL_TEMAPLTES_FIELDS.BODY} />
            <EditButton />
            <CloneWithTransformation transform={transformRemoveDBAutoFields} />
            <DeleteButton mutationMode={"pessimistic"} />
        </Datagrid>
    </List>
);

const EmailTemplatesTitle = ({ record }: { record: EmailTEmplateName }) => {
    return <span>Post {record ? `"${record.name}"` : ''}</span>;
};

const transformEmail = (data: any) => {
    return {
        ...data,
        body: data?.body?.replaceAll(/<p><\/p>/g, "<br>")
    }
};
export const EmailTemplatesEdit = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Edit title={<EmailTemplatesTitle record={props.record} />} transform={transformEmail} redirect={"list"} {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>
                <SimpleForm maxWidth={"md"} toolbar={
                    <Toolbar>
                        <SaveButton label="Save" alwaysEnable />
                    </Toolbar>
                }>
                    {/*<TextInput hidden source={EMAIL_TEMAPLTES_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                    <TextInput disabled source="id" />
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.CODE} />
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.NAME} fullWidth />
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} fullWidth />
                    {/*<TextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />*/}
                    <RichTextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />
                </SimpleForm>
            </Suspense>
        </Edit>
    );
};

export const EmailTemplatesCreate = (props: any) => {
    const tenantId = useTenantId();
    return (
        <Create title="Create an email template" transform={transformEmail} redirect={"list"} {...props}>
            <Suspense fallback={<LazySpinner delay={500} />}>
                <SimpleForm maxWidth={"md"} toolbar={
                    <Toolbar>
                        <SaveButton label="Save" alwaysEnable />
                    </Toolbar>
                }>
                    {/*<TextInput hidden source={EMAIL_TEMAPLTES_FIELDS.TENANT_ID} defaultValue={tenantId} style={{display: "none"}} />*/}
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.CODE} />
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.NAME} fullWidth />
                    <TextInput source={EMAIL_TEMAPLTES_FIELDS.SUBJECT} fullWidth/>
                    {/*<TextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} />*/}
                    <RichTextInput source={EMAIL_TEMAPLTES_FIELDS.BODY} editorOptions={MyEditorOptions} />
                </SimpleForm>
            </Suspense>
        </Create>
    );
};
