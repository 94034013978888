import * as React from "react";
import { List, Datagrid, TextField, useRecordContext, FunctionField, BooleanField, EditButton, DeleteButton, SelectInput, TextInput, ShowButton, BooleanInput, SimpleForm } from 'react-admin';
import { useParams, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { CopyActionRuleButton } from "./CopyActionRuleButton";
import {CreateCountryRulesDialogButton} from "./CreateCountryRuleDialogButton";
import {actionTypeOptions, actionTenantTypeOptions} from "../cases/actions/utils";
import {caseTypeOptions} from "../cases/list/CaseList";
import {ShowContentInDialogButton} from "../content/ShowContentInDialogButton";
import {BulkUpdateFormButton } from "@react-admin/ra-form-layout";

const activeFilterChoices = [{ id: true, name: "Only Active" }, { id: false, name: "Only Inactive" }];

const filters = [
    <TextInput source={"action_code"}  label={"Code"} alwaysOn />,
    <SelectInput source={"action_rule_tenant_type"} choices={actionTenantTypeOptions} label="Corporate / Agent" alwaysOn />,
    <SelectInput source={"action_type"} choices={actionTypeOptions} label={"Type"} alwaysOn />,
    <TextInput source={"action_name"} alwaysOn />,
    <SelectInput source={"case_type"} alwaysOn choices={caseTypeOptions} />,
    <SelectInput source={"active"} alwaysOn choices={activeFilterChoices} emptyText={"Show active and inactive"} />,
];

export const ActionRuleList = (props: any) => {
    const { actionRuleId } = useParams();
    return (
        // @ts-ignore "ASC,ASC" is okay since ra-data-hasura uses this annotation to allow sorting on multiple columns
        <List resource="action_rules"  filters={filters} filter={{ id: actionRuleId }} sort={{ field: "action_code,action_name", order: "ASC,ASC" }} perPage={50}>
            <Datagrid rowClick={false} bulkActionButtons={<>
                <BulkUpdateFormButton>
                    <SimpleForm sx={{maxWidth: 400}}>
                        <SelectInput label="Corporate / Agent rule" source={"action_rule_tenant_type"} choices={actionTenantTypeOptions} required/>
                    </SimpleForm>
                </BulkUpdateFormButton>
            </>}>
                <TextField source={"action_code"} />
                <TextField source={"action_rule_tenant_type"} />
                <TextField source={"action_type"} />
                <TextField source={"action_name"} />
                <TextField source={"case_type"} />
                <FunctionField
                    label="Application type"
                    render={(record: any) => record.application_type ? `${record.application_type_inverse ? "NOT " : ""} ${record.application_type}` : 'All' }
                />
                <BooleanField source={"active"} />
                <ShowButton label={"Country Rules"} icon={<></>}></ShowButton>
                {/*<CountryRulesButton/>*/}
                {/*<CreateCountryRulesButton/>*/}
                <EditButton />
                <CopyActionRuleButton />
                {/*<DeleteButton mutationMode="pessimistic"/>*/}
                <CreateCountryRulesDialogButton />
                <ShowContentInDialogButton />
            </Datagrid>
        </List>
    );
};

const CountryRulesButton = () => {
    const actionRule = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/action_rules/${actionRule?.id}/country_rules`}
        >
            Country rules
        </Button>
    );
};