import React, {useState} from "react";
import { List, BulkDeleteButton, FunctionField, TextField, useRecordContext, useDataProvider, useListContext, BooleanField, SelectInput, TextInput, NumberInput, BooleanInput, Button, SimpleForm, NumberField, NullableBooleanInput, FormDataConsumer } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams, Link } from 'react-router-dom';
import {countries, countriesOptions} from "../utils/countries";
import {
    DeleteWithConfirmIconButton,
    EditableDatagrid,
    EditRowButton,
    RowForm,
    useRowContext
} from "@react-admin/ra-editable-datagrid";
import {triggerDateOptions, triggerDateTitles} from "./triggerDate";
import { Button as MUIButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Backdrop, CircularProgress, Checkbox, FormControlLabel, FormGroup, FormControl, Autocomplete, TextField as MUITextField, InputLabel, Select, MenuItem } from '@mui/material';
import {CountryInput} from "../utils/CountryInput";
import {actionTypeOptions, actionTenantTypeOptions} from "../cases/actions/utils";
import {entitySizeOptions, toChoices} from "../common/common-utils";
import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import {FlagField} from "../utils/FlagField";
import {CASE_FIELDS, caseTypeOptions} from "../cases/list/CaseList";
import {isPCTOrEP, parseDesignatedStates, validateDesignatedStates} from "../cases/crud/utils";

const filters = [
    <CountryInput source={"country_code"} label={"Country"} alwaysOn sx={{marginBottom: 6}}/>,
    <SelectInput source={"action_rule_tenant_type"} choices={actionTenantTypeOptions} label="Corporate / Agent" alwaysOn />,
    <NullableBooleanInput source={"action_rule#active"} alwaysOn sx={{marginBottom: 6}} label={"Active"} />,
    <SelectInput source={"action_rule#case_type"} choices={caseTypeOptions} alwaysOn sx={{marginBottom: 6}} label={"Case type"}/>,
    <SelectInput source={"action_rule#action_type"} choices={actionTypeOptions} alwaysOn sx={{marginBottom: 6}} label={"Action type"}/>,
    <TextInput source={"action_rule#action_name"} alwaysOn sx={{marginBottom: 6}} label={"Action name"}/>,
    <TextInput source={"override_action_name"} alwaysOn sx={{marginBottom: 6}} label={"Override action name"}/>,
];

const CopyCountryRulesButton = () => {
    const { selectedIds, data } = useListContext();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const close = () => {
        setOpen(false);
        setIsLoading(false);
        setSuccessMessage('');
        setFormInput({
            countryCodes: [],
            entitySize: "",
        });
    };
    const [formInput, setFormInput] = useState({
        countryCodes: [] as string[],
        entitySize: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string>();

    // Ensure the page already is filtered on a country before showing the button

    const startCopying = () => {
        console.log(selectedIds);
        console.log(formInput);
        const objects = formInput.countryCodes.map((countryCode) => selectedIds.map((id) => {
            const countryRule = (data || []).find((cr) => cr.id === id);
            console.log(countryRule);
            const { id: crId, created_at, updated_at, action_rule, ["action_rule.id"]: arId, entitySize, ...rest } = countryRule;

            return { ...rest, country_code: countryCode, entity_size: formInput.entitySize || entitySize  };
        })).flat();

        console.log(objects);

        setIsLoading(true)
        dataProvider.createManyActionCountryRules(objects).then((body: any) => {
            console.log(body);
            setIsLoading(false);
            const affected = body?.data?.insert_action_country_rules?.affected_rows;
            setSuccessMessage(`${affected} country rules successfully copied`);
        });
    }
    const handleSelectChange = (event: any) => {
        event?.preventDefault();
        const entitySize = event?.target?.value || ""
        setFormInput({ ...formInput, entitySize });
    }

    return (
        <div>
            <Button label={"Copy to other country"} startIcon={<ContentCopyIcon/>} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={close} maxWidth={"md"} >
                <DialogTitle>{`Copy country rules to countries`}</DialogTitle>
                <DialogContent sx={{ width: 600 }}>
                    {
                        successMessage ?
                            <DialogContentText>
                                {successMessage}
                            </DialogContentText>
                            :
                            <>

                                <DialogContentText>
                                    This will copy the selected country rules to the countries you select below. It will not delete any existing country rules, so if the intention is to override existing country rules, they have to be deleted (deleting before copying might be best).
                                </DialogContentText>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
                                    open={isLoading}
                                    // onClick={handleClose}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <br/>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={Object.keys(countries)}
                                    getOptionLabel={(option) => countries[option]}
                                    // defaultValue={["se"]}
                                    filterSelectedOptions
                                    value={formInput.countryCodes}
                                    onChange={ (e, value) => setFormInput({ ...formInput, countryCodes: value })}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            label="Select countries to copy to"
                                            // placeholder="Countries"
                                        />
                                    )}
                                />
                                <FormControl>
                                    <InputLabel id="entity-size-select-label">Override Entity Size</InputLabel>
                                    <Select
                                        labelId="entity-size-select-label"
                                        onChange={handleSelectChange}
                                        value={formInput.entitySize}
                                        label="Entity size"
                                        sx={{ width: 300 }}>
                                        <MenuItem value={""}>Don't override</MenuItem>
                                        {
                                            entitySizeOptions.map((entity) => <MenuItem value={entity.name}>{entity.name}</MenuItem>)
                                        }

                                    </Select>
                                </FormControl>
                                {/*<SelectInput source={"entity_size"} choices={entitySizeOptions} />*/}
                            </>
                    }
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={close}>Cancel</MUIButton>
                    {!successMessage && <MUIButton onClick={startCopying} disabled={formInput.countryCodes.length === 0}>Start copying</MUIButton>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

const BulkUpdateGracePriceIncrease = () => {
    return (
        <BulkUpdateFormButton label={"Update Grace Price"} icon={<></>}>
            <SimpleForm sx={{maxWidth: 400}}>
                <GraceFeeInput source={"grace_price_increase_factor"} />
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const BulkUpdateTriggerDate = () => {
    return (
        <BulkUpdateFormButton label={"Update Trigger Date"} icon={<></>}>
            <SimpleForm sx={{maxWidth: 400}}>
                <SelectInput source={"trigger_date"} choices={triggerDateOptions.map((s) => ({ id: s, name: s}))}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData?.trigger_date ?
                            <span>{triggerDateTitles[formData?.trigger_date]}</span>
                            : null;
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
}

const BulkActionButtons = () => (
    <>
        <BulkUpdateTriggerDate />
        <BulkUpdateGracePriceIncrease />
        <CopyCountryRulesButton/>
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const ExtendedActionCountryRuleList = (props: any) => {

    return (
    // @ts-ignore
        <List filters={filters} sx={{ mt: 2 }} perPage={50} sort={{ field: "action_rule.action_code,action_rule.action_name", order: "ASC,ASC" }} filterDefaultValues={{ "action_rule#active": true }}>
            <EditableDatagrid
                mutationMode="undoable"
                bulkActionButtons={<BulkActionButtons/>}
                createForm={<CountryRuleForm />}
                editForm={<CountryRuleForm />}
                rowClick="edit"
                actions={<CustomActions/>}
            >
                <TextField source={"action_rule.action_code"} label={"Action code"} />
                <TextField source={"action_rule.action_type"} label={"Action type"}/>
                <TextField source={"action_rule.action_name"} label={"Action name"}/>
                <FunctionField
                    label="Application type"
                    render={(record: any) => `${record.action_rule?.application_type_inverse ? "NOT " : ""} ${record.action_rule.application_type}`}
                />
                <TextField source={"entity_size"} label={"Entity size"}/>
                <BooleanField source={"action_rule.active"} />
                <TextField source={"country_code"} />
                <FlagField label={"Country"} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField>
                <TextField source={"trigger_date"} />
                <TextField source={"days_until_due_date"} minWidth={250} width={250}/>
                <TextField source={"months_until_due_date"} />
                <TextField source={"years_until_due_date"} />
                <BooleanField source={"end_of_month_due_date"} />
                <TextField source={"with_fine_months_from_due_date"} />
                <TextField source={"official_fee"} />
                <TextField source={"official_fee_per_claim"} />
                <TextField source={"official_fee_per_extra_class"} />
                <TextField source={"num_classes_included"} />
                <TextField source={"official_fee_currency"} />
                <TextField source={"agent_fee"} />
                <TextField source={"agent_fee_currency"} />
                <TextField source={"our_fee"} />
                <TextField source={"our_fee_currency"} />
                <NumberField source={"grace_price_increase_factor"} transform={(v: any) => v ? `${v * 100} %` : v } label={"Grace price increase"}/>
                <TextField source={"translation_fee_per_word"} />
                <TextField source={"translation_fee_currency"} />
                <TextField source={"override_action_name"} />
                {/*<CountryRuleEditButton />*/}
            </EditableDatagrid>
        </List>
    );
};

const CustomActions = () => {
    const record = useRecordContext();
    if (!record) return null;
    const body = `Are you sure you want to delete country rule for action '${record.action_rule?.action_name}' for ${countries[record.country_code]} (${record.country_code})`
    return (
        <>
            <EditRowButton />
            <DeleteWithConfirmIconButton mutationMode="undoable" confirmContent={body} confirmTitle={`Delete country rule`} />
        </>
    )
};

const CountryRuleForm = () => (
    <RowForm>
        <TextField source={"action_rule.action_code"} label={"Action code"} />
        <TextField source={"action_rule.action_type"} label={"Action type"}/>
        <TextField source={"action_rule.action_name"} label={"Action name"}/>
        <FunctionField
            label="Application type"
            render={(record: any) => `${record.action_rule?.application_type_inverse ? "NOT " : ""} ${record.action_rule.application_type}`}
        />
        <SelectInput source={"entity_size"} choices={entitySizeOptions} />
        <BooleanField source={"action_rule.active"} />
        <TextField source={"country_code"} />
        <FunctionField
            label="Country"
            render={(record: any) => countries[record["country_code"]]}
        />
        <SelectInput source={"trigger_date"} choices={triggerDateOptions.map((s) => ({ id: s, name: s}))}/>
        <NumberInput source={"days_until_due_date"} />
        <NumberInput source={"months_until_due_date"} />
        <NumberInput source={"years_until_due_date"} />
        <BooleanInput source={"end_of_month_due_date"} />
        <NumberInput source={"with_fine_months_from_due_date"} />
        <NumberInput source={"official_fee"} />
        <NumberInput source={"official_fee_per_claim"} />
        <NumberInput source={"official_fee_per_extra_class"} />
        <NumberInput source={"num_classes_included"} />
        <TextInput source={"official_fee_currency"} />
        <NumberInput source={"agent_fee"} />
        <TextInput source={"agent_fee_currency"} />
        <NumberInput source={"our_fee"} />
        <TextInput source={"our_fee_currency"} />
        <GraceFeeInput source={"grace_price_increase_factor"} />
        <NumberInput source="translation_fee_per_word" defaultValue={0} />
        <TextInput source="translation_fee_currency" defaultValue={"EUR"} />
        <TextInput source={"override_action_name"} />
    </RowForm>
);

const CountryRuleEditButton = () => {
    const actionCountryRule = useRecordContext();
    return (
        <MUIButton
            component={Link}
            to={`/country_rules/${actionCountryRule?.id}`}
            startIcon={<EditIcon />}
        >
            Edit
        </MUIButton>
    );
};

const GraceFeeInput = (props: any) => {
    return (
        <NumberInput
            source={"grace_price_increase_factor"}
            min={0}
            format={(value: any) => value ? Number(value) * 100 : null }
            parse={(value: any) => value ? parseFloat(value) / 100 : null }
            {...props}
        />
    )
}
