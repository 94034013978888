import * as React from "react";
import {
    SelectInput,
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    SimpleFormIterator,
    ReferenceInput,
    useSimpleFormIteratorItem,
    ArrayInput,
    FormDataConsumer
} from 'react-admin';
import {applicationTypeOptions, caseTypeOptions} from "../cases/list/CaseList";
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import {Box, Stack } from "@mui/system";
import { actionTypeOptions, actionTenantTypeOptions } from "../cases/actions/utils";
import {PROMPT_USER} from "./utils";

const applicationTypeOptionsIncludingAll = [{ id: 'All', name: 'All'}, ...applicationTypeOptions];
const responsibleRoleOptions = ["PATENT_ATTORNEY", "PARALEGAL", "ANNUITIES", "SYSTEM"].map((type) => ({ id: type, name: type}));
const startDateLogicOptions = [
    "DATE_OF_TODAY",
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
    PROMPT_USER,
].map((type) => ({ id: type, name: type}))
const dueDateLogicOptions = [
    "SAME_AS_DUE_DATE",
    "3M_BEFORE_DUE_DATE",
    "4M_BEFORE_DUE_DATE",
    "7D_AFTER_START_DATE",
    "14D_AFTER_START_DATE",
].map((type) => ({ id: type, name: type}))
const extractFieldOptions = ["letter_date"].map((type) => ({ id: type, name: type}))
export const RECIPIENT_INTERNAL_IP_GROUP = "INTERNAL_IP_GROUP";
export const RECIPIENT_CLIENT = "CLIENT";
export const RECIPIENT_LOCAL_AGENT = "LOCAL_AGENT";
export const RECIPIENT_PRIMARY_AGENT = "PRIMARY_AGENT";

const recipientOptions = [
    RECIPIENT_INTERNAL_IP_GROUP,
    RECIPIENT_CLIENT,
    RECIPIENT_LOCAL_AGENT,
    RECIPIENT_PRIMARY_AGENT,
].map((type) => ({ id: type, name: type}))

export const ActionRuleEdit = () => {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm sanitizeEmptyValues>
                <Stack direction={"row"} spacing={2} flexWrap="wrap">
                    <TextInput source="action_code" />
                    <TextInput source="action_name" />
                    <SelectInput label="Corporate / Agent rule" source={"action_rule_tenant_type"} choices={actionTenantTypeOptions} required/>
                    <TextInput source="status" multiline/>
                    <SelectInput source={"action_type"} choices={actionTypeOptions}/>
                    <SelectInput source={"responsible_role"} choices={responsibleRoleOptions}/>
                    <SelectInput source="extract_field" choices={extractFieldOptions} />
                    <SelectInput source={"action_type"} choices={actionTypeOptions}/>
                    <SelectInput source={"case_type"} choices={caseTypeOptions} defaultValue={caseTypeOptions[0]}/>
                    <SelectInput source={"application_type"} choices={applicationTypeOptionsIncludingAll}/>
                    <ReferenceInput reference="content" source="content_id" label="Info content">
                        <SelectInput source={"content_id"} label={"Content"} optionText={"title"}/>
                    </ReferenceInput>
                    <BooleanInput source={"application_type_inverse"} label={"Inverse application type filter (i.e NOT *application type*)"}/>
                    <BooleanInput source={"active"} />
                </Stack>
                <Box width={"100%"} maxWidth={"400px"}>
                    <TextInput source="description_for_ai" label={"Description for AI"} multiline fullWidth />
                    <TextInput source="attributes_for_ai" label={"Attributes for AI (comma separated)"} multiline fullWidth />
                </Box>
                <ReferenceManyInput reference="action_sub_rules" target="action_rule_id" sort={{ field: "code", order: "ASC" }}>
                    <SimpleFormIterator inline>
                        <TextInput source="code" required />
                        <TextInput source="status" multiline />
                        <TextInput source="action_name" multiline required />
                        <SelectInput source={"responsible_role"} choices={responsibleRoleOptions} required />
                        <ReferenceInput reference="email_templates" source="email_template_id" label="Email template">
                            <SelectInput source={"name"} label={"Email template"} optionText={(record: any) => `${record.name} (${record.code})`}/>
                        </ReferenceInput>
                        <SelectInput source="email_recipient" choices={recipientOptions} />
                        <SelectInput source="internal_start_date_logic" choices={startDateLogicOptions} required />
                        <SelectInput source="internal_due_date_logic" choices={dueDateLogicOptions} required />
                        <TextInput source="complete_button_text" />
                        <BooleanInput source="inactivates_action_rule" />
                    </SimpleFormIterator>
                </ReferenceManyInput>
            </SimpleForm>
        </Edit>
    );
};