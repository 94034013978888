import React, {Fragment, useRef, useState} from "react";
import {countries} from "../../../utils/countries";
import {CaseRoleList} from "../CaseRoleList";
import {AnnuityCaseActionsList, CaseActionsList} from "../CaseActionsList";
import {alternateBackgroundColor, CASE_FIELDS, CASE_TYPE_PATENT, caseActionsFilter, compactTable} from "../../list/CaseList";
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    FunctionField,
    Link,
    List,
    ListButton,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SaveButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useRecordContext,
    WithRecord
} from "react-admin";
import {CaseTitle} from "../CaseTitle";
import {CopyCaseButton} from "../../copy/CopyCaseButton";
import PlusIcon from '@mui/icons-material/Add';
import {useIsAdmin} from "../../../auth/utils";
import {Box, Chip, Grid, Stack, Typography} from "@mui/material";
import {CreateActionsButton} from "../../actions/CreateActionsButton";
import {EditInDialogButton, ShowInDialogButton} from "@react-admin/ra-form-layout";
import {documentTypes} from "../../../documents/documents";
import {QuickFilter} from "../../../utils/QuickFilter";
import {ANNUITY, RENEWAL} from "../../actions/utils";
import {dateFormat} from "../../actions/Actions";
import {TenantContext} from "../../../react-admin-overrides/AppLayout";
import {CaseRefField} from "../CaseRefField";
import {FlagField} from "../../../utils/FlagField";
import {ApplicationNumberField} from "../AppplicationNumberField";
import {CitationsField} from "../CitationsField";
import moment from "moment";
import {CASE_STATUS_CLOSED, CASE_STATUS_GRANTED} from "../utils";
import {SyncWithEPOButton} from "../CaseSyncWithEPO";
import {DesignRenewalsList} from "../../design/DesignRenewals";
import {ChargesCreateInDialog, ChargesList} from "../../../charges/charges";
import {DocumentsDataGrid} from "./documents/DocumentsTab";
import {AddFolderButton} from "./documents/AddFolderButton";
import {useChat} from "@ai-sdk/react";
import {ButtonDialog} from "../../../utils/ButtonDialog";

const EmptyDocumentList = () => (
    <Typography variant="body1" color="textSecondary">
        <p>No documents associated with this case yet.</p>
        <AddDocumentButton/>
    </Typography>
);

const CaseTabLayout = (props: any) => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;

    return (
        <TabbedShowLayout>
            <Tab label="overview">
                <Grid container spacing={2} sx={{maxWidth: "800px"}}>
                    <Grid item xs={6}>
                        <WithRecord render={(record: any) =>
                            <SimpleShowLayout>
                                <TextField source={CASE_FIELDS.CASE_TYPE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                {/*<TextField source={CASE_FIELDS.COUNTRY_CODE} />*/}
                                <FunctionField
                                    label="Country"
                                    render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
                                />
                                {record[CASE_FIELDS.COUNTRY_CODE] === "pc" &&
                                    <TextField source={CASE_FIELDS.DEMAND_FOR_CHAPTER_2}
                                               label={"Demand for chapter II"}/>}
                                <TextField source={CASE_FIELDS.CATCHWORD}/>
                                <TextField source={CASE_FIELDS.INVENTION_TITLE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_TYPE_2}/>
                                <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                <DateField source={CASE_FIELDS.FILING_DATE}/>
                                <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                <TextField source={"entity_size"} label={"Override entity size"}/>
                            </SimpleShowLayout>
                        }/>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                            <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                            <TextField source={CASE_FIELDS.STATUS}/>
                            <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                            <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                            <FunctionField
                                label="Priority country"
                                render={(record: any) => countries[record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]]}
                            />
                            <TextField source={CASE_FIELDS.DESIGNATED_STATES}/>
                            <DateField source={"expiration_date"}/>
                            <DateField source={CASE_FIELDS.CREATED_AT}/>
                            <DateField source={CASE_FIELDS.UPDATED_AT}/>
                            {
                                isAdmin &&
                                <DateField source={"closed_at"}/>
                            }
                            {
                                isAdmin &&
                                <TextField source={"closed_event_description"}/>
                            }
                            {
                                isAdmin &&
                                <TextField source={"word_count"}/>
                            }
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Tab>
            {
                <Tab label="roles" path="roles">
                    <ReferenceManyField label="Roles" reference="case_roles" target="case_id">
                        <CaseRoleList {...props} />
                    </ReferenceManyField>
                </Tab>
            }
            {
                isAdmin &&
                <Tab label="admin" path="admin">
                    <Edit hasShow={false}>
                        <SimpleForm toolbar={
                            <Toolbar>
                                <SaveButton label="Save" />
                            </Toolbar>
                        }>
                            <Stack spacing={5}>
                                <BooleanInput source="calc_maintenance_actions_and_fees" helperText={"Switching this off will turn off future action calculations for Annuities and Renewals. It will not delete already existing actions"} />
                                <TextInput source="import_comments" helperText={"This text will be included in the Excel report we send to clients after we have concluded importing their data to the system"} />
                            </Stack>
                        </SimpleForm>
                    </Edit>
                </Tab>
            }
            {
                documents &&
                <Tab label="documents" path="documents">
                    <WithRecord render={caseRecord => {
                            return (
                                <>
                                    {isAdmin && <List
                                        resource={"incoming_mail"}
                                        exporter={false}
                                        sort={{field: "created_at", order: "ASC"}}
                                        filter={{case_id: caseRecord?.id}}
                                    >
                                        <Datagrid>
                                            <TextField source={"subject"} label={"Subject"}></TextField>
                                            <DateField source={"created_at"} label={"Received"} showTime></DateField>
                                            <FunctionField
                                                label="Case ref"
                                                render={(record: any) =>
                                                    record.case_id ? (
                                                        <>
                                                        <ReferenceField
                                                            source="case_id"
                                                            reference="cases"
                                                            record={record}
                                                        >
                                                            <TextField source="case_ref"/>
                                                        </ReferenceField>
                                                        <EditInDialogButton label="Override case" >
                                                            <SimpleForm>
                                                                <ReferenceInput source="case_id" reference="cases" title="subject">
                                                                    <AutocompleteInput optionText="application_number" />
                                                                </ReferenceInput>
                                                            </SimpleForm>
                                                        </EditInDialogButton>
                                                        </>
                                                    ) : <>
                                                        <Typography>No case reference found in email </Typography>
                                                        <EditInDialogButton label="Set case" >
                                                            <SimpleForm>
                                                                <ReferenceInput source="case_id" reference="cases" title="subject">
                                                                    <AutocompleteInput optionText="application_number" />
                                                                </ReferenceInput>
                                                            </SimpleForm>
                                                        </EditInDialogButton>
                                                    </>
                                                }
                                            />
                                            <FunctionField
                                                source="body"
                                                label="Body"
                                                render={(record: any) =>
                                                    record.body?.substring(0, 200) + (record.body?.length > 200 ? "..." : "")
                                                }
                                            />
                                            {//<TextField source={"attachments"} label={"Attachments"}></TextField>
                                            }
                                            <FunctionField
                                                source="attachments"
                                                label="Attachments"
                                                render={(record: any) => {
                                                    try {
                                                        const attachments = JSON.parse(record.attachments || '[]');
                                                        return attachments.map((attachment: any, index: number) => (
                                                            <div key={index}>{attachment.original_file_name }</div>
                                                        ));
                                                    } catch (e) {
                                                        return record.attachments; // Fallback to raw text if parsing fails
                                                    }
                                                }}
                                            />
                                        </Datagrid>
                                    </List>
                        }</>
                    )
                        }
                    }

                        />
                    <WithRecord render={(record: any) => (
                        <List
                            resource="case_documents"
                            filter={{case_id: record?.id}}
                            sort={{field: "created_at", order: "DESC"}}
                            filters={<DocumentsFilter/>}
                            exporter={false}
                            pagination={false}
                            perPage={50}
                            empty={<EmptyDocumentList />}
                        >
                            <DocumentsDataGrid />
                        </List>
                    )}/>
                </Tab>
            }
            <Tab label="actions" path="actions">
                <div>
                    <h3>Actions</h3>
                    <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                        sort={{field: "due_date", order: "ASC"}}
                                        filter={caseActionsFilter}>
                        <CaseActionsList/>
                    </ReferenceManyField>
                    <WithRecord render={(record: any) => (
                        record?.case_type === CASE_TYPE_PATENT ?
                            <Box>
                                <h3>Annuities</h3>
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{"action_rule#action_type": ANNUITY}}>
                                    <AnnuityCaseActionsList/>
                                </ReferenceManyField>
                            </Box> :
                            <Box>
                                <h3>Renewals</h3>
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{"action_rule#action_type": RENEWAL}}>
                                    <DesignRenewalsList/>
                                </ReferenceManyField>
                            </Box>
                    )} />
                </div>
                {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                {/*                    filter={caseActionsFilter}*/}
                {/*>*/}
                {/*    <CaseActionsList/>*/}
                {/*</ReferenceManyField>*/}
            </Tab>
            {
                isAdmin &&
                <Tab label="family" path="family">
                    <WithRecord render={(record: any) => {
                        if (record?.case_type === CASE_TYPE_PATENT && record?.case_ref) {
                            const familyPart = record?.case_ref?.match(/P\d+/)[0];
                            return (
                                <div>
                                    <h3>Family / case_ref starting with {familyPart}</h3>
                                    <List
                                        resource={"cases"}
                                        storeKey={"cases/family-cases"}
                                        sort={{field: "case_ref", order: "ASC"}}
                                        filter={{"case_ref": familyPart}}
                                        perPage={100}
                                    >
                                        <Datagrid bulkActionButtons={false} rowSx={alternateBackgroundColor}
                                                  sx={compactTable}
                                        >
                                            <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
                                            <TextField source={CASE_FIELDS.APPLICANT}/>
                                            <FlagField label={"Country"} source={"country_code"}
                                                       sx={{textWrap: "nowrap"}}></FlagField>
                                            <ApplicationNumberField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                            <TextField source={CASE_FIELDS.CATCHWORD}/>
                                            <FunctionField
                                                label={"Title"}
                                                source={CASE_FIELDS.INVENTION_TITLE}
                                                render={(record: any) => <span
                                                    title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span>}
                                            ></FunctionField>
                                            <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                            <FunctionField
                                                label="Priority claim"
                                                render={(record: any) => record[CASE_FIELDS.PRIORITY_APPLICATION_NUMBER] ? "With priority" : "Without priority"}
                                            />
                                            <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                            <DateField source={CASE_FIELDS.FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                                            <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                                            <DateField source={CASE_FIELDS.PUBLICATION_DATE}/>
                                            <TextField source={CASE_FIELDS.PUBLICATION_NUMBER}/>
                                            <TextField source={CASE_FIELDS.STATUS}/>

                                            <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                                            <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                                            <FunctionField
                                                label="Priority Country"
                                                render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
                                            />

                                            <TextField source={CASE_FIELDS.PRODUCT}/>
                                            <TextField source={CASE_FIELDS.DEPARTMENT}/>
                                            <CitationsField label={"Citations"}/>
                                            <FunctionField
                                                label={"Priority claims"}
                                                render={(record: any) => <span>{(record?.priority_claims || []).map(
                                                    (c: any) => <Chip
                                                        label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`}
                                                        sx={{marginBottom: 1}}></Chip>)
                                                }</span>}
                                            ></FunctionField>
                                            <ReferenceField reference="names" source={"primary_agent_id"}
                                                            label={"Agent"}>
                                                <TextField source={"name"}/>
                                            </ReferenceField>
                                            <ReferenceField reference="names" source={"local_agent_id"}
                                                            label={"Local Agent"}>
                                                <TextField source={"name"}/>
                                            </ReferenceField>
                                            <ReferenceOneField
                                                label="Next annuity"
                                                reference="case_actions"
                                                target={"case_id"}
                                                sort={{field: "due_date", order: "ASC"}}
                                                filter={{
                                                    "completed_at@_is_null": true,
                                                    "action_rule#action_type": ANNUITY,
                                                    "due_date@_gte": moment().format(dateFormat)
                                                }}
                                            >
                                                <DateField source="due_date"/>
                                            </ReferenceOneField>
                                        </Datagrid>
                                    </List>
                                </div>
                            )
                        }
                        return null;
                    }}></WithRecord>
                    {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                    {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                    {/*                    filter={caseActionsFilter}*/}
                    {/*>*/}
                    {/*    <CaseActionsList/>*/}
                    {/*</ReferenceManyField>*/}
                </Tab>
            }

            {
                isAdmin &&
                <Tab label="charging" path="charging">
                    <WithRecord render={(record: any) => {
                        return <ChargesList filter={{ case_id: record?.id }} storeKey={"cases/charges"} caseId={record?.id}></ChargesList>;
                    }}></WithRecord>
                </Tab>
            }
        </TabbedShowLayout>
    );
}

export const CaseShow = (props: any) => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;

    return (
        <Show {...props} title={<CaseTitle/>} actions={<CaseShowActions/>}>
            <h1><CaseTitle/></h1>
            <CaseTabLayout {...props}></CaseTabLayout>
        </Show>
    )
};

export const CaseShowInDialog = (props: any) => {
    return (
        <ShowInDialogButton resource={"cases"} {...props} title={<CaseTitle/>} actions={<CaseShowActions/>} maxWidth={"xl"} fullWidth>
            <CaseTabLayout {...props}></CaseTabLayout>
        </ShowInDialogButton>
    )
};

const DocumentsFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <SelectInput source={"type"} choices={documentTypes} emptyText={"All"}/>
            <QuickFilter source={"type@_neq"} label={"Hide citations"} defaultValue={"CITATION"}></QuickFilter>
            <TextInput label="Search" source="file#title@_ilike,file#original_file_name@_ilike" alwaysOn />
        </Filter>
    );
};


const CaseShowActions = () => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ListButton label={"Cases"}/>
            {/*{isAdmin && <EditButton/>}*/}
            <EditButton/>
            {/*{isAdmin && <AddDocumentButton/>}*/}
            <AddDocumentButton/>
            <AddFolderButton/>
            {isAdmin && <CopyCaseButton label={"Copy to other country"}/>}
            {isAdmin &&
                <ButtonDialog buttonTitle={"AI Chat"} dialogTitle={"AI Chat"}>
                    <AIChat />
                </ButtonDialog>
            }
            {isAdmin && <CreateActionsButton/>}
            {/*<CopyCaseButton label={"Copy to other country"}/>*/}
            {isAdmin && <RecreateActionsButton/>}
            {isAdmin && <SyncWithEPOButton/>}
            {isAdmin && <ChargesCreateInDialog caseId={record?.id}/>}
        </TopToolbar>
    );
};

const AddDocumentButton = () => {
    const caseRecord = useRecordContext();
    const isAdmin = useIsAdmin();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    return documents ? (
        <Button
            component={Link}
            to={{
                pathname: "/case_documents/create",
                // Here we specify the initial record for the create view
                state: {record: {case_id: caseRecord?.id}},
                search: caseRecord ? `case_id=${caseRecord.id}` : ""
            }}
            label="Add documents"
        >
            <PlusIcon/>
        </Button>
    ) : null;
};


export const RecreateActionsButton = () => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return (
        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Recreate actions`} label={`Recreate actions`}
                            icon={<></>}>
            <RecreateActionsForm/>
        </EditInDialogButton>
    );
}

export const RecreateActionsForm = () => {
    const caseRecord = useRecordContext();
    return (
        <Box sx={{minWidth: "600px"}}>
            <SimpleForm
                record={{...caseRecord, recreate_case_actions_trigger: moment()}}
                maxWidth={"sm"}
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Generate actions"} alwaysEnable/>
                    </Toolbar>
                }
            >
                {/*<BooleanInput source={"recreate_case_actions_trigger"} />*/}
                <Box>
                    <Typography>
                        Recreate all actions for this case. This will delete all existing, automatically created,
                        actions and recreate them based on the current case data and country rules.
                    </Typography>
                </Box>
                <br/>
                <Box>
                    <Typography>
                        The generation happens in the background and should be done in a few seconds after you click
                        "Generate actions". You can update the page manually by clicking the refresh button in the
                        browser or in the menu. The data (and new actions) will also be updated if you return to the
                        browser window after having used another application.
                    </Typography>
                </Box>
            </SimpleForm>
        </Box>
    );
}

const AIChat = (props: any) => {
    const { messages, input, handleInputChange, handleSubmit, error } = useChat({
        api: `https://portal.breezeip.com/.netlify/functions/chat`,
        //api: `${process.env.REACT_APP_FUNCTIONS_URL}/chat`,
    });
    const [files, setFiles] = useState<FileList | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const fileUrl = props.fileUrl;

    return (
        <div
            className="flex flex-col w-full max-w-md py-24 mx-auto stretch"
        >
            {messages.map((m) => (
                <div key={m.id} className="whitespace-pre-wrap">
                    {m.role === "user" ? "User: " : "AI: "}
                    {m.content}
                    <div>
                        {m?.experimental_attachments
                            ?.filter(
                                (attachment) =>
                                    attachment?.contentType?.startsWith("image/") ||
                                    attachment?.contentType?.startsWith("application/pdf"),
                            )
                            .map((attachment, index) =>
                                attachment.contentType?.startsWith("image/") ? (
                                    <img
                                        key={`${m.id}-${index}`}
                                        src={attachment.url}
                                        width={500}
                                        height={500}
                                        alt={attachment.name ?? `attachment-${index}`}
                                    />
                                ) : attachment.contentType?.startsWith("application/pdf") ? (
                                    <iframe
                                        key={`${m.id}-${index}`}
                                        src={attachment.url}
                                        width="500"
                                        height="600"
                                        title={attachment.name ?? `attachment-${index}`}
                                    />
                                ) : null,
                            )}
                    </div>
                </div>
            ))}

            <form
                className="fixed bottom-0 w-full max-w-md p-2 mb-8 border border-gray-300 rounded shadow-xl space-y-2"
                onSubmit={(event) => {
                    handleSubmit(event, {
                        experimental_attachments: files,
                    });

                    setFiles(undefined);

                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }}
            >
                <input
                    type="file"
                    className=""
                    onChange={(event) => {
                        if (event.target.files) {
                            setFiles(event.target.files);
                        }
                    }}
                    multiple
                    ref={fileInputRef}
                />

                <input
                    className="w-full p-2"
                    value={input}
                    placeholder="Say something..."
                    onChange={handleInputChange}
                />

                {error && (
                    <>
                        <div>An error occurred.</div>
                        <button type="button">
                            Retry
                        </button>
                    </>
                )}
            </form>
        </div>
    );
};

export const CaseStatusField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord.status === CASE_STATUS_GRANTED ?
        <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
        caseRecord.status === CASE_STATUS_CLOSED ?
            <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
            <Chip label={caseRecord.status}></Chip>
}

export const FilingDateField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE] ?
        <Chip label={ `Int. filing date ${moment(caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE]).format(dateFormat)}` }></Chip> :
            <Chip label={ `Filing date ${moment(caseRecord[CASE_FIELDS.FILING_DATE]).format(dateFormat)}` }></Chip>
}
