import * as React from 'react';
import {
    useListContext,
    useRecordContext,
    List,
    TextField,
    DateField,
    ReferenceField,
    EditButton,
    SimpleForm,
    TextInput,
    Edit,
    ReferenceManyField,
    Show,
    ReferenceInput,
    AutocompleteInput,
    Create,
    SimpleShowLayout,
    SelectInput,
    Toolbar,
    SaveButton,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useDataProvider,
    FormDataConsumer
} from 'react-admin';
import {CaseTitle} from "./CaseTitle";
import {CASE_FIELDS, CASE_TYPE_PATENT, CASE_TYPE_TRADEMARK} from "../list/CaseList";
import {PatentCaseInputFields, TrademarkCaseInputFields} from "./CaseInputFields";
import {useTenantId} from "../../data/useTenantId";
import {ApplicantNameInput} from "./ApplicantNameInput";
import {CASE_STATUS_CLOSED} from "./utils";

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const ROLE_INVENTOR = "Inventor";
const caseRoles = [ROLE_INVENTOR, "Organisation", "Account", "Correspondence", "Assignee"];

export const CaseRoleList = (props: any) => {
    const { data, isLoading } = useListContext();
    const caseRecord = useRecordContext();
    const dataProvider = useDataProvider();
    const tenantId = useTenantId();

    console.log(props, caseRecord);
    console.log(data);

    if (isLoading) return null;

    const onCreateNewName = (name: string | undefined) => {
        return dataProvider.create("names", { data: { name, type: ROLE_INVENTOR, tenant_id: tenantId }})
            .then((result: any) => {
                return {
                    id: result?.data?.id,
                    name: result?.data?.name
                };
            });
    }

    return (
        <Edit resource={"cases"} redirect={false} actions={false}>
            <SimpleForm maxWidth={"sm"} toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }>
                <ApplicantNameInput />
                <ReferenceInput source="applicant2_name_id" reference="names">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ name: `${searchText}` })} fullWidth optionText={"name"} label={"Applicant 2"}/>
                </ReferenceInput>
                <ReferenceInput source="applicant3_name_id" reference="names">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ name: `${searchText}` })} fullWidth optionText={"name"} label={"Applicant 3"}/>
                </ReferenceInput>
                <ReferenceInput source="client_id" reference="clients">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceArrayInput
                    label={"Inventors"}
                    source="inventor_name_ids"
                    reference="names"
                    filter={{
                        "type@_eq": "Inventor",
                        "tenant_id@_eq": tenantId
                }}>
                    <AutocompleteArrayInput
                        onCreate={onCreateNewName}
                        label={"Inventors"}
                        filterToQuery={(searchText: string) => ({ "name": `${searchText}` })}
                    ></AutocompleteArrayInput>
                </ReferenceArrayInput>
                <ReferenceInput source="primary_agent_id" reference="names" filter={{ type: "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="local_agent_id" reference="names" filter={{ type: "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) => {
                        return formData?.local_agent_id ?
                            <TextInput source={"local_agent_case_ref"}></TextInput>
                            :
                            null;
                    }}
                </FormDataConsumer>


                <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: tenantId }}>
                    <SelectInput optionText={"name"} fullWidth/>
                </ReferenceInput>

                <ReferenceInput source="annuity_payment_agent_name_id"  reference="names" filter={{ type: "Agent" }}>
                    <AutocompleteInput
                        label={"Annuity payment agent"}
                        filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })}
                        fullWidth
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
