import {allCountries} from "./utils/countries";


export const invoiceItemsFromPCTEPOrderLine = (orderLine: any, vat: number = 0) => {
    console.log(orderLine);
    const filingCase = orderLine?.case;
    const order = orderLine?.pct_order?.order || orderLine?.ep_order?.order;
    const sourceCase = order?.case;
    const country = allCountries[filingCase?.country_code];
    const title = order?.order_type === "PCT" ?
        `Filing of ${sourceCase?.application_number} in ${country || ""}` :
        `Validation of EP Patent ${sourceCase?.application_number} in ${country || ""}`;


    const vat_factor = vat / 100;
    const incVatRate = 1 + vat_factor;

    const serviceFeeIncVat = parseFloat((orderLine?.service_fee * incVatRate)?.toFixed(2));
    const translationFeeIncVat = parseFloat((orderLine?.translation_fee * incVatRate)?.toFixed(2));

    const orderLineIds = {
        ...(orderLine?.pct_order?.id ? { pct_order_line_id: orderLine?.id } : {}),
        ...(orderLine?.ep_order?.id ? { ep_order_line_id: orderLine?.id } : {}),
    }

    const translationLine = orderLine?.translation_fee ? [{
        title: "Translation fees",
        description: undefined,
        quantity: 1,
        rate: orderLine?.translation_fee,
        vat: vat,
        total: translationFeeIncVat,
    }] : [];

    return [
        {
            title: "Official filing fees",
            description: undefined,
            quantity: 1,
            rate: orderLine?.official_fee,
            vat: 0,
            total: orderLine?.official_fee,
        },
        {
            title: "Service fees including disbursment to local agent",
            description: undefined,
            quantity: 1,
            rate: orderLine?.service_fee,
            vat: vat,
            total: serviceFeeIncVat,
        },
        ...translationLine
    ]
}
