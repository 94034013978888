import {ActionSubRule} from "../actions/types";
import moment, {Moment} from "moment";
import {PROMPT_USER} from "../actions/utils";

export const responsibleUserIdFromCaseTeam = (role: string, team: any): string | null => {
    if (role === "PATENT_ATTORNEY") {
        return team.patent_attorney_id;
    } else if (role === "PARALEGAL") {
        return team.paralegal_id;
    } else if (role === "ANNUITIES") {
        return team.annuities_id;
    } else {
        return null;
    }
}
export const internalDueDate = (actionSubRule: ActionSubRule, activationDate: Moment, dueDate: Moment): Moment => {
    switch (actionSubRule.internal_due_date_logic) {
        case "SAME_AS_DUE_DATE":
            return dueDate;
        case "3M_BEFORE_DUE_DATE":
            return dueDate.clone().subtract(3, "months");
        case "4M_BEFORE_DUE_DATE":
            return dueDate.clone().subtract(4, "months");
        case "7D_AFTER_START_DATE":
            return activationDate.clone().add(7, "days");
        case "14D_AFTER_START_DATE":
            return activationDate.clone().add(14, "days");
        default:
            throw new Error("Unknown due date logic: " + actionSubRule.internal_due_date_logic);
    }
}
export const internalStartDate = (actionSubRule: ActionSubRule, dueDate: Moment): Moment => {
    switch (actionSubRule.internal_start_date_logic) {
        case PROMPT_USER: // Todo: This is a temp solution until we implement full support for prompting
            return moment();
        case "DATE_OF_TODAY":
            return moment();
        case "SAME_AS_DUE_DATE":
            return dueDate;
        case "3M_BEFORE_DUE_DATE":
            return dueDate.clone().subtract(3, "months");
        case "4M_BEFORE_DUE_DATE":
            return dueDate.clone().subtract(4, "months");
        case "7D_AFTER_TODAY":
            return moment().clone().add(7, "days");
        case "14D_AFTER_TODAY":
            return moment().clone().add(14, "days");
        default:
            throw new Error("Unknown due date logic: " + actionSubRule.internal_due_date_logic);
    }
}

export type CreateActionsBody = {
    docketing?: {
        docketing_draft_id: string,
        completed_at: string,
        completed_by: string,
        caseDocument: {
            case_id: string,
            original_file_name: string,
            bucket_file_name: string,
            title: string,
            description?: string,
        }
    },
    partialActionRules: Array<{
        id: string,
        letter_date?: string,
        due_date: string,
    }>,
    caseId: string,
}

export const yesNoChoices = [
    "Yes",
    "No",
].map((label:string) => ({ id: label, name: label }))

export const roundToTwoDecimals = (n: number): number => parseFloat(n.toFixed(2));

export const toChoices = (choices: string[]): Array<{id: string, name: string}> => choices.map((label:string) => ({ id: label, name: label }));
export const entitySizeOptions = toChoices(["Micro", "Small", "Large"]);
