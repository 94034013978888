import * as React from "react";
import {BulkUpdateFormButton, InputSelectorForm } from "@react-admin/ra-form-layout";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import {ApplicantNameInput} from "../crud/ApplicantNameInput";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";

export const CasesBulkUpdateButton = () => (
    <BulkUpdateFormButton>
        <InputSelectorForm
            inputs={[
                {
                    label: 'Applicant',
                    element: <ApplicantNameInput/>,
                },
                {
                    label: 'Organization',
                    element:
                        <ReferenceInput source="tenant_id" reference="tenants">
                            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisations"
                                               fullWidth/>
                        </ReferenceInput>,
                },
                {
                    label: 'Client',
                    element:
                        <ReferenceInput source="client_id" reference="clients">
                            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name": `${searchText}` })} />
                        </ReferenceInput>,
                },
                {
                    label: 'Primary Agent',
                    element:
                        <ReferenceInput source="primary_agent_id" reference="names" filter={{ type: "Agent" }}>
                            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
                        </ReferenceInput>,
                },
                {
                    label: 'Local Agent',
                    element:
                        <ReferenceInput source="local_agent_id" reference="names" filter={{ type: "Agent" }}>
                            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} />
                        </ReferenceInput>,
                },
                {
                    label: 'Product',
                    element: <ProductInput source={"product"}></ProductInput>,
                },
                {
                    label: 'Department',
                    element: <DepartmentInput source={"department"}></DepartmentInput>,
                },
            ]}
        />
    </BulkUpdateFormButton>
);
